import { permissions } from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { isProduction } from '@/utils/config';
import {
	AccountBalanceRounded as AccountBalanceRoundedIcon,
	AddShoppingCart as AddShoppingCartIcon,
	CampaignRounded as CampaignRoundedIcon,
	CategoryRounded as CategoryRoundedIcon,
	CreditCard as CreditIcon,
	DashboardRounded as DashboardRoundedIcon,
	DescriptionOutlined as DescriptionOutlinedIcon,
	Email as EmailIcon,
	GridViewOutlined as GridViewOutlinedIcon,
	Group as GroupIcon,
	Handshake as HandshakeIcon,
	InsertDriveFileOutlined as InsertDriveFileOutlinedIcon,
	LocalShipping as LocalShippingIcon,
	LocationOn as LocationOnIcon,
	Payment as PaymentIcon,
	Payments as PaymentsIcon,
	Person as PersonIcon,
	QrCodeScanner as QrCodeScannerIcon,
	ReceiptLong as ReceiptLongIcon,
	ReceiptLongOutlined as ReceiptLongOutlinedIcon,
	Sell as SellIcon,
	Settings as SettingsIcon,
	ShoppingBagOutlined as ShoppingBagOutlinedIcon,
	ShoppingBasket as ShoppingBasketIcon,
	ShowChart as ShowChartIcon,
	SnippetFolder as FolderView,
	SupportAgent as SupportAgentIcon,
} from '@mui/icons-material';
import { intersection } from 'lodash-es';
import type { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import EmployeeHasCards, { ClientHasHouseAccount } from '../../pages/client/useEmployeeCardRead';

export type menuItem = {
	name: string,
	Icon?: ComponentType | any,
	detail?: string,
	background?: string,
	href?: string,
	Badge?: ComponentType | any,
	items?: menuItem[],
	open?: boolean,
	hidden?: boolean
};

export const getAuthMobileMenuItems = (): menuItem[] => [ {
	name: 'Login',
	Icon: PersonIcon,
	href: '/login',
}, {
	name: 'Signup',
	Icon: PersonIcon,
	href: '/signup',
} ];

export const getCompanyMobileMenuItems = (): menuItem[] => [ {
	name: 'Dashboard',
	Icon: DashboardRoundedIcon,
	href: '/dashboard',
}, {
	name: 'Management',
	Icon: FolderView,
	href: '/dashboard/management',
}, {
	name: 'Charge',
	Icon: PaymentIcon,
	href: '/dashboard/commerce/payments/charge',
}, {
	name: 'Account',
	Icon: SettingsIcon,
	href: '/dashboard/settings',
} ];

export const getClientMobileWebMenuItems = (): menuItem[] => {
	const hasCards = EmployeeHasCards();
	const hasHouseAccount = ClientHasHouseAccount();
	
	return [ {
		name: 'Dashboard',
		Icon: DashboardRoundedIcon,
		href: '/client',
	}, !hasCards && {
		name: 'Estimates',
		Icon: InsertDriveFileOutlinedIcon,
		href: '/client/estimates',
	}, hasCards && {
		name: 'Pay',
		Icon: PaymentsIcon,
		href: '/client/cards',
	}, hasHouseAccount && {
		name: 'Members',
		Icon: SupportAgentIcon,
		href: '/client/employees',
	}, !hasCards && {
		name: 'Invoices',
		Icon: DescriptionOutlinedIcon,
		href: '/client/invoices',
	}, !hasCards && {
		name: 'House Accounts',
		Icon: DescriptionOutlinedIcon,
		href: '/client/accounts',
	}, {
		name: 'Profile',
		Icon: SettingsIcon,
		href: '/client/settings',
	} ].filter( Boolean );
};

export const getClientMobileDashboardItems = (): menuItem[] => {
	const hasCards = EmployeeHasCards();
	const hasHouseAccount = ClientHasHouseAccount();
	
	return [ {
		name: 'Dashboard',
		Icon: DashboardRoundedIcon,
		href: '/client',
	}, {
		name      : 'Estimates',
		Icon      : InsertDriveFileOutlinedIcon,
		background: 'warning.main',
		detail    : 'View & approve your estimates',
		href      : '/client/estimates',
	}, {
		name      : 'Invoices',
		Icon      : DescriptionOutlinedIcon,
		detail    : 'View & pay your invoices',
		background: 'text.secondary',
		href      : '/client/invoices',
	}, {
		name      : 'Orders',
		Icon      : DescriptionOutlinedIcon,
		detail    : 'View & pay your orders',
		background: 'success.main',
		href      : '/client/orders',
	}, hasCards && {
		name      : 'Pay',
		Icon      : PaymentsIcon,
		detail    : 'Pay now',
		background: 'error.main',
		href      : '/client/cards',
	}, hasHouseAccount && {
		name      : 'Members',
		Icon      : SupportAgentIcon,
		detail    : 'View & manage employee cards',
		href      : '/client/employees',
		background: 'primary.main',
	}, {
		name      : 'Statements',
		Icon      : EmailIcon,
		href      : '/client/statements',
		detail    : 'View & download your statements',
		background: 'primary.main',
	}, hasHouseAccount && {
		name      : 'House Accounts',
		Icon      : EmailIcon,
		href      : '/client/accounts',
		detail    : 'View & pay your end of month invocies',
		background: 'warning.main',
	} ].filter( Boolean );
};

export const menuItemsObj: Record<string, boolean> = {
	'Dashboard'      : true,
	'Estimates'      : true,
	'Invoices'       : true,
	'Orders'         : true,
	'Purchases'      : true,
	'Payments'       : true,
	'Clients'        : true,
	'House Accounts' : true,
	'Items'          : true,
	'Categories'     : true,
	'Modifier Groups': true,
	'Online Store'   : true,
	'Inventory'      : true,
	'Vendors'        : true,
	'Team'           : true,
	'Locations'      : true,
	'Reports'        : true,
	'Agreements'     : true,
	'Campaigns'      : true,
	'Transactions'   : true,
};

export const falseMenuItemsObj = Object.keys( menuItemsObj )
	.reduce( ( tabsObj, tab ) => ( { ...tabsObj, [ tab ]: false } ), {} as Record<string, boolean> );

export const useGetFullMenu = (): menuItem[] => {
	const { staff, user } = useUserInfo();
	const { t } = useTranslation();
	
	return [ {
		name  : 'Dashboard',
		Icon  : GridViewOutlinedIcon,
		detail: 'Dashboard',
		href  : '/dashboard',
	}, intersection( staff?.permissions, permissions.estimates.read ).length && {
		name      : 'Estimates',
		Icon      : InsertDriveFileOutlinedIcon,
		background: 'warning.main',
		detail    : t( 'common:view-manage-estimates' ),
		href      : '/dashboard/commerce/estimates',
	}, intersection( staff?.permissions, permissions.invoices.read ).length && {
		name      : 'Invoices',
		Icon      : DescriptionOutlinedIcon,
		background: 'text.secondary',
		detail    : t( 'common:view-manage-invoices' ),
		href      : '/dashboard/commerce/invoices',
	}, intersection( staff?.permissions, permissions.orders.read ).length && {
		name      : 'Orders',
		Icon      : ReceiptLongOutlinedIcon,
		background: 'success.main',
		detail    : t( 'common:view-manage-incoming-orders' ),
		href      : '/dashboard/commerce/orders',
	}, intersection( staff?.permissions, permissions.purchases.read ).length && {
		name      : 'Purchases',
		Icon      : ShoppingBagOutlinedIcon,
		background: 'error.main',
		detail    : t( 'common:view-manage-incoming-purchases' ),
		href      : '/dashboard/commerce/purchases',
	}, intersection( staff?.permissions, permissions.payments.read ).length && {
		name      : 'Payments',
		Icon      : CreditIcon,
		background: 'secondary.main',
		detail    : t( 'common:view-manage-incoming-payments' ),
		href      : '/dashboard/commerce/payments',
	}, intersection( staff?.permissions, permissions.clients.read ).length && {
		name      : 'Clients',
		Icon      : PersonIcon,
		background: 'warning.main',
		detail    : t( 'common:send-statements' ),
		href      : '/dashboard/management/clients',
	}, intersection( staff?.permissions, permissions.houseAccounts.read ).length && {
		name      : 'House Accounts',
		Icon      : AccountBalanceRoundedIcon,
		background: 'primary.main',
		detail    : t( 'common:view-manage-estimates' ),
		href      : '/dashboard/management/houseAccounts',
	}, intersection( staff?.permissions, permissions.items.read ).length && {
		name      : 'Items',
		Icon      : SellIcon,
		background: 'primary.main',
		detail    : t( 'common:view-manage-item-stock' ),
		href      : '/dashboard/management/items',
	}, intersection( staff?.permissions, permissions.categories.read ).length && {
		name      : 'Categories',
		Icon      : CategoryRoundedIcon,
		background: 'error.main',
		detail    : t( 'common:view-manage-categories' ),
		href      : '/dashboard/management/categories',
	}, intersection( staff?.permissions, permissions.modifierGroups.read ).length && {
		name      : 'Modifier Groups',
		Icon      : AddShoppingCartIcon,
		background: 'text.secondary',
		detail    : t( 'common:view-manage-modifiers-group' ),
		href      : '/dashboard/management/modifierGroup',
	}, intersection( staff?.permissions, permissions.stores.read ).length && {
		name      : 'Online Store',
		Icon      : ShoppingBasketIcon,
		background: 'secondary.main',
		detail    : t( 'common:sell-product-online' ),
		href      : '/dashboard/management/stores',
	}, intersection( staff?.permissions, permissions.inventories.read ).length && {
		name      : 'Inventory',
		Icon      : QrCodeScannerIcon,
		background: 'warning.main',
		detail    : t( 'common:manage-take-inventory' ),
		href      : '/dashboard/management/inventory',
	}, intersection( staff?.permissions, permissions.vendors.read ).length && {
		name      : 'Vendors',
		Icon      : LocalShippingIcon,
		background: 'success.main',
		detail    : t( 'common:manage-vendors-items' ),
		href      : '/dashboard/management/vendors',
	}, intersection( staff?.permissions, permissions.teams.read ).length && {
		name      : 'Team',
		Icon      : GroupIcon,
		background: 'primary.main',
		detail    : t( 'common:add-team-permission' ),
		href      : '/dashboard/management/teams',
	}, intersection( staff?.permissions, permissions.locations.read ).length && {
		name      : 'Locations',
		Icon      : LocationOnIcon,
		background: 'error.main',
		detail    : t( 'common:create-manage-branches' ),
		href      : '/dashboard/management/locations',
	}, intersection( staff?.permissions, permissions.reports.read ).length && {
		name      : 'Reports',
		Icon      : ShowChartIcon,
		background: 'success.main',
		detail    : t( 'common:view-sale-report' ),
		href      : '/dashboard/management/reports',
	}, intersection( staff?.permissions, permissions.agreements.read ).length && {
		name      : 'Agreements',
		Icon      : HandshakeIcon,
		background: 'success.main',
		detail    : t( 'common:view-agreement-templates' ),
		href      : '/dashboard/management/agreements',
	}, intersection( staff?.permissions, permissions.campaigns.read ).length && {
		name      : 'Campaigns',
		Icon      : CampaignRoundedIcon,
		background: 'primary.main',
		detail    : t( 'common:view-campaigns' ),
		href      : '/dashboard/management/campaigns',
	}, intersection( staff?.permissions, permissions.transactions.read ).length && !isProduction && {
		name      : 'Transactions',
		Icon      : ReceiptLongIcon,
		background: 'primary.main',
		detail    : t( 'common:view-transactions' ),
		href      : '/dashboard/management/transactions',
	} ].filter( Boolean );
};
