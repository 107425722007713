import { Box, BoxProps } from '@mui/material';
import { ReactNode } from 'react';

export default function TipTapStylesWrapper( { children, ...props }: { children: ReactNode } & BoxProps ) {
	return (
		<Box
			{...props}
			sx={{
				'overflowY'     : 'auto',
				'height'        : '100%',
				'flexGrow'      : 1,
				'cursor'        : 'text',
				'& .ProseMirror': {
					height: '100%',
					cursor: 'text',
				},
				
				// Remove focus outline
				'& .ProseMirror:focus': {
					outline: 'none',
				},
				
				// Normalize heading and paragraph line heights
				'& .tiptap-editor h1, & .tiptap-editor h2': {
					margin    : 0,
					padding   : 0,
					lineHeight: 1.2,
				},
				'& .tiptap-editor p': {
					margin    : 0,
					padding   : 0,
					lineHeight: 1.4,
				},
				
				// Blockquote styles
				'& .ProseMirror blockquote': {
					borderLeft: '4px solid lightgray',
					
					paddingLeft: '20px',
					margin     : '0 0 16px',
				},
				// Code block styles
				'& .ProseMirror code': {
					bgcolor     : 'alpha.warning',
					color       : 'warning.main',
					padding     : '2px 4px',
					borderRadius: '4px',
					fontFamily  : 'monospace',
				},
				
				// Horizontal Rule Styles
				'& .ProseMirror hr': {
					border   : 'none',
					borderTop: ( { palette } ) => `1px solid ${palette.divider}`,
					margin   : '16px 0',
				},
				
				// Placeholder Styles
				'& p.is-editor-empty:first-of-type::before': {
					content      : 'attr(data-placeholder)',
					color        : 'text.disabled',
					float        : 'left',
					height       : 0,
					pointerEvents: 'none',
				},
				
				// Link Styles
				'& .ProseMirror a': {
					'span': {
						color: ( { palette } ) => `${palette.primary.main} !important`,
					},
					'color'              : ( { palette } ) => palette.primary.main,
					'textDecoration'     : 'underline',
					'textDecorationColor': ( { palette } ) => palette.primary.main,
					'fontWeight'         : '500',
					'cursor'             : 'pointer',
					'&:hover'            : {
						color     : 'blue',
						transition: 'color 0.2s',
					},
				},
				...props?.sx,
			}}>
			{children}
		</Box>
	);
}
