import TextFieldInputLabel from '@/components/form/inputLabel';
import MicroformField from '@/pages/dashboard/commerce/payment/cybersource/microformField';
import { GatewayBase, Payment } from '@/types/schema';
import { isProduction } from '@/utils/config';
import { formatExpiryDate } from '@/utils/formatExpiryDate';
import { Alert, Box, Skeleton, Stack, TextField } from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash-es';
import Script from 'next/script';
import React, { Fragment, type MutableRefObject, useRef, useState } from 'react';
import { useAsyncEffect } from 'rooks';
import * as yup from 'yup';

type Props = {
	amount: number,
	tip: number,
	dollarTip: number,
	method: string,
	createPayment: ( data? ) => any,
	onSubmit: MutableRefObject<( () => Promise<Payment> )>,
	gateway: GatewayBase
};

const validationSchema = yup.object( {
	expiryDate: yup
		.string()
		.required( 'Required' )
		.test( 'valid-format', 'Format: MM/YY', ( value ) => {
			if ( !value ) return false;
			return /^\d{2}\/\d{2}$/.test( value );
		} )
		.test( 'valid-month', 'Invalid month', ( value ) => {
			if ( !value ) return false;
			const [ month ] = value.split( '/' );
			const monthNum = parseInt( month, 10 );
			if ( monthNum < 1 || monthNum > 12 ) return false;
			return true;
		} )
		.test( 'no-expire', 'Date expired', ( value ) => {
			if ( !value ) return false;
			const [ month, year ] = value.split( '/' );
			const monthNum = parseInt( month, 10 );
			const yearNum = parseInt( year, 10 ) + 2000;
			const now = new Date();
			const currentYear = now.getFullYear();
			const currentMonth = now.getMonth() + 1;
			if ( yearNum < currentYear ) return false;
			if ( yearNum === currentYear && monthNum < currentMonth ) return false;
			return true;
		} ),
} );

function CyberSourceMicroform( {
	amount,
	tip,
	dollarTip,
	method,
	createPayment,
	onSubmit,
	gateway,
}: Props ) {
	const microform = useRef<any>();
	const [ loading, setLoading ] = useState( true );
	const [ cybersourceError, setCybersourceError ] = useState( '' );
	
	const formik = useFormik( {
		initialValues: {
			expiryDate: '',
		},
		validationSchema,
		onSubmit: async ( values ) => {},
	} );
	
	onSubmit.current = async () => {
		if ( !isEmpty( await formik.validateForm() ) ) throw new Error( 'Please review the input' );
		const { values } = formik;
		const [ expirationMonth, expirationYear ] = values.expiryDate.split( '/' );
		const cardToken: string = await new Promise( ( resolve, reject ) => {
			microform.current.createToken(
				{ expirationMonth, expirationYear: String( Number( expirationYear ) + 2000 ) },
				( err, token ) => err ? reject( err ) : resolve( token ),
			);
		} );
		
		const data = await createPayment( { cardToken } );
		return data?.payment;
	};
	
	async function onLoad() {
		console.log( 'Cybersource loaded' );
		setLoading( false );
		
		const { data } = await axios.post( '/api/user/cybersource/captureContext/microform', {
			amount   : amount.toString(),
			gatewayId: gateway.id,
		} );
		console.log( 'context', data );
		
		const flex = new window.Flex( data.token );
		// ?? styles not working!!? Copy from https://developer.cybersource.com/docs/cybs/en-us/digital-accept-flex/developer/all/rest/digital-accept-flex/microform-integ-v2/microform-integ-getting-started-v2/flex-getting-started-examples-v2.html#flex-getting-started-examles-v2_checkout-pay-form
		const myStyles = {
			'input': {
				'font-size'  : '14px',
				'font-family': 'helvetica, tahoma, calibri, sans-serif',
				'color'      : 'white',
			},
			':focus'   : { color: 'blue' },
			':disabled': { cursor: 'not-allowed' },
			'valid'    : { color: '#3c763d' },
			'invalid'  : { color: '#a94442' },
		};
		const microformInstance = flex.microform( { styles: myStyles } );
		
		// https://developer.cybersource.com/docs/cybs/en-us/digital-accept-flex/developer/all/rest/digital-accept-flex/microform-integ-v2/api-reference-v2/class-microform-v2.html#class-microform-v2_clear
		const number = microformInstance.createField( 'number', { placeholder: 'Enter card number' } );
		number.load( '#card-number' );
		
		// Create and mount the CVV field
		const securityCode = microformInstance.createField( 'securityCode', { placeholder: 'CVV' } );
		securityCode.load( '#cvv' );
		
		microform.current = microformInstance;
	}
	
	useAsyncEffect( async () => {
		if ( !window.Flex ) return;
		await onLoad();
	}, [] );
	
	if ( loading ) return (
		<Fragment>
			<Skeleton
				animation='wave'
				height={100}
				sx={{ borderRadius: 1 }}
			/>
			<Script
				src={`https://${isProduction ? '' : 'test'}flex.cybersource.com/microform/bundle/v2/flex-microform.min.js`}
				onLoad={onLoad}
			/>
		</Fragment>
	);
	return (
		<Stack spacing={2} my={2} component='form' onSubmit={formik.handleSubmit}>
			<MicroformField label='Card Number' containerProps={{ id: 'card-number' }}/>
			<Box display='grid' gridTemplateColumns='1fr 1fr' gap={2}>
				<Box width='100%'>
					<TextFieldInputLabel
						isError={Boolean( formik.errors.expiryDate )}
						label={Boolean( formik.errors.expiryDate ) ? formik.errors.expiryDate : 'Expiry Date'}
					/>
					<TextField
						fullWidth
						name='expiryDate'
						error={Boolean( formik.errors.expiryDate )}
						value={formik.values.expiryDate}
						inputProps={{
							sx: { color: '#000', backgroundColor: '#fff', borderRadius: 1 },
						}}
						onChange={( e ) => {
							const value = formatExpiryDate( e.target.value );
							formik.setFieldValue( 'expiryDate', value, value.length === 5 );
						}}
						onBlur={formik.handleBlur}
					/>
				</Box>
				<MicroformField label='Security Code' containerProps={{ id: 'cvv' }}/>
			</Box>
			{cybersourceError && <Alert severity='error'>{cybersourceError}</Alert>}
		</Stack>
	);
}

export default CyberSourceMicroform;
