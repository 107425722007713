import { mutateGraphQL, queryGraphQL } from '@/data/apollo';
import { CategoryRead } from '@/data/management/category.graphql';
import { ClientRead, ClientWrite } from '@/data/management/client.graphql';
import { ItemRead } from '@/data/management/item.graphql';
import { LineItemRead } from '@/data/management/lineItem.graphql';
import { LocationUomsUpdateStock } from '@/data/management/locationUom.graphql';
import { ModifierGroupsRead } from '@/data/management/modifierGroup.graphql';
import { PricesRead } from '@/data/management/price.graphql';
import { UomsWrite } from '@/data/management/uom.graphql';
import { convertCloverClient } from '@/gatewayUtils/cloverUtils';
import {
	addQuantitiesForSameItem,
	addReceivedQuantitiesForSameItem,
} from '@/pages/dashboard/commerce/components/calculations';
import { BillingInfo, CloverLineItem } from '@/types/clover';
import { GatewayCredentials } from '@/types/gateway';
import {
	Address,
	Category,
	CategoryValidator,
	Client,
	ItemValidator,
	LineItem,
	LineItemValidator,
	Location,
	LocationValidator,
	Maybe,
	ModifierGroupValidator,
	ModifierValidator,
	MutationClientWriteArgs,
	MutationLineItemsWriteArgs,
	MutationLocationUomsUpdateStockArgs,
	MutationUomsWriteArgs,
	OrderValidator,
	Price,
	PriceValidator,
	QueryCategoriesReadArgs,
	QueryClientReadArgs,
	QueryLineItemReadArgs,
	QueryModifierGroupsReadArgs,
	QueryPricesReadArgs,
	Uom,
	UomValidator,
} from '@/types/schema';
import { CARD_FEE_LABEL } from '@/utils/constants';
import wait from '@/utils/wait';
import { gql } from '@apollo/client';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import Bluebird from 'bluebird';
import { filter } from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import { differenceWith, flatten, isEmpty, keyBy, map, omit, partition, pick, uniqBy } from 'lodash-es';
import mapValues from 'lodash/fp/mapValues';
import toPairs from 'lodash/fp/toPairs';
import { validate } from 'uuid';
import { PLATFORM_APP_ENDPOINT, PLATFORM_ENDPOINT } from '../clover';
import cloverPaymentProcessor from '../payment/clover';
import { getLineItemsToCreate, getModifierGroupsIds } from './cloverHelper';

export const roundCentDown = ( value: number | BigNumber ) =>
	new BigNumber( value ).decimalPlaces( 0, BigNumber.ROUND_DOWN ).toNumber();

export const updateServiceCharge = async (
	gateway: GatewayCredentials,
	orderId: string,
	serviceCharge: { name: string, value: number },
) => {
	console.debug( 'updateServiceCharge', { orderId, serviceCharge } );
	
	const { data } = await axios.get( `${PLATFORM_ENDPOINT}/${gateway.externalId}/default_service_charge`,
		{ headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
	
	try {
		await axios.delete( `${PLATFORM_ENDPOINT}/${gateway.externalId}/orders/${orderId}/service_charge/${data.id}`,
			{ headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
	} catch ( e ) {
		console.debug( e );
	}
	
	if ( serviceCharge.value > 0 ) {
		await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/orders/${orderId}/service_charge`, {
			id               : data.id,
			name             : serviceCharge.name,
			percentageDecimal: Math.round( serviceCharge.value * 10000 ),
		}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
	}
	
};

export async function updateCloverInventory(
	lineItems: LineItem[],
	isStocked: boolean,
	onSuccess?: ( { progress, updatedLineItems }: {
		progress: number,
		updatedLineItems: Array<{ id: string, receivedQuantity: number }>
	} ) => void,
	isCommerce?: boolean,
	location?: Maybe<Location>,
) {
	const stockLineItems = lineItems.map( ( lineItem ) => {
		const uom = lineItem.uom || lineItem.item?.uoms?.find( ( uom ) => uom.selected || uom.quantity );
		return {
			...pick( lineItem, [
				'id',
				'externalId',
				'name',
				'price',
				'cost',
				'image',
				'description',
				'unit',
				'quantity',
				'code',
				'metadata',
			] ),
			stocked         : lineItem?.stocked,
			item            : { id: lineItem.item?.id, externalId: lineItem.item?.externalId || null },
			receivedQuantity: !isCommerce ? lineItem.receivedQuantity || 0 : undefined,
			soldQuantity    : isCommerce ? lineItem.soldQuantity || 0 : undefined,
			uom             : {
				id      : uom?.id,
				name    : uom?.name,
				quantity: uom?.quantity || 0,
			},
		};
	} );
	
	const writeUomAndLineItems = async ( lineItems: typeof stockLineItems ) => {
		const lineItemsWithUoms = !isCommerce
			? addReceivedQuantitiesForSameItem( lineItems.filter( ( lineItem ) => lineItem.uom?.id ), 'uom.id' )
			: addQuantitiesForSameItem( lineItems.filter( ( lineItem ) => lineItem.uom?.id ) as any[], 'uom.id' );
		
		if ( !isEmpty( lineItemsWithUoms ) ) {
			const inputs = lineItemsWithUoms.map( ( lineItem ) => ( {
				name    : lineItem.uom.name,
				id      : lineItem.uom.id,
				quantity: isStocked
					? !isCommerce
						? ( lineItem.uom.quantity || 0 ) - lineItem.receivedQuantity
						: ( lineItem.uom.quantity || 0 ) + lineItem.soldQuantity
					: !isCommerce
						? ( lineItem.uom.quantity || 0 ) + lineItem.receivedQuantity
						: ( lineItem.uom.quantity || 0 ) - lineItem.soldQuantity,
			} ) );
			
			await mutateGraphQL<MutationUomsWriteArgs>( {
				mutation : UomsWrite,
				variables: {
					inputs,
				},
			} );
			if ( location?.id ) {
				await mutateGraphQL<MutationLocationUomsUpdateStockArgs>( {
					mutation : LocationUomsUpdateStock,
					variables: {
						inputs: lineItemsWithUoms.map( ( lineItem ) => {
							const quantity = isStocked
								? !isCommerce ? -lineItem.receivedQuantity : lineItem.soldQuantity
								: !isCommerce ? lineItem.receivedQuantity : -lineItem.soldQuantity;
							return {
								uom     : lineItem.uom?.id,
								quantity,
								location: location?.id,
							};
						} ),
					},
				} );
			}
		}
		
		await mutateGraphQL<MutationLineItemsWriteArgs>( {
			mutation : gql`
				mutation LineItemsWrite_3b86($inputs: [LineItemValidator!]) {
					lineItemsWrite(inputs: $inputs)
				}
			`,
			variables: {
				method: 'Updated Stocks',
				inputs: lineItems.map( ( lineItem ) => ( {
					...lineItem,
					receivedQuantity: !isCommerce ? !isStocked ? lineItem.receivedQuantity : null : undefined,
					soldQuantity    : isCommerce ? !isStocked ? lineItem.soldQuantity : null : undefined,
					stocked         : !isStocked,
					metadata        : {
						stock           : !isStocked,
						purchaseReceived: !isCommerce ? !isStocked : undefined,
					},
					uom             : lineItem.uom?.id ?? undefined,
					item            : lineItem.item?.id ?? undefined,
				} ) ) as any[],
			},
		} );
	};
	
	const lineItemsWithItemExternalId = stockLineItems.filter( ( lineItem ) => lineItem.item?.externalId );
	const lineItemsNoExternalId = stockLineItems.filter( ( lineItem ) => !lineItem.item?.externalId );
	
	if ( !isEmpty( lineItemsNoExternalId ) ) {
		await writeUomAndLineItems( lineItemsNoExternalId );
	}
	
	const onUpdateSuccess = async ( { progress, itemId }: { progress: number, itemId: string } ) => {
		const updatedLineItems = stockLineItems.filter( ( lineItem ) => lineItem.item?.externalId === itemId );
		await writeUomAndLineItems( updatedLineItems );
		onSuccess?.( { progress, updatedLineItems } );
	};
	
	// add lineItem received quantity if same item external id
	const lineItemsWithItems = !isCommerce
		? addReceivedQuantitiesForSameItem( lineItemsWithItemExternalId, 'item.externalId' )
		: addQuantitiesForSameItem( lineItemsWithItemExternalId as any[] );
	const hasExternalItems = !isEmpty( lineItemsWithItems );
	
	if ( hasExternalItems ) {
		const items = !isCommerce
			? mapValues( ( { receivedQuantity }: typeof stockLineItems[0] ) => -receivedQuantity )( keyBy( lineItemsWithItems, 'item.externalId' ) )
			: mapValues( ( { soldQuantity }: typeof stockLineItems[0] ) => soldQuantity )( keyBy( lineItemsWithItems, 'item.externalId' ) );
		
		await cloverManageProcessor.updateInventory(
			items,
			isStocked,
			onUpdateSuccess,
		);
	}
	const hasItems = hasExternalItems || !isEmpty( lineItemsNoExternalId );
	return { hasItems };
}

type TransferUom = {
	diff: number
} & Partial<Uom>;

export async function updateCloverInventoryByUoms(
	uoms: TransferUom[],
	isStocked: boolean,
	onSuccess?: ( { progress, updatedUoms }: {
		progress: number,
		updatedUoms: Array<{ id: string | undefined, quantity: Maybe<number> | undefined }>
	} ) => void,
) {
	
	const writeUoms = async ( uoms: TransferUom[] ) => {
		
		if ( !isEmpty( uoms ) ) {
			const inputs = uoms.map( ( uom ) => ( { ...omit( uom, 'diff' ), item: uom.item?.id || uom.item } ) );
			await mutateGraphQL<MutationUomsWriteArgs>( {
				mutation : UomsWrite,
				variables: {
					inputs: inputs as UomValidator[],
				},
			} );
			
		}
	};
	
	const [ uomsWithItemExternalId, uomsWithNoItemExternalId ] = partition( uoms, ( uom ) => uom.item?.externalId );
	
	if ( !isEmpty( uomsWithNoItemExternalId ) ) {
		await writeUoms( uomsWithNoItemExternalId );
	}
	
	const onUpdateSuccess = async ( { progress, itemId }: { progress: number, itemId: string } ) => {
		const updatedUoms = uomsWithItemExternalId.filter( ( uom ) => uom.item?.externalId === itemId );
		await writeUoms( updatedUoms );
		onSuccess?.( {
			progress,
			updatedUoms: updatedUoms.map( ( uom ) => ( { id: uom.id, quantity: uom.quantity } ) ),
		} );
	};
	
	// update item stock on Clover
	const hasExternalItems = !isEmpty( uomsWithItemExternalId );
	
	if ( hasExternalItems ) {
		const items = mapValues( ( { diff }: TransferUom ) => diff )( keyBy( uomsWithItemExternalId, 'item.externalId' ) );
		await cloverManageProcessor.updateInventory(
			items,
			isStocked,
			onUpdateSuccess,
		);
	}
	const hasItems = hasExternalItems || !isEmpty( uomsWithNoItemExternalId );
	return { hasItems };
}

export async function getBillingInfo( gateway: GatewayCredentials ): Promise<BillingInfo> {
	const { data } = await axios.get(
		`${PLATFORM_APP_ENDPOINT}/merchants/${gateway.externalId}/billing_info`,
		{ headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
	return data;
}

export async function postMeteredBilling( { gateway, count }: { gateway: GatewayCredentials, count?: number } ) {
	const { data } = await axios.post( `${PLATFORM_APP_ENDPOINT}/merchants/${gateway.externalId}/metereds/${process.env.NEXT_PUBLIC_CLOVER_METERED_ID}?count=${count}`,
		{}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
	return data;
}

const cloverManageProcessor = {
	type: 'CLOVER',
	
	async getLocations( gateway ) {
		const { data } = await axios.get( `${PLATFORM_ENDPOINT}/${gateway.externalId}/address`,
			{ headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		return [ data ];
	},
	async convertLocation( gateway, locationId, data ) {
		const address = {
			line1     : data.address1,
			line2     : data.address2,
			city      : data.city,
			state     : data.state,
			postalCode: data.zip,
			country   : data.country,
		};
		return {
			gateway   : gateway.id,
			externalId: locationId || `${gateway.id}_${JSON.stringify( address )}`.slice( 0, 255 ),
			name      : data.address1,
			address,
		} as LocationValidator;
	},
	async getItemTaxes( gateway, page = 0 ) {
		const { data } = await axios.get( `${PLATFORM_ENDPOINT}/${gateway.externalId}/tax_rates`, {
			params : { expand: [ 'items' ].join(), offset: ( +page * 100 ).toString() },
			headers: { Authorization: `Bearer ${gateway.externalKey}` },
		} );
		return { data: data.elements, nextPage: +page + 1 };
	},
	async postTaxRates( gateway, { name, isPercent, value, externalId, metadata }: Price ) {
		const { data } = await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/tax_rates${externalId
			? `/${externalId}`
			: ''}`, {
			name,
			rate     : isPercent ? value * 100000 || 0 : undefined,
			taxAmount: isPercent ? undefined : value * 100,
			isDefault: metadata?.isDefault,
		}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		return data;
	},
	async convertItemTax( gateway, data ) {
		const isPercent = data?.rate > 0 ? true : data?.taxAmount <= 0;
		return {
			name      : data.name,
			company   : data.company?.id,
			isPercent : isPercent,
			externalId: data.id,
			gateway   : gateway.id,
			metadata  : { isDefault: data.isDefault, externalTax: true },
			value     : data?.taxAmount > 0 ? data.taxAmount / 100 : data?.rate / 100000,
			
		} as PriceValidator;
	},
	async getItem( gateway, id ) {
		const { data } = await axios.get( `${PLATFORM_ENDPOINT}/${gateway.externalId}/items/${id}`, {
			params : {
				expand: [ 'taxRates', 'categories', 'modifierGroups', 'itemStock' ].join(),
			},
			headers: { Authorization: `Bearer ${gateway.externalKey}` },
		} );
		return data;
	},
	async getItems( gateway, location, page = 0 ) {
		const { data } = await axios.get( `${PLATFORM_ENDPOINT}/${gateway.externalId}/items`, {
			params : {
				expand: [ 'itemStock', 'taxRates', 'modifierGroups', 'categories' ].join(),
				offset: ( +page * 20 ).toString(),
				limit : 20,
			},
			headers: { Authorization: `Bearer ${gateway.externalKey}` },
		} );
		return { data: data.elements, nextPage: +page + 1 };
	},
	async postItem( gateway, { externalId, name, uoms, categories, taxable, taxes, isHidden } ) {
		const uom = uoms.find( ( { selected } ) => selected );
		
		const cloverCategories = categories.filter( ( { externalId } ) => externalId );
		
		const { data } = await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/items${externalId
			? `/${externalId}`
			: ''}?expand=categories`, {
			name,
			price    : Math.round( ( uom?.price || 0 ) * 100 ),
			unitName : uom?.name,
			code     : uom.code,
			sku      : uom.sku,
			cost     : Math.round( ( uom?.cost || 0 ) * 100 ),
			available: isHidden !== true,
			taxRates : taxes?.filter( ( { externalId } ) => externalId )
				.map( ( tax ) => ( { id: tax.externalId } ) ),
			// categories     : [],
			defaultTaxRates: taxable,
		}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		
		const itemCloverCategories = data?.categories?.elements;
		
		// un link clover item with its categories
		if ( !isEmpty( itemCloverCategories ) && ( data?.id || externalId ) ) {
			await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/category_items?delete=true`, {
				elements: [ { item: { id: data?.id || externalId } } ],
			}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		}
		
		// link clover categories with this item
		if ( !isEmpty( cloverCategories ) && ( data?.id || externalId ) ) {
			await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/category_items`, {
				elements: cloverCategories.map( ( category ) => ( {
					category: { id: category.externalId },
					item    : { id: data?.id || externalId },
				} ) ),
			}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		}
		
		if ( data?.id || externalId ) {
			await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/item_stocks/${data?.id || externalId}`, {
				quantity: uom?.quantity || 0,
			}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		}
		
		return data;
	},
	async deleteItem( gateway, { externalId } ) {
		const { data } = await axios.delete( `${PLATFORM_ENDPOINT}/${gateway.externalId}/items/${externalId}`,
			{ headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		return data;
	},
	async convertItem( gateway, location, data ) {
		const categoryIds = data?.categories?.elements.map( ( { id } ) => id );
		const modifierGroupsIds = data?.modifierGroups?.elements.map( ( { id } ) => id );
		const taxRatesIds = data?.taxRates?.elements.map( ( { id } ) => id );
		
		let categoriesRead: { items: Pick<Category, 'id'>[] };
		if ( categoryIds?.length ) {
			try {
				const data = await queryGraphQL<QueryCategoriesReadArgs>( {
					query    : gql`
						query CategoriesRead_dbac($options: FilterOptions) {
							categoriesRead(options: $options) {
								items {
									id
								}
							}
						}
					`,
					variables: { options: { limit: categoryIds.length, filter: { externalId: { $in: categoryIds } } } },
				} );
				categoriesRead = data.categoriesRead;
			} catch {
			}
		}
		
		let taxesRead;
		if ( taxRatesIds?.length ) {
			try {
				const data = await queryGraphQL<QueryPricesReadArgs>( {
					query    : PricesRead,
					variables: { options: { filter: { externalId: { $in: taxRatesIds } } } },
				} );
				taxesRead = data.pricesRead;
			} catch {
			}
		}
		
		let modifierGroups;
		if ( modifierGroupsIds?.length ) {
			try {
				const data = await queryGraphQL<QueryModifierGroupsReadArgs>( {
					query    : ModifierGroupsRead,
					variables: { options: { filter: { externalId: { $in: modifierGroupsIds } } } },
				} );
				modifierGroups = data.modifierGroupsRead;
			} catch {
			}
		}
		return {
			gateway       : gateway.id,
			externalId    : data.id,
			name          : data.name.replace( '/\0/g', '' ),
			taxable       : data.defaultTaxRates || !isEmpty( data.taxRates?.elements ),
			syncDate      : data.modifiedTime ? new Date( +data.modifiedTime ) : null,
			uoms          : [ {
				name    : data.unitName || 'Unit',
				price   : data.price / 100,
				cost    : data.cost / 100,
				quantity: data.itemStock?.quantity,
				sku     : data.sku,
				selected: true,
				code    : data.code,
			} ],
			isHidden      : data.available === false ? true : null,
			taxes         : taxesRead ? map( taxesRead.items, 'id' ) : [],
			locations     : [ location ],
			categories    : categoriesRead! ? map( categoriesRead.items, 'id' ) : [],
			modifierGroups: modifierGroups ? map( modifierGroups.items, 'id' ) : [],
		} as ItemValidator;
	},
	
	async getCategories( gateway, location, page = 0 ) {
		const { data } = await axios.get( `${PLATFORM_ENDPOINT}/${gateway.externalId}/categories`, {
			params : { expand: 'items', offset: ( +page * 100 ).toString() },
			headers: { Authorization: `Bearer ${gateway.externalKey}` },
		} );
		return { data: data.elements, nextPage: +page + 1 };
	},
	async getCategory( gateway, id ) {
		const { data } = await axios.get( `${PLATFORM_ENDPOINT}/${gateway.externalId}/categories/${id}`, {
			params : { expand: 'items' },
			headers: { Authorization: `Bearer ${gateway.externalKey}` },
		} );
		return data;
	},
	async postCategory( gateway, { externalId, name, items } ) {
		const itemsWithExternalId = items?.filter( ( { externalId } ) => externalId );
		
		const { data } = await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/categories${externalId
			? `/${externalId}`
			: ''}?expand=items`, {
			name,
			// items: map( items, 'id' ),
		}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		
		const cloverItems = data?.items?.elements;
		
		if ( !isEmpty( cloverItems ) ) {
			await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/category_items?delete=true`, {
				elements: [ { category: { id: data?.id || externalId } } ],
			}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		}
		
		if ( !isEmpty( itemsWithExternalId ) ) {
			await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/category_items`, {
				elements: itemsWithExternalId.map( ( item ) => ( {
					item    : { id: item.externalId },
					category: { id: data?.id || externalId },
				} ) ),
			}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		}
		
		return data;
	},
	async deleteCategory( gateway, { externalId } ) {
		const { data } = await axios.delete( `${PLATFORM_ENDPOINT}/${gateway.externalId}/categories/${externalId}`,
			{ headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		return data;
	},
	async convertCategory( gateway, location, data ) {
		// const itemIds = data.items?.elements.map( ( { id } ) => id );
		// const { itemsRead } = await queryGraphQL<QueryItemsReadArgs>( {
		// 	query    : ItemsRead,
		// 	variables: { options: { filter: { externalId: { $in: itemIds } } } },
		// } );
		return {
			gateway   : gateway.id,
			externalId: data.id,
			name      : data.name,
			locations : location ? [ location ] : undefined,
			// items     : map( itemsRead.items, 'id' ),
		} as CategoryValidator;
	},
	async getClient( gateway, id ) {
		const { data } = await axios.get( `${PLATFORM_ENDPOINT}/${gateway.externalId}/customers/${id}`, {
			params : {
				expand: [ 'addresses', 'emailAddresses', 'phoneNumbers', 'metadata' ].join(),
			},
			headers: { Authorization: `Bearer ${gateway.externalKey}` },
		} );
		return data;
	},
	async getClients( gateway, page = 0, pageSize = 100 ) {
		const { data } = await axios.get( `${PLATFORM_ENDPOINT}/${gateway.externalId}/customers`, {
			params : {
				expand: [ 'addresses', 'emailAddresses', 'phoneNumbers', 'metadata' ].join(),
				offset: ( +page * pageSize ).toString(),
				limit : pageSize,
			},
			headers: { Authorization: `Bearer ${gateway.externalKey}` },
		} );
		return { data: data.elements, nextPage: +page + 1 };
	},
	async postClient( gateway, { externalId, name, contact, email, phone, addresses, metadata } ) {
		
		let firstName = '';
		let lastName: string[] = [];
		if ( contact ) {
			const [ first, ...last ] = contact.split( ' ' );
			firstName = first;
			lastName = last;
		}
		
		if ( externalId ) {
			await axios.get( `${PLATFORM_ENDPOINT}/${gateway.externalId}/customers/${externalId}`, {
				params : { expand: [ 'addresses', 'emailAddresses', 'phoneNumbers', 'metadata' ].join() },
				headers: { Authorization: `Bearer ${gateway.externalKey}` },
			} ).then( async ( { data } ) => {
				if ( data.phoneNumbers?.elements.length && phone ) {
					if ( data.phoneNumbers.elements[ 0 ].phoneNumber !== phone ) {
						await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/customers/${externalId}/phone_numbers/${data.phoneNumbers.elements[ 0 ].id}`, {
							phoneNumber: phone,
						}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
					}
					phone = undefined;
				}
				
				if ( data.emailAddresses?.elements.length && email ) {
					if ( data.emailAddresses.elements[ 0 ].emailAddress !== email ) {
						await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/customers/${externalId}/email_addresses/${data.emailAddresses.elements[ 0 ].id}`, {
							emailAddress: email,
						}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
					}
					email = undefined;
				}
				
				if ( !isEmpty( addresses ) ) {
					addresses.forEach( ( address ) => {
						const [ countryFirst, countryLast ] = address.country.split( ' ' );
						address.country = countryLast
							? `${countryFirst[ 0 ]}${countryLast[ 0 ]}`
							: countryFirst.slice( 0, 2 );
					} );
					
					const [ addressWithExternalId, addressWithoutExternalId ] = partition( addresses, ( address ) => address.externalId );
					const cloverAddresses = data.addresses?.elements;
					
					const newAddressesToSave = differenceWith( addressWithoutExternalId, cloverAddresses, ( a: Address,
						b: any ) => a?.line1 === b?.address1 ) || [];
					
					const addressesToSave = [ ...addressWithExternalId, ...newAddressesToSave ];
					
					if ( !isEmpty( addressesToSave ) ) {
						for ( const address of addressesToSave ) {
							await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/customers/${externalId}/addresses/${address.externalId
								? address.externalId
								: ''}`, {
								address1: address.line1,
								address2: address.line2,
								city    : address.city,
								country : address.country,
								state   : address.state,
								zip     : address.postalCode,
							}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } ).catch( () => null );
						}
					}
					
					addresses = undefined;
				}
				// if ( data.addresses?.elements.length ) addresses = undefined;
				// if ( data.emailAddresses?.elements.length ) email = undefined;
				// if ( data.phoneNumbers?.elements.length ) phone = undefined;
			} ).catch( ( e ) => {
				if ( e.response?.status === 404 ) {
					externalId = undefined;
				} else {
					console.error( e );
				}
			} );
			
		}
		const { data } = await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/customers${externalId
			? `/${externalId}`
			: ''}`, {
			firstName,
			lastName      : lastName.join( ' ' ),
			addresses     : !externalId ? addresses?.map( ( address ) => {
				const [ countryFirst, countryLast ] = address.country.split( ' ' );
				if ( !countryLast ) {
					address.country = countryFirst.slice( 0, 2 );
				} else {
					address.country = `${countryFirst[ 0 ]}${countryLast[ 0 ]}`;
				}
				
				return {
					address1: address.line1,
					address2: address.line2,
					city    : address.city,
					country : address.country,
					state   : address.state,
					zip     : address.postalCode,
				};
			} ) : undefined,
			emailAddresses: email ? [ { emailAddress: email } ] : undefined,
			phoneNumbers  : phone ? [ { phoneNumber: phone } ] : undefined,
			metadata      : { businessName: name, note: metadata.note },
		}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		return data;
	},
	async deleteClient( gateway, { externalId } ) {
		const { data } = await axios.delete( `${PLATFORM_ENDPOINT}/${gateway.externalId}/customers/${externalId}`,
			{ headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		return data;
	},
	async getOrder( gateway, id ) {
		const { data } = await axios.get( `${PLATFORM_ENDPOINT}/${gateway.externalId}/orders/${id}`, {
			params : {
				expand: [
					'lineItems',
					'lineItems.item',
					'serviceCharge',
					'discounts',
					'orderType',
					'payments',
					'payments.tender',
					'payments.cardTransaction',
					'payments.additionalCharges',
					'customers',
					'refunds.payment.tender',
					'refunds.additionalCharges',
					'lineItems.discounts',
					'lineItems.modifications',
					'lineItems.taxRates',
				].join(),
			},
			headers: { Authorization: `Bearer ${gateway.externalKey}` },
		} );
		return data;
	},
	async getOrders( gateway, location, page = 0, pageSize = 30, companyId ) {
		// const filterByClientCreatedTime = companyId === '265b71f8-6d67-477f-abcf-1bb08337e716';
		// const currentTimeInMilliseconds = new Date().getTime();
		
		if ( page * pageSize > 5000 ) return { data: [], nextPage: +page + 1 }; // cap at 1800
		const { data } = await axios.get( `${PLATFORM_ENDPOINT}/${gateway.externalId}/orders`, {
			params : {
				// ...filterByClientCreatedTime && {
				// 	filter: `clientCreatedTime<${currentTimeInMilliseconds}`,
				// },
				expand: [
					'lineItems',
					'lineItems.item',
					'serviceCharge',
					'discounts',
					'orderType',
					'payments',
					'payments.additionalCharges',
					'refunds.additionalCharges',
					'payments.tender',
					'customers',
					'refunds.payment.tender',
					'lineItems.discounts',
					'lineItems.modifications',
					'lineItems.taxRates',
				].join(),
				offset: ( +page * pageSize ).toString(),
				limit : pageSize,
			},
			headers: { Authorization: `Bearer ${gateway.externalKey}` },
		} );
		return { data: data.elements, nextPage: +page + 1 };
	},
	async postOrder( gateway, {
		externalId,
		serviceType,
		status,
		client,
		grandTotal,
		id,
		companyLocation,
		lineItems,
		taxPercent,
		prices,
		staff,
		metadata,
		updatedAt,
		title,
		notes,
		po,
		company,
		type,
		payments,
	}, sendNote ) {
		const [ discounts, charges ] = partition( prices?.filter( ( price ) => !price.metadata?.cloverTaxPercent ), ( { value } ) => value < 0 );
		const [ chargesPercentage ] = partition( charges, ( price ) => price.isPercent );
		
		if ( isEmpty( lineItems ) ) {
			throw 'Please add an item before syncing to Clover';
		}
		const lineItemDollarFee = lineItems?.map( ( lineItem ) => lineItem.prices?.find( ( price ) => price.value > 0 && !price.metadata?.externalTax ) );
		if ( !isEmpty( lineItemDollarFee.filter( Boolean ) ) ) {
			throw 'A dollar amount fee on your item is not supported by Clover. Please add that as a modifier.';
		}
		if ( charges.find( ( { isPercent } ) => !isPercent ) )
			throw 'A dollar amount order fee is not supported by Clover. please change that to a percentage to sync properly';
		
		if ( !isEmpty( lineItems ) ) {
			const lineItemWithMoreDescription = lineItems.find( ( lineItem ) => lineItem.description?.length > 255 );
			if ( lineItemWithMoreDescription ) {
				throw 'Line item description above 255 characters is not supported by Clover.';
			}
		}
		
		for ( const { prices } of lineItems ) {
			for ( const { isPercent, value, metadata } of prices ) {
				if ( value <= 0 || metadata?.externalTax ) continue;
				if ( isPercent ) {
					throw 'A percentage line item fee is not supported by Clover. please remove that to sync properly';
				}
			}
		}
		let cloverClient: Client;
		if ( client ) {
			try {
				const data = await this.postClient( gateway, client as any );
				if ( data ) {
					const { clientWrite } = await mutateGraphQL<MutationClientWriteArgs>( {
						mutation : ClientWrite,
						variables: {
							id   : client.id,
							input: {
								gateway      : gateway.id,
								externalId   : data?.id || null,
								externalValue: metadata?.qbClientId || undefined,
							},
						},
					} );
					cloverClient = { ...clientWrite, externalId: data?.id || null };
				}
			} catch {
			}
		}
		
		let orderType;
		if ( serviceType ) {
			const { data: types } = await axios.get( `${PLATFORM_ENDPOINT}/${gateway.externalId}/order_types`,
				{ headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
			orderType = types.elements.find( ( { label } ) => label === serviceType );
			if ( !orderType ) {
				const { data } = await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/order_types`, {
					label   : serviceType,
					isHidden: true,
				}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
				orderType = data;
			}
		}
		
		const { data } = await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/orders${externalId
			? `/${externalId}`
			: ''}`, {
			currency: gateway.currency,
			// externalReferenceId: clientAddress?.line1?.substring( 0, 12 ),
			orderType: orderType?.id ? { id: orderType.id } : null,
			state    : company?.metadata?.hideCloverInvoices && type === 'INVOICE' || metadata?.state === null || metadata?.hideOnClover
				? null // hide invoice on Clover order page
				: 'open',
		}, {
			params : { expand: [ 'lineItems', 'serviceCharge', 'discounts' ].join() },
			headers: { Authorization: `Bearer ${gateway.externalKey}` },
		} );
		
		notes = po ? notes?.includes( po ) ? notes : `${po}-${notes || ''}` : notes;
		
		await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/orders/${data.id}`, {
			customers   : cloverClient ? [ { id: cloverClient.externalId } ] : client?.externalId
				? [ { id: client.externalId } ]
				: null,
			note        : notes?.length < 200 ? notes || '' : undefined,
			employee    : staff?.externalId ? { id: staff.externalId } : undefined,
			paymentState: {
				DRAFT         : 'OPEN',
				SENT          : 'OPEN',
				VIEWED        : 'OPEN',
				COMPLETED     : 'PAID',
				PARTIALLY_PAID: 'PARTIALLY_PAID',
				REFUNDED      : 'REFUNDED',
				PAID          : 'PAID',
				CANCELLED     : 'FAILED',
			}[ status ] || 'OPEN',
			taxRemoved  : !!cloverClient?.metadata?.exemptFromTax,
			title,
			modifiedTime: Math.floor( new Date( updatedAt ).getTime() ),
			total       : Math.round( grandTotal * 100 ),
		}, {
			params : { expand: [ 'lineItems', 'serviceCharge', 'discounts' ].join() },
			headers: { Authorization: `Bearer ${gateway.externalKey}` },
		} );
		
		const { data: serviceCharge } = await axios.get( `${PLATFORM_ENDPOINT}/${gateway.externalId}/default_service_charge`,
			{ headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		
		if ( data.serviceCharge ) {
			await axios.delete( `${PLATFORM_ENDPOINT}/${gateway.externalId}/orders/${data.id}/service_charge/${data.serviceCharge.id}`,
				{ headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		}
		
		const chargePercentageTotal = chargesPercentage.reduce( ( acc, { value } ) => acc + value, 0 );
		if ( chargePercentageTotal > 0 ) {
			const hasCardFee = chargesPercentage.some( ( charge ) => charge?.name === CARD_FEE_LABEL );
			const chargeName = hasCardFee ? CARD_FEE_LABEL : chargesPercentage?.[ 0 ]?.name;
			
			await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/orders/${data.id}/service_charge`, {
				id               : serviceCharge.id,
				name             : chargeName,
				percentageDecimal: Math.round( chargePercentageTotal * 10000 ),
			}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		}
		
		for ( const discount of data.discounts?.elements || [] ) {
			await axios.delete( `${PLATFORM_ENDPOINT}/${gateway.externalId}/orders/${data.id}/discounts/${discount.id}`,
				{ headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		}
		
		for ( const discount of discounts ) {
			const discountResult = await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/orders/${data.id}/discounts`, {
				name: discount.name,
				...discount.isPercent
					? { percentage: Math.round( Math.abs( discount.value ) ) }
					: { amount: Math.round( discount.value * 100 ) },
			}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		}
		
		const { data: taxes } = await axios.get( `${PLATFORM_ENDPOINT}/${gateway.externalId}/tax_rates`,
			{ headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		let defaultTax = taxes.elements.find( ( { name } ) => name === 'Tax (auto)' );
		if ( !defaultTax ) {
			const { data } = await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/tax_rates`, {
				name: 'Tax (auto)',
				rate: 0,
			}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
			defaultTax = data;
		}
		
		await axios.delete( `${PLATFORM_ENDPOINT}/${gateway.externalId}/orders/${data.id}/line_items`,
			{ headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		
		const chunkedLineItems: CloverLineItem[] = [];
		
		for ( let i = 0; i < lineItems.length; i += 100 ) {
			const lineItemChunk = lineItems.slice( i, i + 100 );
			const { data: items } = await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/orders/${data.id}/bulk_line_items`, {
				items: getLineItemsToCreate( lineItemChunk, taxes, taxPercent, defaultTax, companyLocation ),
			}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
			
			chunkedLineItems.push( ...!isEmpty( items ) ? items.map( ( lineItem ) => ( {
				...lineItem,
				alternateName: lineItem?.alternateName?.split( ':' )?.[ 0 ] || '',
			} ) ) : [] );
		}
		
		const cloverLineItemsByBinName = keyBy( chunkedLineItems, ( lineItem ) => lineItem.alternateName );
		const modifications: { lineItemId: string, payload: object }[] = [];
		
		for ( let i = 0; i < lineItems.length; i++ ) {
			const lineItem = lineItems[ i ];
			const cloverLineItem = cloverLineItemsByBinName[ lineItem.id ];
			
			const modifiers = uniqBy( flatten( lineItem.modifierGroups?.map( ( mGroup ) => mGroup.modifiers?.filter( ( modifier ) => lineItem.metadata?.[ modifier.externalId ] ) ) ), 'externalId' );
			if ( !isEmpty( modifiers ) ) {
				for ( const { name, value, externalId } of modifiers as any ) {
					if ( lineItem.metadata?.[ externalId ] < 1 || !externalId ) continue;
					const quantity = lineItem.metadata?.[ externalId ] * lineItem.quantity;
					if ( !quantity ) continue;
					const payload = {
						name    : name,
						amount  : Math.round( value * 100 ),
						modifier: { id: externalId },
					};
					modifications.push( ...new Array( quantity ).fill( {
						lineItemId: cloverLineItem?.id || chunkedLineItems[ i ].id,
						payload,
					} ) );
				}
			}
			
			const lineItemDiscounts = lineItem.prices?.filter( ( price ) => price.value <= 0 && !price.metadata?.externalTax );
			if ( !isEmpty( lineItemDiscounts ) ) {
				for ( const { isPercent, name, value } of lineItemDiscounts ) {
					await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/orders/${data.id}/line_items/${cloverLineItem?.id || chunkedLineItems[ i ].id}/discounts`, {
						name: name,
						...isPercent
							? { percentage: Math.round( Math.abs( value ) ) }
							: { amount: Math.round( value * lineItem.quantity * 100 ) },
					}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
				}
			}
		}
		
		if ( !isEmpty( modifications ) ) {
			const isParallel = modifications.length > 40;
			await Bluebird.map(
				modifications,
				( { lineItemId, payload } ) => Promise.all( [ axios.post(
					`${PLATFORM_ENDPOINT}/${gateway.externalId}/orders/${data.id}/line_items/${lineItemId}/modifications`,
					payload,
					{ headers: { Authorization: `Bearer ${gateway.externalKey}` } } ), wait( 150 ) ] ),
				{ concurrency: isParallel ? 2 : 1 },
			);
		}
		
		// sync payments if they weren't synced and are PAID
		if ( !isEmpty( payments ) ) {
			const paymentsToSync = payments.filter( ( payment ) => !payment.externalId && payment.status === 'PAID' );
			if ( !isEmpty( paymentsToSync ) ) {
				await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/payment/sendPaymentsToClover`, {
					orderId        : id,
					orderExternalId: data?.id,
					staffExternalId: staff?.externalId,
				} );
			}
		}
		
		return data;
	},
	async deleteOrder( gateway, { externalId } ) {
		const { data } = await axios.delete( `${PLATFORM_ENDPOINT}/${gateway.externalId}/orders/${externalId}`,
			{ headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		return data;
	},
	async deleteOrders( gateway, externalIds ) {
		
		const deleteOrder = async ( gateway, externalId ) => {
			await Promise.all( [ wait( 200 ),
			                     await axios.delete( `${PLATFORM_ENDPOINT}/${gateway.externalId}/orders/${externalId}`,
				                     { headers: { Authorization: `Bearer ${gateway.externalKey}` } } ) ] );
		};
		
		await Bluebird.map(
			externalIds,
			( externalId ) => deleteOrder( gateway, externalId ), { concurrency: 1 },
		);
		
	},
	async convertOrder( gateway, location, data, modifiers ) {
		let client = undefined;
		if ( data?.customers?.elements[ 0 ]?.id ) {
			try {
				const { clientRead } = await queryGraphQL<QueryClientReadArgs>( {
					query    : ClientRead,
					variables: { externalId: data.customers.elements[ 0 ].id },
				} );
				client = clientRead;
			} catch {
				if ( typeof window === 'undefined' ) {
					const { data: customer } = await axios.get( `${PLATFORM_ENDPOINT}/${gateway.externalId}/customers/${data.customers.elements[ 0 ].id}`, {
						params : { expand: [ 'addresses', 'emailAddresses', 'phoneNumbers', 'metadata' ].join() },
						headers: { Authorization: `Bearer ${gateway.externalKey}` },
					} );
					const { clientWrite } = await mutateGraphQL<MutationClientWriteArgs>( {
						mutation : ClientWrite,
						variables: {
							externalId: data.customers.elements[ 0 ].id,
							input     : convertCloverClient( gateway, customer ),
						},
						context  : { headers: { company: data.company.id || '' } },
					} );
					client = clientWrite;
				}
			}
		}
		let refundedPayments = [];
		if ( !isEmpty( data?.refunds?.elements ) ) {
			refundedPayments = data.refunds.elements.map( ( refund ) => ( {
				refundedAmount   : refund.amount,
				amount           : refund.amount,
				tipAmount        : 0,
				note             : refund.payment?.note,
				tender           : { label: refund.payment?.tender?.label },
				id               : refund.id,
				parentPaymentId  : refund?.payment?.id,
				createdTime      : refund.createdTime,
				additionalCharges: {
					elements: !isEmpty( refund?.additionalCharges?.elements ) ? refund?.additionalCharges?.elements : [],
				},
				
			} ) );
		}
		
		return {
			gateway        : gateway.id,
			externalId     : data.id,
			client         : client?.id || null,
			companyLocation: location,
			// serviceDate    : data.createdTime ? new Date( data.createdTime ) : null,
			// clientAddress  : client?.addresses?.[ 0 ].id || null,
			createdAt    : data.createdTime ? new Date( data.createdTime ) : undefined,
			updatedAt    : data.modifiedTime ? new Date( data.modifiedTime ) : undefined,
			syncDate     : data.modifiedTime ? new Date( +data.modifiedTime ) : undefined,
			number       : data.id,
			title        : data?.title,
			notes        : data.note?.length ? data.note : undefined,
			serviceType  : data.orderType?.label,
			overrideTotal: data.total / 100 || null,
			prices       : [
				...data.serviceCharge ? [ {
					name     : data.serviceCharge.name,
					isPercent: true,
					value    : data.serviceCharge.percentageDecimal / 10000,
				} ] : [],
				...data?.discounts?.elements.map( ( discount ) => ( {
					name     : discount.name,
					isPercent: Boolean( discount.percentage ),
					value    : discount.amount / 100 || -discount.percentage,
					metadata : { clientCreditId: discount.name === 'Credit' && client?.id },
				} ) ) || [],
			],
			lineItems    : await Promise.all( data?.lineItems?.elements.reduce( ( array, lineItem ) => {
				const modifications = lineItem.modifications?.elements || [],
				      discounts     = lineItem.discounts?.elements || [],
				      taxRates      = lineItem.taxRates?.elements || [];
				const item = array.find( ( stored ) =>
					lineItem.name === stored.name
					&& lineItem.price === stored.price
					&& lineItem.note === stored.note
					&& lineItem?.itemCode === stored?.itemCode
					&& lineItem?.binName === stored?.binName
					&& modifications.length === ( stored.modifications?.elements || [] ).length
					&& modifications.every( ( modification, index ) => {
						const storedModification = stored.modifications?.elements[ index ];
						if ( !storedModification ) return false;
						return modification.name === storedModification.name
							&& modification.amount === storedModification.amount;
					} )
					&& discounts.length === ( stored.discounts?.elements || [] ).length
					&& discounts.every( ( discount, index ) => {
						const storedDiscount = stored.discounts?.elements[ index ];
						if ( !storedDiscount ) return false;
						return discount.name === storedDiscount.name
							&& discount.amount === storedDiscount.amount
							&& discount.percentage === storedDiscount.percentage;
					} )
					&& taxRates.length === ( stored.taxRates?.elements || [] ).length
					&& taxRates.every( ( taxRate, index ) => {
						const storedTaxRate = stored.taxRates?.elements[ index ];
						if ( !storedTaxRate ) return false;
						return taxRate.name === storedTaxRate.name
							&& ( taxRate.rate === storedTaxRate.rate || taxRate.taxAmount === storedTaxRate.taxAmount );
					} ) );
				
				if ( item ) {
					item.hasSameLineItem = true;
					item.unitQty = item.unitQty + ( lineItem.unitQty ?? 1000 );
				} else {
					lineItem.hasSameLineItem = false;
					lineItem.unitQty = lineItem.unitQty ?? 1000;
					array.push( lineItem );
				}
				return array;
			}, [] ).map( async ( lineItem ) => {
				// we save the original line item's uuid: item name in alternateName field on Clover
				const lineItemId = lineItem?.alternateName?.split( ':' )?.[ 0 ];
				const hasUuid = validate( lineItemId );
				let item, category, lineItemItem;
				if ( !hasUuid && lineItem.item?.id ) {
					try {
						const { itemRead } = await queryGraphQL( {
							query    : ItemRead,
							variables: { externalId: lineItem.item?.id },
						} );
						item = itemRead.id;
						lineItemItem = itemRead;
					} catch {
					}
				}
				
				const binHasCategory = lineItem?.binName?.includes( ':' );
				
				if ( binHasCategory && !hasUuid ) {
					const [ categoryName, externalId ] = lineItem.binName.split( ':' );
					if ( externalId ) {
						const id = validate( externalId );
						try {
							const { categoryRead } = await queryGraphQL( {
								query    : CategoryRead,
								variables: id ? { id: externalId } : { externalId },
							} );
							category = categoryRead.id;
							await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/orders/${data.id}/line_items/${lineItem.id}`, {
								binName: categoryName,
							}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
						} catch {
						}
					} else {
						try {
							const { lineItemRead } = await queryGraphQL<QueryLineItemReadArgs>( {
								query    : LineItemRead,
								variables: { externalId: lineItem.id },
							} );
							category = lineItemRead.category?.id;
						} catch {
						}
					}
				}
				const { modifierGroupsIds, metadata } = await getModifierGroupsIds( lineItem, modifiers );
				
				const lineItemQuantity = ( lineItem?.unitQty ?? 1000 ) / 1000;
				const multipleTaxes = lineItem.taxRates?.elements.filter( ( tax ) => tax?.name !== 'Tax (auto)' );
				const discounts = lineItem?.discounts?.elements || [];
				if ( lineItem?.hasSameLineItem && !isEmpty( discounts ) ) {
					lineItem.discounts.elements = discounts.map( ( discount ) => {
						if ( discount?.percentage ) {
							return discount;
						} else {
							return { ...discount, amount: discount.amount *= lineItemQuantity };
						}
					} );
				}
				
				return {
					item       : hasUuid ? undefined : item,
					uom        : hasUuid ? undefined : lineItemItem
						? lineItemItem.uoms?.find( ( uom ) => uom?.selected || !uom?.removed )?.id
						: undefined,
					id         : hasUuid ? lineItemId : undefined,
					externalId : lineItem.id,
					name       : lineItem.name || '',
					price      : lineItem.price / 100,
					cost       : ( lineItem.item?.cost || 0 ) / 100 || undefined,
					quantity   : lineItemQuantity,
					unit       : lineItem.unitName ?? 'Unit',
					description: lineItem.note,
					code       : lineItem?.itemCode || '',
					isRevenue  : lineItem.isRevenue,
					category   : hasUuid ? undefined : category,
					binName    : !binHasCategory ? lineItem?.binName : undefined,
					image      : hasUuid ? undefined : lineItemItem?.image ? lineItemItem.image : undefined,
					metadata   : { ...metadata, modifierGroupsIds, refunded: lineItem?.refunded } || undefined,
					prices     : [
						...lineItem?.discounts?.elements.map( ( discount ) => ( {
							name     : discount.name,
							isPercent: Boolean( discount.percentage ),
							value    : discount.amount / 100 / lineItemQuantity || -discount.percentage,
						} ) ) || [],
						...!data.taxRemoved && !isEmpty( multipleTaxes ) ? multipleTaxes.map( ( tax ) => {
							const isPercent = tax?.rate > 0 ? true : tax?.taxAmount <= 0;
							
							return {
								name      : tax.name,
								externalId: null,
								metadata  : { externalId: tax.id, externalTax: true, isDefault: tax.isDefault, useTax: true },
								isPercent : isPercent,
								value     : ( tax?.taxAmount > 0
									? tax.taxAmount / 100 / lineItemQuantity
									: tax?.rate / 100000 ) || 0,
							};
						} ) : [],
					
					],
					tax        : data.taxRemoved ? 0 : isEmpty( multipleTaxes )
						? lineItem.taxRates?.elements.find( ( tax ) => tax.name === 'Tax (auto)' )?.rate / 100000 || 0
						: 0,
					// tax        : lineItem.taxRates?.elements.find( ( tax ) => tax.name === 'Tax (auto)' )?.rate / 100000 || 0,
				} as LineItemValidator;
			} ) || [] ),
			payments     : await Promise.all( [ ...!isEmpty( data?.payments?.elements )
				? data.payments.elements.map( async ( payment ) => ( {
					client: client?.id, ...await cloverPaymentProcessor.convertPayment( gateway, location, payment ),
				} ) )
				: [], ...!isEmpty( refundedPayments ) ? refundedPayments?.map( async ( payment ) => ( {
				client: client?.id, ...await cloverPaymentProcessor.convertPayment( gateway, location, payment ),
			} ) ) : [] ] || [] ),
		} as OrderValidator;
	},
	async getModifiers( gateway, page = 0, ids ) {
		const { data } = await axios.get( `${PLATFORM_ENDPOINT}/${gateway.externalId}/modifiers`, {
			params : {
				expand: 'modifierGroup',
				limit : 1000,
				filter: ids?.length ? `id in (${ids.map( ( id ) => `'${id}'` ).join( ',' )})` : undefined,
			},
			headers: { Authorization: `Bearer ${gateway.externalKey}` },
		} );
		return { data: data.elements, nextPage: +page + 1 };
	},
	async getModifierGroups( gateway, page = 0 ) {
		const { data } = await axios.get( `${PLATFORM_ENDPOINT}/${gateway.externalId}/modifier_groups`, {
			params : { expand: [ 'modifiers' ].join(), offset: ( +page * 100 ).toString() },
			headers: { Authorization: `Bearer ${gateway.externalKey}` },
		} );
		return { data: data.elements, nextPage: +page + 1 };
	},
	async postModifierGroup( gateway, { externalId, name, min, max, modifiers } ) {
		const { data } = await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/modifier_groups${externalId
			? `/${externalId}`
			: ''}`, {
			name,
			minRequired  : min,
			maxAllowed   : max,
			showByDefault: false,
		}, {
			params : { expand: [ 'modifiers' ].join() },
			headers: { Authorization: `Bearer ${gateway.externalKey}` },
		} );
		const remove = differenceWith( data.modifiers.elements as any[], modifiers, ( { id },
			{ externalId } ) => id === externalId );
		
		if ( !isEmpty( remove ) ) {
			for ( const modifier of remove ) {
				await axios.delete( `${PLATFORM_ENDPOINT}/${gateway.externalId}/modifier_groups${data.id}/modifiers/${modifier.id}`,
					{ headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
			}
		}
		for ( const modifier of modifiers ) {
			if ( modifier.isPercent ) continue;
			await axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/modifier_groups/${data.id}/modifiers${modifier.externalId
				? `/${modifier.externalId}`
				: ''}`, {
				name         : modifier.name,
				price        : modifier.value * 100,
				available    : true,
				modifierGroup: { id: data.id },
			}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		}
		
		return data;
	},
	async deleteModifierGroup( gateway, { externalId } ) {
		const { data } = await axios.delete( `${PLATFORM_ENDPOINT}/${gateway.externalId}/modifier_groups/${externalId}`,
			{ headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
		return data;
	},
	async convertModifierGroup( gateway, location, data ) {
		return {
			externalId: data.id,
			gateway   : gateway.id,
			name      : data.name,
			min       : isNaN( data.minRequired ) ? 0 : data.minRequired,
			max       : isNaN( data.maxAllowed ) ? 0 : data.maxAllowed,
			modifiers : data.modifiers.elements.map( ( { id, name, price } ) => ( {
				externalId: id,
				name,
				value     : price / 100,
			} ) as ModifierValidator ),
		} as ModifierGroupValidator;
	},
	
	async updateInventory(
		items,
		hasStock,
		cb?: ( { progress, itemId }: { progress: number, itemId: string } ) => Promise<void> ) {
		const itemsList = pipe( items,
			toPairs,
			filter( ( [ id, quantity ] ) => {
				if ( id?.length !== 13 ) {
					console.log( 'Invalid item externalId:', id );
					return false;
				}
				return quantity !== 0;
			} ),
		);
		
		if ( isEmpty( itemsList ) ) return;
		let completed = 0;
		
		const updateStock = async ( id, quantity, hasStock ) => {
			await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/clover/updateItemStock`, {
				itemId: id,
				diff  : hasStock ? quantity : -quantity,
			} );
			completed++;
			await cb?.( { progress: completed / itemsList.length * 100, itemId: id } );
		};
		
		await Bluebird.map(
			itemsList,
			( [ id, quantity ] ) => updateStock( id, quantity, hasStock ), { concurrency: 2 },
		);
	},
	
	async postMeteredBilling( gateway, count = 1 ) {
		return postMeteredBilling( { gateway, count } );
	},
	
	async getTips( gateway ) {
		const { data } = await axios.get( `${PLATFORM_ENDPOINT}/${gateway.externalId}/tip_suggestions`, {
			params : {
				limit: 20,
			},
			headers: { Authorization: `Bearer ${gateway.externalKey}` },
		} );
		return { data: data.elements };
	},
};
export default cloverManageProcessor;
