import TextFieldInputLabel from '@/components/form/inputLabel';
import { useModal } from '@/providers/modal';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import csv from 'csvtojson';
import { fromPairs, mapValues } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { Fragment, useEffect, useMemo, useState } from 'react';
import fileToText from '../../helpers/fileToText';
import EnhancedTable from '../enhancedDisplay/enhancedTable';
import type { AttachmentInputProps } from './attachmentInput';
import AttachmentInput from './attachmentInput';

function ImportModal( {
	headers,
	headerValues,
	tempData,
	initialMappedHeaders,
	onSave,
	remainingData,
}: {
	headers: string[],
	headerValues: string[],
	tempData: Record<string, string>[],
	initialMappedHeaders: string[],
	onSave: ( mappedHeaders: string[] ) => void,
	remainingData?: number
} ) {
	const [ mappedHeaders, setMappedHeaders ] = useState<string[]>( initialMappedHeaders );
	
	return (
		<ResponsiveModalContainer
			title='Upload'
			onSave={() => onSave( mappedHeaders )}>
			<EnhancedTable
				data={tempData}
				extraData={mappedHeaders}
				title='Preview'
				size='medium'
				sx={{ '.MuiTableCell-head': { minWidth: 160 } }}
				headers={headers.map( ( header, index ) => (
					<Fragment key={index}>
						<TextFieldInputLabel label={header}/>
						<FormControl fullWidth>
							<Select
								value={mappedHeaders[ index ] ?? ''}
								onChange={( e ) => {
									const newHeaders = [ ...mappedHeaders ];
									newHeaders[ index ] = e.target.value;
									setMappedHeaders( newHeaders );
								}}>
								<MenuItem value=''>None</MenuItem>
								{Object.keys( tempData[ 0 ] ).map( ( header ) => (
									<MenuItem
										key={header}
										value={header}>
										{header}
									</MenuItem>
								) )}
							</Select>
						</FormControl>
					</Fragment>
				) )}
				columns={( item ) => headerValues.map( ( header, index ) => (
					<Typography key={index}>
						{item[ mappedHeaders[ index ] ]}
					</Typography>
				) )}
			/>
			{typeof remainingData !== 'undefined' && remainingData > 0 && (
				<Typography textAlign='right' sx={{ mt: 1 }}>
					{`& ${remainingData} more items...`}
				</Typography>
			)}
		</ResponsiveModalContainer>
	);
}

export default function CSVImportModal( {
	headers,
	headerValues,
	setData,
	type,
	...props
}: {
	headers: string[],
	headerValues: string[],
	setData?: ( data: any[] ) => void,
	type?: string
} & Omit<AttachmentInputProps, 'setFiles'> ) {
	const { enqueueSnackbar } = useSnackbar();
	const { closeModal, showModal } = useModal();
	const [ importData, setImportData ] = useState<any[]>( [] );
	const [ mappedHeaders, setMappedHeaders ] = useState<string[]>( [] );
	const tempData = useMemo( () => importData.slice( 0, 5 ), [ importData ] );
	
	const hasImportedData = Boolean( importData.length );
	const remainingData = importData.length - tempData.length;
	const handleOnSave = ( mappedHeaders: string[] ) => {
		setData?.( importData.map( ( line ) => mapValues(
			fromPairs( headerValues.map( ( header, index ) => [ header, mappedHeaders[ index ] ] ) ),
			( value ) => line[ value ],
		) ) );
	};
	
	useEffect( () => {
		if ( hasImportedData ) {
			closeModal();
			showModal( ImportModal, { maxWidth: 'xl', fullPageModal: true }, {
				headers,
				headerValues,
				tempData,
				initialMappedHeaders: mappedHeaders,
				onSave              : handleOnSave,
				remainingData,
			} );
		}
	}, [ importData ] );
	
	return (
		<ResponsiveModalContainer
			hideClose
			title={`Upload ${type ? `${type}s` : ''}`}
			onSave={hasImportedData ? () => handleOnSave( mappedHeaders ) : undefined}>
			<Box
				sx={{
					'.MuiTableContainer-root'               : { bgcolor: 'background.paper', pb: 2 },
					'.MuiTableCell-head'                    : { px: 0.5, pt: 2, minWidth: 150 },
					'.MuiSelect-select'                     : { fontSize: 13 },
					'.MuiTableCell-root .MuiTypography-root': { fontSize: 13 },
				}}>
				{!hasImportedData && (
					<AttachmentInput
						disableUpload
						setFiles={async ( files ) => {
							if ( !files.length ) {
								setImportData( [] );
								setMappedHeaders( [] );
								return;
							}
							try {
								const text = await fileToText( files[ 0 ] );
								const data = await csv().fromString( text );
								const newHeaders = Object.keys( data[ 0 ] );
								setMappedHeaders( headers.map( ( header ) => {
									const headerRegex = new RegExp( header, 'i' );
									return newHeaders.find( ( newHeader ) => Boolean( newHeader.match( headerRegex ) ) ) || '';
								} ) );
								setImportData( data );
							} catch ( e ) {
								enqueueSnackbar( e?.response?.data || e?.message || e, { variant: 'error' } );
							}
						}}
						accept={{ 'text/*': [ '.csv' ] }}
						multiple={false}
						{...props}
					/>
				)}
			</Box>
		</ResponsiveModalContainer>
	);
}
