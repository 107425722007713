import GooglePlacesSearch from '@/components/form/fields/googlePlacesSearch';
import FormTextField from '@/components/form/fields/textField';
import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';

const countryCodes = {
	'United States': 'US',
	'Canada'       : 'CA',
	// More countries as needed
};

export default function AddressFields( { path = '' } ) {
	const formik = useFormikContext<any>();
	
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<GooglePlacesSearch
					name={`${path}.address1`}
					textFieldProps={{
						label       : 'Address',
						autoComplete: 'street-address',
					}}
					onSelect={async ( address: any ) => {
						await formik.setFieldValue( `${path}.address1`, address.line1 );
						await formik.setFieldValue( `${path}.city`, address.city );
						await formik.setFieldValue( `${path}.stateCd`, address.state );
						await formik.setFieldValue( `${path}.countryCd`, countryCodes[ address.country ] || address.country );
						await formik.setFieldValue( `${path}.zip`, address.postalCode );
					}}
				/>
			</Grid>
			<Grid item xs={12}>
				<FormTextField
					fullWidth
					name={`${path}.city`}
					label='City'
					autoComplete='address-level2'
				/>
			</Grid>
			<Grid item xs={12}>
				<FormTextField
					fullWidth
					name={`${path}.stateCd`}
					label='State'
					autoComplete='address-level1'
				/>
			</Grid>
			<Grid item xs={12}>
				<FormTextField
					fullWidth
					name={`${path}.countryCd`}
					label='Country'
					autoComplete='country'
				/>
			</Grid>
			<Grid item xs={12}>
				<FormTextField
					fullWidth
					name={`${path}.zip`}
					label='Zip/Postal Code'
					autoComplete='postal-code'
				/>
			</Grid>
		</Grid>
	);
}
