import Loading from '@/components/loading';
import { useGraphqlResult } from '@/data/query/graphqlProvider';
import { EmbedUrlResponse } from '@/dto/vopay';
import { getPaymentNote } from '@/pages/dashboard/commerce/payment/details';
import { makePayment } from '@/pages/dashboard/commerce/payment/helpers';
import { ClientInfo } from '@/pages/p/commerce/clientInfoForm';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModalControls } from '@/providers/modal';
import { useThemeMode } from '@/providers/theme';
import { ClientCredit, GatewayBase, Order } from '@/types/schema';
import postCloverMeteredBilling from '@/utils/api/postCloverMeteredBilling';
import { PaletteMode, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { round } from 'lodash-es';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

type Props = {
	amount: number,
	cardFee?,
	tip?,
	dollarTip?,
	method,
	cancel,
	confirm: ( paymentObject: any ) => Promise<void>,
	locationPayment?: boolean,
	hideSignature?: boolean,
	client?: ClientInfo,
	storeOrder?: boolean,
	order?: Order,
	prepayClientId?: string,
	cardToken?: string,
	cardType?: string,
	paymentGateway: GatewayBase,
	selectedCredits?: ClientCredit[],
	invoiceNumber?: string,
	invoiceId?: string
};

function VopayAch( {
	amount,
	tip,
	dollarTip,
	method,
	cancel,
	confirm,
	locationPayment,
	hideSignature,
	client,
	storeOrder,
	order,
	prepayClientId,
	cardToken,
	cardType,
	paymentGateway,
	selectedCredits,
	invoiceNumber,
	invoiceId,
}: Props ) {
	const {
		      id,
		      company,
		      client: orderClient,
		      paidTotal,
		      metadata,
		      companyLocation,
		      staff: orderStaff,
	      } = useGraphqlResult<Order>();
	const router = useRouter();
	const mode: PaletteMode = useThemeMode();
	const route = router.route.split( '/' )[ 1 ];
	const { staff } = useUserInfo();
	const { enqueueSnackbar } = useSnackbar();
	const { closeModal } = useModalControls();
	const [ iframeState, setIframeState ] = useState<{
		loading: boolean,
		error: string | null
	}>( { loading: false, error: null } );
	const { data, error, isLoading } = useQuery<EmbedUrlResponse>( {
		queryKey: [ 'vopay-iframe' ],
		queryFn : async () => {
			const response = await axios.post( '/api/user/vopay/getEmbedUrl', {
				gatewayId: paymentGateway.id,
				darkMode : mode === 'dark',
			} );
			return response.data;
		},
	} );
	console.log( 'Vopay iframe url data', data );
	
	const isClientPage = route === 'p' || route === 'client';
	const cashDiscount = paidTotal === 0 && metadata?.enableCashDiscount && round( company.metadata?.cashDiscount || 0, 2 ) / 100;
	
	const handleMessage = async ( event: MessageEvent ) => {
		if ( event.data.type !== 'PROCESS_COMPLETE' ) return;
		try {
			setIframeState( { loading: true, error: null } );
			const params = event.data.params as {
				Token: string,
				PaymentMethod: 'bank'
			};
			
			let cloverOrder;
			if ( cashDiscount > 0 ) {
				const { data: commerce } = await axios.post( '/api/tempCashDiscount', {
					id: id,
					storeOrder,
				} );
				if ( commerce.commerce && commerce?.data ) {
					cloverOrder = commerce.commerce;
				}
			}
			
			const paymentData = await makePayment( {
				type  : method.toUpperCase(),
				amount: amount,
				fee   : 0,
				tip   : dollarTip || amount * tip / 100,
				note  : getPaymentNote( {
					invoiceNote: prepayClientId ? 'PrePaid' : invoiceNumber || '',
					method,
					locationPayment,
				} ),
				signature      : null,
				orderId        : invoiceId || cloverOrder?.id || id,
				gatewayId      : paymentGateway?.id,
				companyId      : company.id,
				metadata       : company?.metadata,
				staffExternalId: staff?.externalId || orderStaff?.externalId || null,
				staffId        : staff?.id,
				payerId        : staff?.id || orderClient?.id,
				payerName      : orderClient?.name || orderClient?.email || orderClient?.contact || ( staff?.user?.firstName || '' + staff?.user?.lastName ),
				cardToken      : params.Token,
				isClientPage   : isClientPage,
				invoiceNumber  : invoiceNumber,
			} );
			
			if ( paymentData ) {
				if ( companyLocation?.gateway?.external === 'CLOVER' && method === 'ach' ) {
					await postCloverMeteredBilling( {
						orderId  : id,
						gatewayId: companyLocation.gateway?.id,
						eventType: 'Bank Account',
						key      : 'bankAccount',
						staffId  : staff?.id,
						clientId : orderClient?.id,
					} ).catch( () => [] );
				}
				
				await confirm( {
					payment    : paymentData?.payment,
					method,
					credits    : selectedCredits,
					syncedOrder: cloverOrder || order,
				} );
			}
			closeModal();
		} catch ( err ) {
			enqueueSnackbar( 'Payment Failed', { variant: 'error' } );
			closeModal();
		}
	};
	
	useEffect( () => {
		window.addEventListener( 'message', handleMessage );
		return () => {
			window.removeEventListener( 'message', handleMessage );
		};
	}, [] );
	
	if ( error ) return <Typography>Error loading Vopay iframe</Typography>;
	if ( isLoading || !data || iframeState.loading ) return <Loading/>;
	return <iframe src={data.EmbedURL} style={{ width: '100%', height: '800px', border: 'none' }}/>;
}

export default function VopayPaymentDetails( props: Props ) {
	if ( props.method === 'ach' ) return <VopayAch {...props}/>;
	return null;
}
