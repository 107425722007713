import { mutateGraphQL, queryGraphQL } from '@/data/apollo';
import { InvoiceSyncLineItemsRead } from '@/pages/dashboard/commerce/invoices/invoiceGQL';
import { getUserInfo } from '@/providers/auth/useUserInfo';
import { MutationCommercesWriteArgs, Order, QueryLineItemsReadArgs } from '@/types/schema';
import { gql } from '@apollo/client';
import axios from 'axios';
import { isEmpty } from 'lodash-es';

export default async function SyncToClover( data: Order, sendNote?: boolean ) {
	const { staff } = getUserInfo();
	if ( data.companyLocation?.gateway?.external && data.companyLocation?.gateway?.external !== 'CLOVER' && !data.externalId ) return data;
	
	const lineItemsWithCloverTaxOrModifiers = data?.lineItems?.filter( ( lineItem ) =>
		lineItem?.prices?.some( ( price ) => price?.metadata?.useTax )
		|| lineItem?.metadata && Object.keys( lineItem?.metadata )
			.some( ( key ) => key?.length === 13 && typeof lineItem?.metadata[ key ] === 'number' ),
	);
	
	const lineItemsWithItemGateway = lineItemsWithCloverTaxOrModifiers?.filter( ( lineItem ) => lineItem?.item?.gateway?.id );
	
	if ( !isEmpty( lineItemsWithItemGateway ) ) {
		const lineItemWithDifferentLocation = lineItemsWithItemGateway?.find( ( lineItem ) => lineItem.item?.gateway?.id !== data.companyLocation?.gateway?.id );
		if ( lineItemWithDifferentLocation ) throw `The item "${lineItemWithDifferentLocation?.name || lineItemWithDifferentLocation?.item?.name || ''}" is from a different location`;
	}
	const { data: cloverCommerce } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/postOrder`, {
		id     : data.id,
		sendNote,
		staffId: staff?.id,
	} );
	return cloverCommerce;
	
}

export const syncToCloverAfterValidation = async ( invoicesToSync: Order[],
	hideOnClover?: boolean ) => {
	if ( isEmpty( invoicesToSync ) ) return;
	const invoices = invoicesToSync.filter( ( invoice ) => !( !invoice.externalId && invoice.paidTotal > 0 ) );
	const { staff } = getUserInfo();
	
	if ( hideOnClover !== undefined ) {
		await mutateGraphQL<MutationCommercesWriteArgs>(
			{
				mutation: gql`mutation CommercesWrite_d33c($ids: [String!], $inputs: [OrderValidator!], $options: FilterOptions, $remove: Boolean) {
					commercesWrite(ids: $ids, inputs: $inputs, options: $options, remove: $remove)
				}`,
				variables: {
					method: 'Synced and Hid On Clover',
					inputs: invoices.map( ( commerce ) => ( {
						id      : commerce.id,
						metadata: { hideOnClover },
					} ) ),
				},
			},
		);
	}
	
	let differentLocationItemCount = 0;
	
	for ( const invoice of invoices ) {
		
		const { lineItemsRead } = await queryGraphQL<QueryLineItemsReadArgs>( {
			query    : InvoiceSyncLineItemsRead,
			variables: { options: { limit: 1000, filter: { order: invoice.id } } },
		} );
		
		if ( isEmpty( lineItemsRead?.items ) ) {
			continue;
		}
		
		const lineItemsWithCloverModifiers = lineItemsRead?.items?.filter( ( lineItem ) => Object.keys( lineItem?.metadata )
			.some( ( key ) => key?.length === 13 && typeof lineItem?.metadata[ key ] === 'number' ) );
		
		const lineItemsWithItemGateway = lineItemsWithCloverModifiers?.filter( ( lineItem ) => lineItem.item?.externalId && lineItem?.item?.gateway?.id );
		if ( !isEmpty( lineItemsWithItemGateway ) ) {
			const lineItemWithDifferentLocation = lineItemsWithItemGateway?.find( ( lineItem ) => lineItem.item?.gateway?.id !== invoice.companyLocation?.gateway?.id );
			if ( lineItemWithDifferentLocation ) {
				differentLocationItemCount++;
				continue;
			}
		}
		
		const lineItemDollarFee = lineItemsRead.items.map( ( lineItem ) => lineItem.prices?.find( ( price ) => price.value > 0 && !price.metadata?.externalTax ) );
		if ( !isEmpty( lineItemDollarFee.filter( Boolean ) ) ) {
			continue;
		}
		
		const orderDollarFee = invoice.prices?.find( ( price ) => !price.isPercent && price.value > 0 && !price.metadata?.cloverTaxPercent );
		if ( !isEmpty( orderDollarFee ) ) {
			continue;
		}
		
		const lineItemWithMoreDescription = lineItemsRead.items.find( ( lineItem ) => lineItem.description?.length > 255 );
		if ( lineItemWithMoreDescription ) {
			continue;
		}
		
		await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/postOrder`, {
			id     : invoice.id,
			staffId: staff?.id,
		} );
	}
	
	if ( differentLocationItemCount ) throw `There are ${differentLocationItemCount} items from a different location`;
	
};
