import FormGraphQLAutocomplete from '@/components/form/fields/formGraphQLAutocomplete';
import { FormGraphQLAutoCompleteProps } from '@/components/form/fields/types';
import { ModalFormWrapper } from '@/components/form/modal';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import { useModal } from '@/providers/modal';
import type { Category, CategoryType, QueryCategoriesReadArgs } from '@/types/schema';
import { MenuItem } from '@mui/material';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import CategoryForm from '../dashboard/management/categories/form';
import { CategoriesSelectRead } from './selectsGQL';

export default function CategorySelect( props: Omit<FormGraphQLAutoCompleteProps<Category, QueryCategoriesReadArgs>, 'query' | 'queryKey'> & {
	type: CategoryType
} ) {
	const creatable = usePermissions( permissions.categories.write );
	const { showModal } = useModal();
	const [ , { value = [] }, { setValue } ] = useField( props.name );
	const { t } = useTranslation();
	
	const label = 'label' in props && props.label === undefined ? undefined : props.label || t( 'common:category' );
	
	return (
		<FormGraphQLAutocomplete<Category>
			queryKey={[ 'category' ]}
			label={t( 'common:category' )}
			query={CategoriesSelectRead}
			variables={{
				options: {
					filter: { type: props.type || undefined },
					limit : 50,
				},
			}}
			getOptionLabel={( { name }: Category ) => name || ''}
			renderOption={( props, option ) => {
				const { key, ...otherProps } = props;
				return (
					<MenuItem key={key ?? ''} {...otherProps}>
						{option.name || ''}
					</MenuItem>
				);
			}}
			onAdd={creatable ? () => showModal( CategoryForm, { maxWidth: 'sm' }, {
				type    : props.type,
				onSubmit: ( category ) => setValue( props.multiple ? [ ...value, category ] : category ),
				Wrapper : ModalFormWrapper,
			} ) : undefined}
			{...props}
			textFieldProps={{ label: label, ...props.textFieldProps }}
		/>
	);
}
