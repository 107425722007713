import Attachment from '@/components/fileUploading/attachment';
import Form from '@/components/form';
import FormPhoneField from '@/components/form/fields/phoneField';
import FormSwitch from '@/components/form/fields/switch';
import FormTextField from '@/components/form/fields/textField';
import { ModalFormWrapper } from '@/components/form/modal';
import PageLinkComponent from '@/components/page/linkComponent';
import { mutateGraphQL } from '@/data/apollo';
import uploadFile from '@/data/uploadFile';
import { DriverSignAndTag_Dst123Mutation, DriverSignAndTag_Dst123MutationVariables } from '@/generated/graphql';
import { CompanyTagsType } from '@/modals/companyTags';
import { companyOrderTagsColors } from '@/pages/dashboard/commerce/chips/deliveryStatusChip';
import usePermissions from '@/providers/auth/usePermissions';
import { theme } from '@/providers/theme';
import { Order } from '@/types/schema';
import { gql } from '@apollo/client';
import { CheckCircle as CheckCircleIcon, SettingsSuggest as SettingsSuggestIcon } from '@mui/icons-material';
import { Box, Button, ListItemButton, ListItemText, Stack, Typography, alpha } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { isEmpty, startCase, toLower } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SignaturePad from 'react-signature-pad-wrapper';

export default function SignAndTagModal( { data }: { data: Order } ) {
	const signRef = useRef<SignaturePad>( null );
	const queryClient = useQueryClient();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const isOwner = usePermissions( [ 'OWNER' ] );
	const isAdmin = usePermissions( [ 'ADMIN' ] );
	const colors = companyOrderTagsColors( theme.palette.mode === 'dark' );
	const orderTags: CompanyTagsType[] = data.company?.tags?.orderTags?.filter( ( tag ) => tag?.forOrder );
	
	return (
		<Form
			initialValues={{
				status      : data.deliveryStatus || null,
				hasSignature: Boolean( data.metadata?.signature ) || false,
				sendText    : false,
				note        : data.deliveryStatusNote || '',
				clientCell  : data.client?.cell || '',
			}}
			onSubmit={async ( values ) => {
				let signature = data.metadata?.signature;
				if ( signRef.current?.isEmpty() === false ) {
					const blob = await new Promise<Blob>( ( resolve, reject ) => {
						signRef?.current?.canvas?.current?.toBlob( ( blob ) => {
							if ( blob ) {
								resolve( blob );
							} else {
								reject( new Error( 'Failed to create blob from signature' ) );
							}
						} );
					} );
					signature = await uploadFile( blob );
				}
				if ( signRef.current?.isEmpty() ) signature = null;
				
				await mutateGraphQL<DriverSignAndTag_Dst123MutationVariables, DriverSignAndTag_Dst123Mutation>( {
					mutation: gql`
						mutation DriverSignAndTag_DST123($id: String!, $method: String!, $input: DriverSignAndTagValidator) {
							driverSignAndTag(id: $id, method: $method, input: $input) 
						}`,
					variables: {
						id    : data.id,
						method: 'Signed and Tagged',
						input : {
							deliveryStatus    : values.status!,
							deliveryStatusNote: values.note,
							metadata          : { ...data.metadata, signature },
						},
					},
				} );
				
				await queryClient.invalidateQueries( [ 'order' ] );
				await queryClient.invalidateQueries( [ 'driverOrders' ] );
				
				if ( values.sendText ) {
					await axios.post( '/api/message', {
						company: data.company.id,
						number : values.clientCell,
						message: `Hi ${data.client?.name || data.client?.contact || ''}\n`
							+ `Your ${toLower( data.type )} ${data.metadata?.customNumber || data.number || data.externalId} is ${values.status}. ${values.note}`,
					} );
					
					enqueueSnackbar( t( 'common:text-sent' ), { variant: 'success' } );
				}
				
			}}>
			{( formik ) => (
				<ModalFormWrapper
					title={`${startCase( toLower( data.type ) )}# ${data.metadata?.customNumber || data.number}`}
					secondaryTitle='Tag and Sign'>
					<Stack spacing={2}>
						{( isOwner || isAdmin ) && (
							<Button
								startIcon={<SettingsSuggestIcon/>}
								component={PageLinkComponent}
								href='/dashboard/settings/doc?tab=general'
								sx={{ alignSelf: 'end' }}
								target='_blank'>
								{t( 'settings:create-or-edit-tags' )}
							</Button>
						)}
						{!isEmpty( orderTags ) ? orderTags.map( ( tag, index ) => (
							<ListItemButton
								key={index}
								sx={{
									'bgcolor': tag.name === formik.values.status
										? colors[ tag.color ].bgcolor
										: alpha( colors[ tag.color ].bgcolor, .3 ),
									'opacity'    : tag.name !== formik.values.status ? '.6' : '1',
									'transition' : '.3s',
									'border'     : 2,
									'borderColor': tag.name === formik.values.status
										? colors[ tag.color ].textColor
										: 'transparent',
									'borderRadius': 2,
									':hover'      : {
										bgcolor    : colors[ tag.color ].bgcolor,
										borderColor: colors[ tag.color ].textColor,
										filter     : 'brightness(110%)',
									},
								}}
								onClick={() => formik.setFieldValue(
									'status',
									tag.name === formik.values.status ? null : tag.name,
								)}>
								<ListItemText
									primary={tag.name}
									primaryTypographyProps={{
										fontWeight: 'bold',
										variant   : 'h4',
										color     : colors[ tag.color ].textColor,
									}}
									secondary={tag?.description}
									secondaryTypographyProps={{
										color: colors[ tag.color ].textColor + ' !important',
									}}
								/>
								{tag.name === formik.values.status && <CheckCircleIcon color='primary'/>}
							</ListItemButton>
						) ) : null}
						<FormTextField
							fullWidth
							multiline
							name='note'
							label={t( 'common:note' )}
							placeholder='tracking number, or thank you note'
							helperText='Your client will only be able to see this note if you send it to them via text.'
							minRows={2}
							maxRows={10}
						/>
						<Stack direction='row' alignItems='end' spacing={1}>
							<FormPhoneField
								label='Send a text notification to the client'
								name='clientCell'
								disabled={!formik.values.sendText}
							/>
							<FormSwitch name='sendText'/>
						</Stack>
						{formik.values.hasSignature ? (
							<Attachment
								removeDownload
								src={data.metadata.signature}
								imageSX={{ width: '100%', height: 'unset', objectFit: 'cover', bgcolor: '#ffffff' }}
							/>
						) : (
							<Box sx={{ p: 2, borderRadius: 2, bgcolor: '#ffffff', width: '100%', height: 200 }}>
								<Typography sx={{ textAlign: 'center', color: '#000000' }}>
									{t( 'common:sign-here' )}
								</Typography>
								<SignaturePad ref={signRef} options={{ penColor: '#000000' }}/>
							</Box>
						)}
						<Button
							variant='outlined'
							onClick={() => {
								if ( formik.values.hasSignature ) {
									formik.setFieldValue( 'hasSignature', false );
									signRef.current?.clear();

								} else {
									signRef.current?.clear();
								}
							}}>
							{formik.values.hasSignature ? t( 'common:redo-signature' ) : t( 'common:clear-signature' )}
						</Button>
						{formik.values.hasSignature && (
							<Button
								variant='text'
								color='error'
								onClick={() => {
									formik.setFieldValue( 'hasSignature', false );
									signRef.current?.clear();
								}}>
								{t( 'common:delete-signature' )}
							</Button>
						)}
					</Stack>
				</ModalFormWrapper>
			)}
		</Form>
	);
}
