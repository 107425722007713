import Loading from '@/components/loading';
import type { Permissions } from '@/types/schema';
import { intersection } from 'lodash-es';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import ErrorPage from '../../pages/_error.page';
import useUserInfo from './useUserInfo';

export type AllPermissions = 'USER' | 'ACCOUNT' | Permissions;

export function CheckPermissions( { permissions, redirect, children }: {
	permissions: AllPermissions[],
	redirect?: string | boolean,
	children: JSX.Element
} ) {
	const valid = usePermissions( permissions, redirect );
	
	if ( !valid ) return redirect
		? <Loading/>
		: (
			<ErrorPage
				title='Permission Denied'
				subtitle='You do not have permission to access this page'
			/>
		);
	
	return children;
}

export default function usePermissions( permissions: AllPermissions[], redirect?: string | boolean ) {
	const { user, staff } = useUserInfo();
	const router = useRouter();
	
	return useMemo( () => {
		const userPermissions: AllPermissions[] = [ ...staff?.permissions || [] ] as any;
		if ( user ) userPermissions.push( 'USER' );
		if ( staff ) userPermissions.push( 'ACCOUNT' );
		const valid = Boolean( intersection( userPermissions, permissions ).length );
		if ( !valid ) {
			if ( typeof redirect === 'boolean' ) {
				router.back();
			} else if ( typeof redirect === 'string' ) {
				router.push( redirect ).then();
			}
		}
		return valid;
	}, [ user, staff ] );
}

export const permissions: Record<string, Record<'read' | 'write', AllPermissions[]>> = {
	estimates: {
		read: [
			'ESTIMATE_READ',
			'ESTIMATE_WRITE',
			'ADMIN',
			'OWNER',
		],
		write: [
			'ESTIMATE_WRITE',
			'ADMIN',
			'OWNER',
		],
	},
	invoices: {
		read: [
			'INVOICE_READ',
			'INVOICE_WRITE',
			'ADMIN',
			'OWNER',
		],
		write: [
			'INVOICE_WRITE',
			'ADMIN',
			'OWNER',
		],
	},
	orders: {
		read: [
			'ORDER_READ',
			'ORDER_WRITE',
			'ADMIN',
			'OWNER',
		],
		write: [
			'ORDER_WRITE',
			'ADMIN',
			'OWNER',
		],
	},
	purchases: {
		read: [
			'PURCHASE_READ',
			'PURCHASE_WRITE',
			'ADMIN',
			'OWNER',
		],
		write: [
			'PURCHASE_WRITE',
			'ADMIN',
			'OWNER',
		],
	},
	items: {
		read: [
			'MENU_READ',
			'MENU_WRITE',
			'ITEM_READ',
			'ITEM_WRITE',
			'ADMIN',
			'OWNER',
		],
		write: [
			'ITEM_WRITE',
			'ADMIN',
			'OWNER',
		],
	},
	clients: {
		read: [
			'CLIENT_READ',
			'CLIENT_WRITE',
			'ADMIN',
			'OWNER',
		],
		write: [
			'CLIENT_WRITE',
			'ADMIN',
			'OWNER',
		],
	},
	agreements: {
		read: [
			'AGREEMENT_READ',
			'AGREEMENT_WRITE',
			'INVOICE_WRITE',
			'INVOICE_READ',
			'ORDER_READ',
			'ORDER_WRITE',
			'ESTIMATE_READ',
			'ESTIMATE_WRITE',
			'ADMIN',
			'OWNER',
		],
		write: [
			'AGREEMENT_WRITE',
			'INVOICE_WRITE',
			'ORDER_WRITE',
			'ESTIMATE_WRITE',
			'ADMIN',
			'OWNER',
		],
	},
	houseAccounts: {
		read: [
			'HOUSE_ACCOUNT_READ',
			'HOUSE_ACCOUNT_WRITE',
			'ADMIN',
			'OWNER',
		],
		write: [
			'HOUSE_ACCOUNT_WRITE',
			'ADMIN',
			'OWNER',
		],
	},
	menus: {
		read: [
			'MENU_READ',
			'MENU_WRITE',
			'ITEM_READ',
			'ITEM_WRITE',
			'ADMIN',
			'OWNER',
		],
		write: [
			'PURCHASE_WRITE',
			'MENU_WRITE',
			'ADMIN',
			'OWNER',
		],
	},
	vendors: {
		read: [
			'MENU_READ',
			'MENU_WRITE',
			'ITEM_READ',
			'ITEM_WRITE',
			'ADMIN',
			'OWNER',
		],
		write: [
			'PURCHASE_WRITE',
			'MENU_WRITE',
			'ADMIN',
			'OWNER',
		],
	},
	inventories: {
		read: [
			'INVENTORY',
			'ADMIN',
			'OWNER',
		],
		write: [
			'INVENTORY',
			'ADMIN',
			'OWNER',
		],
	},
	categories: {
		read: [
			'ITEM_READ',
			'ITEM_WRITE',
			'CATEGORY_READ',
			'CATEGORY_WRITE',
			'ADMIN',
			'OWNER',
		],
		write: [
			'CATEGORY_WRITE',
			'ADMIN',
			'OWNER',
			'ITEM_WRITE',
		],
	},
	locations: {
		read: [
			'OWNER',
			'ADMIN',
		],
		write: [
			'OWNER',
			'ADMIN',
		],
	},
	teams: {
		read: [
			'OWNER',
			'ADMIN',
			'CAMPAIGN_READ',
		],
		write: [
			'OWNER',
		],
	},
	campaigns: {
		read: [
			'OWNER',
			'ADMIN',
			'CAMPAIGN_READ',
		],
		write: [
			'OWNER',
			'ADMIN',
			'CAMPAIGN_WRITE',
		],
	},
	transactions: {
		read: [
			'OWNER',
			'ADMIN',
		],
		write: [
			'OWNER',
			'ADMIN',
		],
	},
	payments: {
		read: [
			'ADMIN',
			'OWNER',
			'PAYMENT_READ',
			'PAYMENT_WRITE',
		],
		write: [
			'ADMIN',
			'OWNER',
			'PAYMENT_WRITE',
		],
	},
	uoms: {
		read : [],
		write: [
			'ADMIN',
			'OWNER',
			'ITEM_WRITE',
		],
	},
	reports: {
		read: [
			'OWNER',
			'ADMIN',
			'REPORTS',
		],
		write: [
			'OWNER',
			'ADMIN',
			'REPORTS',
		],
	},
	stores: {
		read: [
			'STORES',
			'STORE_READ',
			'STORE_WRITE',
			'STORES',
			'ADMIN',
			'OWNER',
		],
		write: [
			'STORE_WRITE',
			'ADMIN',
			'OWNER',
			'STORES',
		],
	},
	modifierGroups: {
		read: [
			'MODIFIER_GROUP_READ',
			'MODIFIER_GROUP_WRITE',
			'ADMIN',
			'OWNER',
			'CATEGORY_READ',
			'ITEM_READ',
			'STORE_READ',
		],
		write: [
			'MODIFIER_GROUP_WRITE',
			'ADMIN',
			'OWNER',
			'ORDER_WRITE',
			'INVOICE_WRITE',
			'ESTIMATE_WRITE',
			'ITEM_WRITE',
			'CATEGORY_WRITE',
			'STORE_WRITE',
		],
	},
	logs: {
		read: [
			'ADMIN',
			'OWNER',
		],
		write: [
			'ADMIN',
			'OWNER',
		],
	},
	chat: {
		read: [
			'CHAT',
			'ADMIN',
			'OWNER',
		],
		write: [
			'CHAT',
			'ADMIN',
			'OWNER',
		],
	},
};
