import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { startCase, toLower } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import GooglePlacesSearch from './googlePlacesSearch';
import FormPhoneField from './phoneField';
import FormTextField from './textField';

export function useAddressValidationSchema( addressFieldName?: string ) {
	const { t } = useTranslation();
	
	const addressSchema = yup.object().shape( {
		line1     : yup
			.string()
			.required( t( 'common:enter-address' ) )
			.max( 250, t( 'common:house-accounts-address-long' ) ),
		line2     : yup
			.string()
			.nullable()
			.max( 64, t( 'common:house-accounts-floor-long' ) ),
		city      : yup
			.string()
			.required( t( 'common:enter-city' ) )
			.max( 64, t( 'common:house-accounts-city-long' ) ),
		state     : yup
			.string()
			.required( t( 'common:enter-state' ) )
			.max( 64, t( 'common:house-accounts-state-long' ) ),
		country   : yup
			.string()
			.required( t( 'common:enter-country' ) )
			.max( 64, t( 'common:house-accounts-country-long' ) ),
		postalCode: yup
			.string()
			.required( t( 'common:enter-postal' ) )
			.max( 64, t( 'common:house-accounts-postal-long' ) ),
	} );
	
	if ( addressFieldName ) {
		return yup.object().shape( {
			[ addressFieldName ]: addressSchema,
		} );
	} else {
		return yup.object().shape( addressSchema.fields );
	}
}

interface FormAddressProps {
	name?: string,
	fieldSize?: 'small' | 'medium',
	gridBreakpoints?: any,
	showNamePhoneAndEmail?: boolean,
	showFriendlyName?: boolean
}

export default function FormAddress( {
	name,
	fieldSize,
	gridBreakpoints = {},
	showNamePhoneAndEmail,
	showFriendlyName,
}: FormAddressProps ) {
	const formik = useFormikContext<any>();
	const name2 = name ? `${name}.` : '';
	
	const { t } = useTranslation();
	
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={8} {...gridBreakpoints.line1}>
				<GooglePlacesSearch
					name={`${name2}line1`}
					textFieldProps={{
						label       : t( 'common:address' ),
						autoComplete: 'address-line1',
						size        : fieldSize,
					}}
					onSelect={( address: any ) => {
						if ( name ) formik.setFieldValue( name, address );
						else formik.setValues( { ...formik.values, ...address } );
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={4} {...gridBreakpoints.line2}>
				<FormTextField
					fullWidth
					name={`${name2}line2`}
					label={t( 'common:suite-floor-no' )}
					autoComplete='address-line2'
					size={fieldSize}
				/>
			</Grid>
			<Grid item xs={12} sm={6} lg={4} {...gridBreakpoints.city}>
				<FormTextField
					fullWidth
					name={`${name2}city`}
					label={t( 'common:city' )}
					autoComplete='address-level2'
					size={fieldSize}
				/>
			</Grid>
			<Grid item xs={12} sm={6} lg={2} {...gridBreakpoints.state}>
				<FormTextField
					fullWidth
					name={`${name2}state`}
					label={t( 'common:state' )}
					autoComplete='address-level1'
					size={fieldSize}
				/>
			</Grid>
			<Grid item xs={12} sm={6} lg={3} {...gridBreakpoints.country}>
				<FormTextField
					fullWidth
					name={`${name2}country`}
					label={t( 'common:country' )}
					autoComplete='country'
					size={fieldSize}
				/>
			</Grid>
			<Grid item xs={12} sm={6} lg={3} {...gridBreakpoints.postalCode}>
				<FormTextField
					fullWidth
					name={`${name2}postalCode`}
					label={t( 'common:zip-code' )}
					autoComplete='postal-code'
					size={fieldSize}
				/>
			</Grid>
			{showFriendlyName && (
				<Grid item xs={12}>
					<FormTextField
						fullWidth
						name='name'
						label={t( 'common:friendly-name' )}
						size='small'
						placeholder={t( 'common:friendly-name' )}
					/>
				</Grid>
			)}
			{showNamePhoneAndEmail && (
				<Grid item xs={12} {...gridBreakpoints.fullName}>
					<FormTextField
						fullWidth
						name={`${name2}fullName`}
						label={t( 'common:recipient' )}
					/>
				</Grid>
			)}
			{showNamePhoneAndEmail && (
				<Grid item xs={6} {...gridBreakpoints.email}>
					<FormTextField
						fullWidth
						name={`${name2}email`}
						label={t( 'common:email' )}
						type='email'
						autoComplete='email'
					/>
				</Grid>
			)}
			{showNamePhoneAndEmail && (
				<Grid item xs={6} {...gridBreakpoints.phone}>
					<FormPhoneField
						name='phone'
						label={t( 'common:phone' )}
						onChange={( value, data, event, formattedValue ) => {
							formik.setFieldValue( `${name2}phone`, formattedValue );
						}}
					/>
				</Grid>
			)}
			{showNamePhoneAndEmail && <Grid item xs={12}>
				<FormTextField
					multiline
					fullWidth
					name='deliveryNote'
					placeholder={t( 'common:add-note' )}
					label={`Delivery Note`}
					variant='outlined'
					inputProps={{ maxLength: 1500 }}
					rows={5}
				/>
			</Grid>}
		</Grid>
	);
}
