import { useGraphQL } from '@/data';
import { StorePublicReadHookQuery, StorePublicReadHookQueryVariables } from '@/generated/graphql';
import { Address, Location, Maybe, Order, QueryOrderPublicReadArgs, StoreType } from '@/types/schema';
import { gql } from '@apollo/client';
import { useSetAtom } from 'jotai';
import { useAtomValue } from 'jotai/index';
import { atomWithStorage } from 'jotai/utils';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

type OnlineStoreAtomType = {
	atomOrderId?: string | null,
	atomCustomerAddress?: Partial<Address> | null,
	atomStoreType?: StoreType | null,
	atomStoreLocation?: Location | null
};

// Define the atom
export const onlineStoreDataAtom = atomWithStorage<Record<string, OnlineStoreAtomType>>(
	'online-stores',
	{},
	undefined,
	{ getOnInit: true },
);

// Helper hook to update atom fields without overwriting the entire object
export const useUpdateOnlineStoreAtom = () => {
	const setAtom = useSetAtom( onlineStoreDataAtom );
	const router = useRouter();
	const storeId = router.query.id as string;
	
	return useCallback( ( newValues: Partial<OnlineStoreAtomType> ) => {
		setAtom( ( prev ) => ( {
			...prev,
			[ storeId ]: { ...prev[ storeId ], ...newValues },
		} ) );
	}, [ setAtom ] );
};

// Helper hook to get atom fields
export const useGetStoreAtom = (): OnlineStoreAtomType => {
	const getAtom = useAtomValue( onlineStoreDataAtom );
	const router = useRouter();
	const storeId = router.query.id as string;
	
	return getAtom[ storeId ] || {};
};

// TO-FIX!!!: should use individual store query instead of one hook for everything
export function useStorePublicRead() {
	const router = useRouter();
	const { data, ...rest } = useGraphQL<StorePublicReadHookQueryVariables, StorePublicReadHookQuery>( {
		query: gql`
			query StorePublicReadHook($id: String!) {
				storePublicRead(id: $id, ) {
					id
					name
					updatedAt
					type
					description
					active
					city
					leadTime
					radius
					email
					cell
					industries
					paymentOptions
					selectionsMenu
					allowPriceUpdates
					clientCategories {
						id
						name
					}
					hideLineItemImages
					requireInStock
					lineItemView
					company {
						id
						logo
						banner
						description
						email
						metadata
					}
					country
					minDelivery
					taxPercent
					banner
					logo
					hours
					rewards
					metadata
					requestVerification
					location {
						id
						name
						address {
							id
							line1
							line2
							city
							state
							postalCode
							country
						}
					}
					prices {
						id
						name
						value
						isPercent
						metadata
						quantity
						isZipCode
						minDelivery
					}
					
					lineItems {
						id
						name
						price
						image
						images
						description
						unit
						quantity
						sequence
						tags
						externalId
						hours
						active
						metadata
						leadTime
						timeIncrement
						uom {
							id
							quantity
						}
						category {
							id
							name
							sequence
							description
						}
					}
				}
			}
		`,
		queryKey    : [ 'storePublicRead' ],
		subscription: { STORE: router.query.id as string },
		variables   : { id: router.query.id as string },
	}, {
		placeholderData: undefined,
		enabled        : Boolean( router.query.id ),
		staleTime      : 1000 * 60 * 60 * 24, // valid for 24 hours
	} );
	
	return {
		store: data?.storePublicRead as StorePublicReadHookQuery['storePublicRead'],
		...rest,
	};
}

export function useOrderPublicRead() {
	const { atomOrderId } = useGetStoreAtom();
	const query = useGraphQL<QueryOrderPublicReadArgs>( {
		query: gql`
			query OrderPublicRead_62aa($id: String) {
				orderPublicRead(id: $id) {
					id
					taxPercent
					subTotal
					grandTotal
					taxTotal
					metadata
					companyLocation {
						id
						gateway {
							id
							external
						}
					}
					company {
						id
						metadata
					}
					lineItems {
						id
						images
						name
						unit
						description
						price
						quantity
						tax
						code
						externalId
						metadata
						cost
						note
						orderTax
						prices {
							id
							name
							isPercent
							value
							quantity
							metadata
							externalId
							isSavedFee
						}
						modifierGroups {
							id
							name
							min
							max
							sequence
							modifiers {
								id
								name
								isPercent
								value
								quantity
								min
								max
								metadata
								externalId
							}
						}
					}
				}
			}
		`,
		queryKey    : [ 'orderPublicRead' ],
		subscription: { ORDER: atomOrderId as string },
		variables   : { id: atomOrderId! },
	}, {
		enabled  : Boolean( atomOrderId ),
		staleTime: 1000 * 60 * 60 * 24, // valid for 24 hours
	} );
	
	if ( !query.data?.orderPublicRead ) return { ...query, order: {} as Maybe<Order> };
	
	return {
		...query,
		order: query.data?.orderPublicRead as Order,
	};
}

