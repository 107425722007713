import FormSelect from '@/components/form/fields/select';
import FormTextField from '@/components/form/fields/textField';
import { Grid, MenuItem, Stack, Typography } from '@mui/material';
import * as yup from 'yup';

export const bankInfoValidationSchema = yup.object().shape( {
	bankDetail: yup.object().shape( {
		depositBank: yup.object().shape( {
			bankAcctNum: yup
				.string()
				.required( 'Enter a bank account number' )
				.max( 64, 'Bank account number is too long' ),
			bankRoutingNum: yup
				.string()
				.required( 'Enter a bank routing number' )
				.length( 9, 'Bank routing number must be 9 digits' )
				.max( 64, 'Bank routing number is too long' )
				.test( 'deposit-routing-account-different', 'Routing and account numbers must be different', function( value ) {
					const { bankAcctNum } = this.parent;
					return value !== bankAcctNum;
				} ),
			confirmAccountNumber: yup
				.string()
				.required( 'Confirm your bank account number' )
				.max( 64, 'Bank account number is too long' )
				.oneOf( [ yup.ref( 'bankAcctNum' ) ], 'Account numbers must match' ),
			bankAcctTypeCd: yup
				.string()
				.required( 'Enter a bank account type' )
				.max( 64, 'Bank account type is too long' )
				.oneOf( [ 'BIZ', 'Savings', 'GL' ], 'Select a valid tax filing method' ),
			bankName: yup
				.string()
				.required( 'Enter a bank name' )
				.max( 64, 'Bank name is too long' ),
		} ),
		withdrawalBank: yup.object().shape( {
			bankAcctNum: yup
				.string()
				.required( 'Enter a bank account number' )
				.max( 64, 'Bank account number is too long' ),
			bankRoutingNum: yup
				.string()
				.required( 'Enter a bank routing number' )
				.length( 9, 'Bank routing number must be 9 digits' )
				.max( 64, 'Bank routing number is too long' )
				.test( 'withdrawal-routing-account-different', 'Routing and account numbers must be different', function( value ) {
					const { bankAcctNum } = this.parent;
					return value !== bankAcctNum;
				} ),
			confirmAccountNumber: yup
				.string()
				.required( 'Confirm your bank account number' )
				.max( 64, 'Bank account number is too long' )
				.oneOf( [ yup.ref( 'bankAcctNum' ) ], 'Account numbers must match' ),
			bankAcctTypeCd: yup
				.string()
				.required( 'Enter a bank account type' )
				.max( 64, 'Bank account type is too long' )
				.oneOf( [ 'BIZ', 'Savings', 'GL' ], 'Select a valid tax filing method' ),
			bankName: yup
				.string()
				.required( 'Enter a bank name' )
				.max( 64, 'Bank name is too long' ),
		} ),
	} ),
} );

export default function BankInfo() {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant='h4' fontWeight='500'>
					Deposit Account Information
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<FormTextField
					fullWidth
					name='bankDetail.depositBank.bankName'
					label='Deposit Bank Name'
				/>
			</Grid>
			<Grid item xs={12}>
				<FormSelect
					fullWidth
					name='bankDetail.depositBank.bankAcctTypeCd'
					label='Bank Account Type'
					defaultValue='BIZ'>
					<MenuItem value='BIZ'>Business</MenuItem>
					<MenuItem value='Savings'>Savings</MenuItem>
					<MenuItem value='GL'>General Ledger</MenuItem>
				</FormSelect>
			</Grid>
			<Grid item xs={12}>
				<FormTextField
					fullWidth
					name='bankDetail.depositBank.bankAcctNum'
					label='Bank Account Number'
					inputProps={{ type: 'number' }}
				/>
			</Grid>
			<Grid item xs={12}>
				<FormTextField
					fullWidth
					name='bankDetail.depositBank.confirmAccountNumber'
					label='Confirm Account Number'
					inputProps={{ type: 'number' }}
				/>
			</Grid>
			<Grid item xs={12}>
				<FormTextField
					fullWidth
					name='bankDetail.depositBank.bankRoutingNum'
					label='Routing Number'
					inputProps={{ type: 'number' }}
				/>
			</Grid>
			<Grid item xs={12}>
				<Stack>
					<Typography variant='h4' fontWeight='500'>
						Withdrawal Account Information
					</Typography>
					<Typography>
						(For refund purposes only)
					</Typography>
				</Stack>
			</Grid>
			<Grid item xs={12}>
				<FormTextField
					fullWidth
					name='bankDetail.withdrawalBank.bankName'
					label='Deposit Bank Name'
				/>
			</Grid>
			<Grid item xs={12}>
				<FormSelect
					fullWidth
					name='bankDetail.withdrawalBank.bankAcctTypeCd'
					label='Bank Account Type'
					defaultValue='BIZ'>
					<MenuItem value='BIZ'>Business</MenuItem>
					<MenuItem value='Savings'>Savings</MenuItem>
					<MenuItem value='GL'>General Ledger</MenuItem>
				</FormSelect>
			</Grid>
			<Grid item xs={12}>
				<FormTextField
					fullWidth
					name='bankDetail.withdrawalBank.bankAcctNum'
					label='Deposit Bank Account Number'
					inputProps={{ type: 'number' }}
				/>
			</Grid>
			<Grid item xs={12}>
				<FormTextField
					fullWidth
					name='bankDetail.withdrawalBank.confirmAccountNumber'
					label='Confirm Account Number'
					inputProps={{ type: 'number' }}
				/>
			</Grid>
			<Grid item xs={12}>
				<FormTextField
					fullWidth
					name='bankDetail.withdrawalBank.bankRoutingNum'
					label='Routing Number'
					inputProps={{ type: 'number' }}
				/>
			</Grid>
		</Grid>
	);
}
