import { useCompany } from '@/hooks/useSetCompanyInAtom';
import ConnectVoPayFormModal from '@/modals/vopayForm/connectVopayFormModal';
import { stayOpen } from '@/pages/dashboard/commerce/components/tableHelpers';
import { useModal } from '@/providers/modal';
import { dismissibleBannerAtoms } from '@/utils/atoms';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import { Button, Stack, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { lowerCase } from 'lodash-es';
import { useRouter } from 'next/router';
import GlobalBannerWrapper from './wrapper';

export default function VoPayBanner() {
	const { company } = useCompany();
	const { showModal } = useModal();
	const router = useRouter();
	const [ globalBanners, setGlobalBanners ] = useAtom( dismissibleBannerAtoms );
	
	const urlParam = new URLSearchParams( router.asPath.split( '?' )[ 1 ] );
	const devFlag = urlParam.has( 'vopayBanner' );
	const hasVoPay = company?.gateways?.some( ( g ) => g.external === 'VOPAY' );
	const hasCaAddress = Boolean( company?.locations
		?.map( ( l ) => l.address )
		?.some( ( addr ) => [ 'canada', 'ca' ].includes( lowerCase( addr.country ) ) ) );
	
	return (
		<GlobalBannerWrapper
			show={( devFlag || !hasVoPay && hasCaAddress ) && !globalBanners.hideVoPayBanner}
			onClose={() => setGlobalBanners( ( prev ) => ( { ...prev, hideVoPayBanner: true } ) )}>
			<Stack
				spacing={1}
				direction={{ xs: 'column', lg: 'row' }}
				alignItems='center'
				justifyContent='center'>
				<Typography variant='h5' sx={{ textAlign: 'center', fontWeight: 'normal', color: 'primary.main' }}>
					Bank transfer is now available! Enable your customers to pay via EFT.
				</Typography>
				<Button
					variant='outlined'
					color='primary'
					startIcon={<AccountBalanceRoundedIcon/>}
					onClick={() => {
						showModal( ConnectVoPayFormModal, {
							id      : 'vopay-connect-modal',
							onClose : ( event, reason ) => stayOpen( event, reason ),
							maxWidth: 'xs',
						} );
					}}>
					Connect Bank
				</Button>
			</Stack>
		</GlobalBannerWrapper>
	);
}

