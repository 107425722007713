import { EmailFooter } from '@/components/emailWrapper/footer';
import { removeSpecialAlphabets } from '@/pages/api/processor/quickbooks.utils';
import {
	Box,
	Divider,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableContainerProps,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from '@mui/material';
import type { ReactNode } from 'react';
import StyledImage from '../styledImage';

export default function EmailWrapper( {
	children,
	reqBodyNote,
	headerLogo,
	removeFooter,
	removeHeaderImage,
	commercial = false,
	footerNotes,
	emailBgColor = 'transparent',
	tableContainerProps,
}: {
	children: ReactNode,
	reqBodyNote?: string,
	headerLogo?: string,
	removeFooter?: boolean,
	removeHeaderImage?: boolean,
	commercial?: boolean,
	footerNotes?: ReactNode,
	emailBgColor?: string,
	tableContainerProps?: TableContainerProps
} ) {
	const theme = useTheme();
	
	return (
		<TableContainer
			{...tableContainerProps}
			sx={{
				border      : `1px solid ${theme.palette.divider}`,
				maxWidth    : '600px !important',
				m           : 'auto',
				textAlign   : 'center',
				borderRadius: 1.5,
				...tableContainerProps?.sx,
			}}>
			<Table
				sx={{
					'@media screen and (min-width: 567px)': {
						'td, p, span, li, a, .MuiTypography-root': { fontSize: '14px !important' },
						'.MuiTypography-h1'                      : { fontSize: '30px !important' },
						'.MuiTable-root'                         : {
							'.MuiTableCell-root, .MuiTypography-root': {
								fontSize: '14px !important',
							},
						},
					},
					'@media screen and (max-width: 567px)': {
						'td, p, span, li, a, .MuiTypography-root': { fontSize: '12px !important' },
						'.MuiTypography-h1'                      : { fontSize: '25px !important' },
						'.MuiTable-root'                         : {
							'.MuiTableCell-root, .MuiTypography-root': {
								fontSize: '12px !important',
							},
						},
					},
					'.footerFont'                         : { fontSize: '13px !important' },
					'bgcolor'                             : emailBgColor,
					'py'                                  : 2,
					'px'                                  : '0 !important',
					'maxWidth'                            : '600px !important',
					'm'                                   : 'auto',
					'textAlign'                           : 'center',
					'.emailWrapperTableCell'              : {
						border : '0px !important',
						padding: '0px !important',
					},
				}}>
				{!removeHeaderImage && headerLogo && (
					<TableHead>
						<TableRow>
							<TableCell className='emailWrapperTableCell'>
								<Box
									sx={{
										backgroundImage: 'url("https://dev.invoiss.com/images/whitebg.png")',
										borderBottom   : 1,
										borderColor    : 'divider',
										py             : 2,
										textAlign      : 'center',
									}}>
									<StyledImage
										sx={{
											width    : 'auto',
											height   : '50px !important',
											maxHeight: '50px !important',
											textAlign: 'center',
											display  : 'flex',
											mx       : 'auto',
											objectFit: 'contain',
										}}
										alt='account logo'
										src={headerLogo}
									/>
								</Box>
							</TableCell>
						</TableRow>
					</TableHead>
				)}
				<TableBody>
					<TableRow>
						<TableCell className='emailWrapperTableCell'>
							<Box className='emailWrapperBody' sx={{ p: { xs: 2, md: 5 }, height: '100%' }}>
								{reqBodyNote && (
									<Box>
										<Typography sx={{ whiteSpace: 'pre-line', mb: 1 }}>
											{removeSpecialAlphabets( reqBodyNote )}
										</Typography>
										<Divider sx={{ my: 3 }}/>
									</Box>
								)}
								{children}
							</Box>
						</TableCell>
					</TableRow>
					{!removeFooter && (
						<TableRow>
							<TableCell className='emailWrapperTableCell'>
								<EmailFooter footerNotes={footerNotes} commercial={commercial}/>
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

