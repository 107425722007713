import { GatewayCredentials } from '@/types/gateway';
import axios from 'axios';
import { QUICKBOOKS_ENDPOINT } from '../quickbooks/quickbooks';

export const removeSpecialAlphabets = ( str: string ) => {
	if ( !str ) return '';
	return str.normalize( 'NFD' )
		.replace( /[\u0300-\u036f]/g, '' )
		.replace( /\[/g, '(' )
		.replace( /]/g, ')' )
		.trim();
};

export const normalizeName = ( name: string ) => removeSpecialAlphabets( name ).slice( 0, 99 );

export const getQB = (
	gateway: GatewayCredentials,
	urlComponent: string,
	params: object = {},
) => axios.get( `${QUICKBOOKS_ENDPOINT}/${gateway.externalId}${urlComponent}`, {
	headers: { Authorization: `Bearer ${gateway.externalKey}` },
	params : { ...params, minorversion: 65 },
} );

export const postQB = (
	gateway: GatewayCredentials,
	urlComponent: string,
	payload: object = {},
	params: object = {},
) => axios.post( `${QUICKBOOKS_ENDPOINT}/${gateway.externalId}${urlComponent}`, payload, {
	headers: { Authorization: `Bearer ${gateway.externalKey}` },
	params : { ...params, minorversion: 65 },
} );

export const queryQB = ( gateway: GatewayCredentials, query: string ) => getQB( gateway, '/query', { query } );

export const queryQbEntity = ( { gateway, entity, ids, size = 100, page = 0 }: {
	gateway: GatewayCredentials,
	entity: string,
	ids?: string[],
	size?: number,
	page?: number
} ) => {
	const startPosition = page * size + 1;
	let query = `SELECT * FROM ${entity}`;
	if ( ids ) query += ` WHERE Id IN ('${ids.join( '\',\'' )}')`;
	if ( size ) query += ` MAXRESULTS ${size}`;
	query += ` STARTPOSITION ${startPosition}`;
	return queryQB( gateway, query );
};
