import Form from '@/components/form';
import { ModalFormWrapper } from '@/components/form/modal';
import LargeChip from '@/components/largeChip';
import { CommerceRead } from '@/data/commerce/commerce.graphql';
import PageGraphqlProvider from '@/data/query/pageGraphqlProvider';
import SyncToClover from '@/helpers/syncToClover';
import isOrderSyncedToClover from '@/helpers/useCheckIsSyncedToClover';
import { isEstimateType } from '@/helpers/useIsEstimateType';
import { disableEmailButton, sendCommerceEmail } from '@/modals/email/utils';
import { EmailValuesType, getCommerceEmailValues } from '@/pages/dashboard/commerce/invoices/syncAndPay';
import { updateStock } from '@/pages/dashboard/commerce/orders/utils';
import InvoiceOrderEstimateEmailView from '@/pages/p/commerce/emailView/invoiceOrderEstimate';
import LastSentSection from '@/pages/p/commerce/emailView/lastSentSection';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModalControls } from '@/providers/modal';
import type { EmailType, Order, QueryCommerceReadArgs } from '@/types/schema';
import { getBrowserTimezone } from '@/utils/timezone';
import { Box, Paper, Stack, useTheme } from '@mui/material';
import { isString, startCase, toLower } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmailComponent } from './emailComponent';
import ToggleEmailPdfUrl from './invoiceOrderEstimate/toggleEmailPdfUrl';

export function InvoiceOrderEstimateEmailModal( {
	id,
	subject,
}: {
	id: string,
	subject?: string
} ) {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { staff } = useUserInfo();
	const { closeModal } = useModalControls();
	const timezone = getBrowserTimezone();
	const theme = useTheme();
	
	const [ sendViaType, setSendViaType ] = useState<EmailType>( staff?.defaultEmailClient );
	
	return (
		<PageGraphqlProvider<Order, QueryCommerceReadArgs>
			queryKey='commerce'
			query={CommerceRead}
			variables={{ id }}>
			{( order ) => {
				const syncedToClover = isOrderSyncedToClover( order );
				const stripeOrder = order.companyLocation?.gateway?.external === 'STRIPE';
				const cloverAccount = order.companyLocation?.gateway?.external === 'CLOVER';
				const unPayable = cloverAccount && !syncedToClover && !stripeOrder && order.grandTotal > 0 && ( order.type === 'INVOICE' || order.type === 'ORDER' || order.type === 'STATEMENT' );
				const emailValues = getCommerceEmailValues( staff, order, subject );
				
				return (
					<Form<EmailValuesType>
						initialValues={{ ...emailValues, syncAndSend: false, toggleEmailPdfUrl: true }}
						onSubmit={async ( values ) => {
							try {
								enqueueSnackbar( 'Sending Email..', { variant: 'info' } );
								closeModal();
								if ( values.syncAndSend ) {
									await SyncToClover( order );
									await updateStock( order, staff.company, 'manualSyncStock' );
								}
								await sendCommerceEmail( order, timezone, values, sendViaType, staff, theme ).then( () => {
									enqueueSnackbar( t( 'commerce:email-sent' ), { variant: 'success' } );
								} ).catch( () => {
									enqueueSnackbar( t( 'commerce:email-not-sent' ), { variant: 'error' } );
								} );
								await updateStock( order, staff.company, 'manualSendStock', enqueueSnackbar )
									.catch( () => null );
							} catch ( e ) {
								const cloverErrors = e?.response.data?.cloverErrors;
								if ( cloverErrors ) {
									throw isString( cloverErrors )
										? cloverErrors
										: cloverErrors?.error?.message || cloverErrors?.message || 'An error has occurred - Clover.com';
								} else {
									enqueueSnackbar( t( 'commerce:sync-fail' ), { variant: 'default' } );
								}
								
								console.error( e );
								throw e;
							}
						}}>
						{( formik ) => (
							<ModalFormWrapper
								title={`${t( 'commerce:send' )} ${startCase( toLower( order.type ) )}`}
								actionItems={[
									unPayable && !isEstimateType( order.type )
									&& !order.standing && {
										name   : 'Sync & Send',
										details: `${order.companyLocation?.gateway?.external !== 'CLOVER'
											? `${t( 'commerce:edit-this' )} ${startCase( toLower( order.type ) )} ${t( 'commerce:company-location' )}`
											: `${t( 'commerce:will-sync' )} ${startCase( toLower( order.type ) )} ${t( 'commerce:will-sync-post' )}`}`,
										buttonProps: {
											variant : 'contained',
											size    : 'large',
											disabled: disableEmailButton( formik ) || order.companyLocation?.gateway?.external !== 'CLOVER',
										},
										onClick: async () => {
											await formik.setFieldValue( 'syncAndSend', true );
											await formik.submitForm();
										},
									}, {
										name: `${t( 'common:send' )} ${unPayable && !order.standing
											? `(${t( 'common:unpayable' )})`
											: ''}`,
										buttonProps: {
											variant : `${unPayable ? 'outlined' : 'contained'}`,
											color   : 'primary',
											size    : 'large',
											disabled: disableEmailButton( formik ),
										},
										onClick: () => formik.submitForm(),
									} ]}
								onSave={undefined}>
								{unPayable && !order.standing && (
									<Stack direction='row'>
										<Box width={100}/>
										<LargeChip
											label={`${t( 'commerce:client-unable' )} ${toLower( order.type )} ${t( 'commerce:client unable post' )}`}
											color='warning'
											sx={{ mt: 1, mb: 2, width: '70%' }}
										/>
									</Stack>
								)}
								{( !staff?.gmailData?.email || !staff?.outlookData?.email ) && (
									<Stack direction='row'>
										<Box width={100}/>
										<LargeChip
											label='Connect your Gmail or outlook to easily track sent emails directly from your sent box.'
											color='info'
											sx={{ mt: 1, mb: 2, width: '70%' }}
										/>
									</Stack>
								)}
								<EmailComponent
									sendViaType={sendViaType}
									setSendViaType={setSendViaType}
									emailView={(
										<Fragment>
											<Paper sx={{ p: 3, bgcolor: 'background.default' }}>
												<InvoiceOrderEstimateEmailView data={order} timezone={timezone}/>
											</Paper>
											<ToggleEmailPdfUrl/>
										</Fragment>
									)}
								/>
								<LastSentSection data={order} timezone={timezone}/>
							</ModalFormWrapper>
						)}
					</Form>
				);
			}}
		</PageGraphqlProvider>
	);
}
