import { MenuItem, TextField } from '@mui/material';
import { get, isArray, isEmpty, omit, set, startCase, toLower } from 'lodash-es';
import type { ColumnInstance } from 'react-table';

export default function StringFilter( {
	filter,
	setFilter,
	column,
}: {
	column?: ColumnInstance<any>,
	filter: { $ilike, $eq, $in, $or },
	setFilter: ( filterValue: any ) => void
} ) {
	const key = column?.filterByKey;
	const multiFilters = column?.multipleFilters;
	
	return (
		<TextField
			fullWidth
			select={Boolean( column?.filterOptions )}
			variant='outlined'
			placeholder='Search'
			value={filter?.$ilike || filter?.$eq || filter?.$in && 'OPEN' || get( filter?.$or?.[ 0 ], multiFilters?.[ 0 ] )?.$ilike || isArray( key ) && get( filter?.$or?.[ 0 ], key?.[ 0 ] )?.$ilike || ''}
			onChange={( e ) => {
				if ( multiFilters && !isEmpty( column?.filterOptions ) && e.target.value === 'OPEN' ) {
					const newFilter = omit( filter, '$eq' );
					setFilter( {
						...newFilter,
						$in: multiFilters,
					} );
				} else if ( multiFilters && isEmpty( column?.filterOptions ) ) {
					setFilter( {
						...filter,
						$or: multiFilters.map( ( field ) => set( {}, field, { $ilike: e.target.value } ) ),
					} );
				} else if ( typeof key === 'string' ) {
					const newFilter = omit( filter, '$in' );
					setFilter( {
						...newFilter,
						$eq: e.target.value,
					} );
				} else if ( isArray( key ) ) {
					const newFilter = omit( filter, '$in' );
					setFilter( {
						...newFilter,
						$or: key.map( ( field ) => set( {}, field, { $ilike: e.target.value } ) ),
					} );
				} else {
					setFilter( {
						...filter,
						$ilike: e.target.value,
					} );
				}
				
			}}>
			{column?.filterOptions && column?.filterOptions?.map( ( option ) => (
				<MenuItem key={option} value={option}>
					{startCase( toLower( option ) )}
				</MenuItem>
			) )}
		</TextField>
	);
}
