import { useGraphQL } from '@/data';
import { PartnerRead_Pr10Query, PartnerRead_Pr10QueryVariables } from '@/generated/graphql';
import { usePartnerId } from '@/providers/auth/useSessionStoragePartnerId';
import { useEvents } from '@/providers/event';
import { gql } from '@apollo/client';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

export function usePartner() {
	const { sessionPartnerId } = usePartnerId();
	const queryClient = useQueryClient();
	const appEvent = useEvents();
	
	const { data, isLoading } = useGraphQL<PartnerRead_Pr10QueryVariables, PartnerRead_Pr10Query>( {
		query: gql`
			query PartnerRead_PR10 {
				partnerRead {
					id
					name
					firstName
					lastName
					email
					isActive
					phone
					cell
					website
					commission
					logo
					createdAt
					updatedAt
					salesAgents {
						id
						firstName
					}
				}
			}`,
		queryKey: [ 'partner' ],
	}, { enabled: sessionPartnerId !== 'null' && Boolean( sessionPartnerId ) } );
	
	useEffect( () => {
		if ( !sessionPartnerId ) return;
		const event = `SUBSCRIPTION:${sessionPartnerId}`;
		const listener = () => {
			queryClient.invalidateQueries( [ 'partner' ] );
		};
		appEvent.on( event, listener );
		
		return () => {
			appEvent.off( event, listener );
		};
	}, [ sessionPartnerId ] );
	
	return { partner: data?.partnerRead, loading: isLoading };
}
