import StyledImage from '@/components/styledImage';
import { Checkbox, ListItemAvatar, ListItemButton, ListItemText, Paper } from '@mui/material';
import { useFormikContext } from 'formik';

export default function ToggleEmailPdfUrl() {
	const formik = useFormikContext<any>();
	const isChecked = Boolean( formik.values.toggleEmailPdfUrl );
	
	return (
		<Paper sx={{ mt: 1 }}>
			<ListItemButton
				onClick={() => formik.setFieldValue( 'toggleEmailPdfUrl', !isChecked )}>
				<ListItemAvatar>
					<StyledImage
						src='https://assets.invoiss.com/pdf-icon.png'
						sx={{
							width : 40,
							height: 50,
						}}
					/>
				</ListItemAvatar>
				<ListItemText
					primary='Attach PDF'
					secondary='Attach a PDF of this document to the email.'
					sx={{ mt: 0 }}
				/>
				<Checkbox
					checked={isChecked}
				/>
			</ListItemButton>
		</Paper>
	);
}
