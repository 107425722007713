import { BoolChip } from '@/components/chips';
import Attachment from '@/components/fileUploading/attachment';
import FormSelectTable from '@/components/form/fields/formSelectTable';
import PageSection from '@/components/page/section';
import { ItemsRead } from '@/data/management/item.graphql';
import currencyFormat from '@/helpers/currencyFormat';
import type { DeepPartial } from '@/types/deepPartial';
import type { Item, QueryItemsReadArgs, Category } from '@/types/schema';
import { Avatar, Box, ListItemAvatar, ListItemText, MenuItem, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';

export default function CategoryFormItems() {
	const { t } = useTranslation();
	const formik = useFormikContext<DeepPartial<Category>>();
	
	return (
		<PageSection primary={t( 'common:items' )}>
			<Box>
				<FormSelectTable<Item, QueryItemsReadArgs, DeepPartial<Item>>
					disableCloseOnSelect
					name='items'
					blurOnSelect={false}
					textFieldProps={{ placeholder: t( 'common:search-by-name' ) }}
					queryKey='items'
					query={ItemsRead}
					subscription={{ ITEM: 'COMPANY' }}
					getOptionLabel={( option: Item ) => option.name || ''}
					checkDuplicate={( item, newValue: Item ) => item?.id === newValue?.id}
					renderOption={( liProps, option: Item ) => (
						<MenuItem
							{...liProps}
							key={option.id ?? nanoid()}
							selected={formik.values.items?.some( ( item: any ) => item.id === option.id )}>
							<ListItemAvatar key={`avatar-${option.id}`}>
								<Avatar src={option.image as string}>
									{option.name?.charAt?.( 0 ).toUpperCase() ?? '-'}
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								key={`text-${option.id}`}
								primary={(
									<Stack direction='row' alignItems='center' spacing={1}>
										<Typography key={`name-${option.id}`}>
											{option.name}
										</Typography>
										<Typography key={`uom-${option.id}`} color='text.secondary'>
											({option?.uoms.map( ( uom ) => uom.name ).join( ', ' )})
										</Typography>
									</Stack>
								)}
								secondary={currencyFormat( option.uoms?.[ 0 ]?.price )}
							/>
						</MenuItem>
					)}
					tableProps={{
						hideAddIcon: true,
						headers    : [
							t( 'management:image' ),
							t( 'common:name' ),
							t( 'management:taxable' ),
							t( 'common:inventory' ),
							t( 'common:price' ),
						],
						columns: ( item ) => [
							item.image ? (
								<Attachment
									key='image'
									removeDownload
									src={item.image}
									imageSX={{ width: 40, height: 40, objectFit: 'cover' }}
								/>
							) : (
								<Avatar
									key='image'
									variant='rounded'
									src={item.image as string}
									sx={{ width: 38, height: 38, borderRadius: 2 }}>
									{item.name?.charAt?.( 0 ).toUpperCase() ?? '-'}
								</Avatar>
							),
							<Typography key='name'>{item.name}</Typography>,
							<BoolChip key='taxable' value={item.taxable ?? false}/>,
							<BoolChip key='isInventory' value={item.isInventory ?? false}/>,
							<Typography key='price'>{currencyFormat( item.uoms?.[ 0 ]?.price )}</Typography>,
						],
					}}
				/>
			</Box>
		</PageSection>
	);
}
