import EmailButton from '@/components/emailButton';
import EmailWrapper from '@/components/emailWrapper';
import Form from '@/components/form';
import EmailField from '@/components/form/fields/email';
import FormTextField from '@/components/form/fields/textField';
import { ModalFormWrapper } from '@/components/form/modal';
import useUserInfo from '@/providers/auth/useUserInfo';
import { Agreement, AgreementPublic, Client } from '@/types/schema';
import { Box, Stack, Typography } from '@mui/material';
import axios from 'axios'; // assuming axios is imported from 'axios'
import { useSnackbar } from 'notistack';
import SendViaField from './sendViaField';

export function AgreementToClientEmailWrapper( {
	client,
	agreement,
	companyName,
	invoiceId,
}: {
	client: Client,
	agreement: AgreementPublic,
	companyName: string,
	invoiceId: number
} ) {
	return (
		<Box>
			<Typography sx={{ mb: 2 }}>
				{`Hi ${client?.name || client?.contact || ''},`}
			</Typography>
			
			<Typography sx={{ mb: 2 }}>
				{`${companyName} has an agreement that would like you to review.`}
			</Typography>
			
			<EmailButton href={`${process.env.NEXT_PUBLIC_SITE_URL}/p/${invoiceId}/invoice?agreementId=${agreement.id}`}>
				Review & {agreement?.requireSignature ? 'Sign' : 'Approve'}
			</EmailButton>
		</Box>
	);
}

export default function SendAgreementToClientEmailModal( {
	email,
	agreement,
	client,
	invoiceId,
}: {
	email: string,
	agreement: Agreement,
	client: Client,
	invoiceId: number
} ) {
	const { staff } = useUserInfo();
	const { enqueueSnackbar } = useSnackbar();
	return (
		<Form
			initialValues={{
				email         : email,
				reqBodyNote   : '',
				recipientEmail: [ client?.email || '' ].filter( Boolean ),
				sendViaType   : 'INVOISS',
			}}
			onSubmit={async ( values ) => {
				try {
					if ( !values.email ) {
						enqueueSnackbar( 'Please enter a client email address', { variant: 'default' } );
						return;
					}
					const response = await axios.post( '/api/emails/sendAgreementToClient', {
						to         : values.recipientEmail,
						cc         : [],
						bcc        : [],
						subject    : `Agreement Review Request on ${new Date().toLocaleString()}`,
						emailBody  : values.reqBodyNote,
						sendVia    : values.sendViaType,
						staffId    : staff?.id,
						companyLogo: staff?.company?.logo,
						companyName: staff?.company?.name,
						replyTo    : staff?.email,
						agreement  : agreement,
						client     : client,
						invoiceId  : invoiceId,
						fileName   : '',
						url        : '',
					} );
					if ( response.status === 200 ) {
						enqueueSnackbar( 'Email sent successfully', { variant: 'success' } );
					}
				} catch ( error ) {
					console.error( 'Error sending email:', error );
					throw error;
				}
			}}>
			{( formik ) => (
				<ModalFormWrapper title='Send Agreement Email' saveButtonText='Send Email'>
					<Stack spacing={2}>
						<SendViaField
							sendViaType={formik.values.sendViaType}
							setSendViaType={( value ) => formik.setFieldValue( 'sendViaType', value )}
						/>
						<EmailField
							name='recipientEmail'
							textFieldProps={{ label: 'To' }}
						/>
						<FormTextField
							fullWidth
							multiline
							name='reqBodyNote'
							label='Additional text'
						/>
						<EmailWrapper
							headerLogo={staff?.company?.logo || undefined}
							reqBodyNote={formik.values.reqBodyNote}>
							<AgreementToClientEmailWrapper
								client={client}
								agreement={agreement}
								companyName={staff?.company?.name || ''}
								invoiceId={invoiceId}
							/>
						</EmailWrapper>
					</Stack>
				</ModalFormWrapper>
			)}
		</Form>
	);
}
