import { mutateGraphQL, queryGraphQL } from '@/data/apollo';
import { PLATFORM_ENDPOINT } from '@/pages/api/processor/clover';
import {
	CloverDiscount,
	CloverItem,
	CloverLineItem,
	CloverModifier,
	CloverOrder,
	CloverPayment,
	CloverTaxRate,
} from '@/types/clover';
import {
	AddressValidator,
	CategoryValidator,
	Client,
	ClientValidator,
	GatewayBase,
	Item,
	ItemValidator,
	LineItemValidator,
	MutationModifierGroupWriteArgs,
	OrderValidator,
	PaymentValidator,
	QueryClientsReadArgs,
	QueryModifierGroupsReadArgs,
} from '@/types/schema';
import { gql } from '@apollo/client';
import axios from 'axios';
import { filter, flatten, map } from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import DeviceValidator from 'invoiss-backend/src/graphql/validators/device.validator';
import { isEmpty, isUndefined, keyBy, omitBy, toLower, uniq } from 'lodash-es';
import countBy from 'lodash/fp/countBy';
import keyByFp from 'lodash/fp/keyBy';
import mapValues from 'lodash/fp/mapValues';
import { validate } from 'uuid';

export function convertItem(
	gatewayId: string,
	locationId: string,
	data,
	categoriesMap,
	modifierGroupsMap,
	taxRatesMap ) {
	const categoryExternalIds: string[] = data?.categories?.elements.map( ( { id } ) => id ) ?? [];
	const modifierGroupsExternalIds: string[] = data?.modifierGroups?.elements.map( ( { id } ) => id ) ?? [];
	const taxRatesExternalIds: string[] = data?.taxRates?.elements.map( ( { id } ) => id ) ?? [];
	
	return {
		gateway   : gatewayId,
		externalId: data.id,
		name      : data.name.replace( '/\0/g', '' ),
		taxable   : data.defaultTaxRates || !isEmpty( data.taxRates?.elements ),
		syncDate  : data.modifiedTime ? new Date( +data.modifiedTime ) : null,
		uoms      : [ {
			name    : data.unitName || 'Unit',
			price   : data.price / 100,
			cost    : data.cost / 100,
			quantity: data.itemStock?.quantity,
			sku     : data.sku,
			selected: true,
			code    : data?.code,
		} ],
		isHidden      : data.available === false ? true : null,
		locations     : [ locationId ],
		taxes         : taxRatesExternalIds.map( ( externalId ) => taxRatesMap[ externalId ] ).filter( Boolean ),
		categories    : categoryExternalIds.map( ( externalId ) => categoriesMap[ externalId ] ).filter( Boolean ),
		modifierGroups: modifierGroupsExternalIds.map( ( externalId ) => modifierGroupsMap[ externalId ] )
			.filter( Boolean ),
		tags: data.tags?.elements,
	} as ItemValidator;
}

export async function convertItems( gatewayId: string, locationId: string, items: CloverItem[] ) {
	const categoryExternalIds = pipe(
		items,
		map( ( item ) => item?.categories?.elements.map( ( { id } ) => id ) ),
		filter( Boolean ),
		flatten,
		uniq,
	);
	const modifierGroupsExternalIds = pipe(
		items,
		map( ( item ) => item?.modifierGroups?.elements.map( ( { id } ) => id ) ),
		filter( Boolean ),
		flatten,
		uniq,
	);
	const taxRatesExternalIds = pipe(
		items,
		map( ( item ) => item?.taxRates?.elements.map( ( { id } ) => id ) ),
		filter( Boolean ),
		flatten,
		uniq,
	);
	
	const [ categoriesMap, modifierGroupsMay, taxRatesMap ] = await Promise.all( [
		queryGraphQL( {
			query: gql`
				query CategoriesRead_de09($options: FilterOptions) {
					categoriesRead(options: $options) {
						items { id, externalId }
					}
				}
			`,
			variables: {
				options: {
					filter: { externalId: { $in: categoryExternalIds } },
					limit : categoryExternalIds.length,
				},
			},
		} )
			.then( ( { categoriesRead } ) => categoriesRead.items ).catch( () => [] ),
		queryGraphQL( {
			query: gql`
				query ModifierGroupsRead_ae87($options: FilterOptions) {
					modifierGroupsRead(options: $options) {
						items { id, externalId }
					}
				}
			`,
			variables: {
				options: {
					filter: { externalId: { $in: modifierGroupsExternalIds } },
					limit : modifierGroupsExternalIds.length,
				},
			},
		} )
			.then( ( { modifierGroupsRead } ) => modifierGroupsRead.items ).catch( () => [] ),
		queryGraphQL( {
			query: gql`
				query PricesRead_f1e7($options: FilterOptions) {
					pricesRead(options: $options) {
						items { id, externalId }
					}
				}
			`,
			variables: {
				options: {
					filter: { externalId: { $in: taxRatesExternalIds } },
					limit : taxRatesExternalIds.length,
				},
			},
		} )
			.then( ( { pricesRead } ) => pricesRead.items ).catch( () => [] ),
	] ).then( ( res ) => res.map( ( items ) => pipe( items, keyByFp( 'externalId' ), mapValues( 'id' ) ) ) );
	
	console.log( 'got categories', categoryExternalIds.length, Object.keys( categoriesMap ).length );
	console.log( 'got modifier groups', modifierGroupsExternalIds.length, Object.keys( modifierGroupsMay ).length );
	
	return items.map( ( item ) => convertItem(
		gatewayId,
		locationId,
		item,
		categoriesMap,
		modifierGroupsMay,
		taxRatesMap,
	) );
}

export function convertCategory( gateway: GatewayBase, location: string, data ) {
	
	return {
		gateway   : gateway.id,
		externalId: data.id,
		name      : data.name,
		locations : location ? [ location ] : undefined,
	} as CategoryValidator;
}

export function convertCloverClient( gateway: GatewayBase, data ) {
	const phone = data.phoneNumbers?.elements[ 0 ]?.phoneNumber;
	const email = data.emailAddresses?.elements[ 0 ]?.emailAddress;
	const name = data.metadata?.businessName;
	if ( !name && !data.firstName && !data.lastName && !phone && !email ) throw 'Invisible';
	return {
		gateway   : gateway.id,
		externalId: data.id,
		name,
		contact   : !data.firstName && !data.lastName ? '-' : [ data.firstName || '',
		                                                        data.lastName || '' ].filter( Boolean ).join( ' ' ),
		email    : email?.length ? toLower( email ) : null,
		phone,
		addresses: data.addresses?.elements.map( ( address ) => ( {
			line1     : address.address1,
			line2     : address.address2,
			city      : address.city,
			country   : address.country || 'US', // fallback to US when country is undefined on Clover
			state     : address.state,
			postalCode: address.zip,
			externalId: address.id,
		} ) as AddressValidator ) || [],
		metadata: { note: data.metadata?.note },
	} as ClientValidator;
}

export function convertCloverPayment( gatewayId: string, payment: Partial<CloverPayment> ) {
	const refundedAmount = payment?.refundedAmount;
	const additionalCharges = payment?.additionalCharges?.elements;
	
	let checkNumber = '';
	if ( payment.note?.includes( '#' ) ) {
		const checkNumbers = payment.note.split( '#' );
		checkNumber = checkNumbers[ checkNumbers.length - 1 ];
	}
	const cardTransaction = payment?.cardTransaction;
	// const stripeId = payment.externalPaymentId?.startsWith( 'stripe-' )
	// 	? payment.externalPaymentId.replace( 'stripe-', '' )
	// 	: undefined;
	
	return {
		type  : payment.tender?.label || undefined,
		status: refundedAmount > 0 && refundedAmount >= payment.amount
			? 'REFUNDED'
			: refundedAmount > 0 && refundedAmount < payment.amount
				? 'PARTIALLY_REFUNDED'
				: payment.result === 'SUCCESS' ? 'PAID' : 'FAILED',
		amount        : payment.amount / 100,
		tip           : payment.tipAmount / 100,
		note          : payment.note,
		checkNumber   : checkNumber,
		refundedAmount: refundedAmount / 100,
		gateway       : gatewayId,
		createdAt     : +payment?.createdTime ? new Date( +payment.createdTime ) : null,
		externalId    : payment.id,
		// stripeId,
		cardBrand     : cardTransaction?.cardType,
		cardEntryType : cardTransaction?.entryType,
		cardLast4     : cardTransaction?.last4,
		cardFunc      : cardTransaction?.extra?.func,
		prices        : !isEmpty( additionalCharges ) ? additionalCharges.map( ( charge ) => ( {
			name      : charge.type,
			isPercent : true,
			value     : charge.rate / 10000,
			externalId: charge.id,
		} ) ) : [],
	} as PaymentValidator;
}

const aggregateLineItems = ( items: CloverLineItem[] ) => items?.reduce( ( array, lineItem ) => {
	const modifications = lineItem?.modifications?.elements || [];
	const discounts: CloverDiscount[] = lineItem.discounts?.elements || [];
	const taxRates: CloverTaxRate[] = lineItem.taxRates?.elements || [];
	const item = array.find( ( stored ) =>
		lineItem.name === stored.name
		&& lineItem.price === stored.price
		&& lineItem.note === stored.note
		&& lineItem?.itemCode === stored?.itemCode
		&& lineItem?.binName === stored?.binName
		&& modifications.length === ( stored?.modifications?.elements || [] ).length
		&& modifications.every( ( modification, index ) => {
			const storedModification = stored?.modifications?.elements[ index ];
			if ( !storedModification ) return false;
			return modification.name === storedModification.name
				&& modification.amount === storedModification.amount;
		} )
		&& discounts.length === ( stored.discounts?.elements || [] ).length
		&& discounts.every( ( discount, index ) => {
			const storedDiscount = stored.discounts?.elements[ index ];
			if ( !storedDiscount ) return false;
			return discount.name === storedDiscount.name
				&& discount.amount === storedDiscount.amount
				&& discount.percentage === storedDiscount.percentage;
		} )
		&& taxRates.length === ( stored.taxRates?.elements || [] ).length
		&& taxRates.every( ( taxRate, index ) => {
			const storedTaxRate = stored.taxRates?.elements[ index ];
			if ( !storedTaxRate ) return false;
			return taxRate.name === storedTaxRate.name
				&& taxRate.rate === storedTaxRate.rate;
		} ) );
	
	if ( item ) {
		item.hasSameLineItem = true;
		item.unitQty = item.unitQty + ( lineItem.unitQty ?? 1000 );
	} else {
		lineItem.hasSameLineItem = false;
		lineItem.unitQty = lineItem.unitQty ?? 1000;
		array.push( lineItem );
	}
	return array;
}, [] ) ?? [];

export function aggregateMetadata( lineItem: CloverLineItem ) {
	const elements = lineItem?.modifications?.elements;
	if ( isEmpty( elements ) ) return;
	
	return pipe(
		elements,
		countBy( ( modification ) => modification.modifier.id ),
		mapValues( ( value ) => value === 1
			? 1
			: value / ( ( lineItem.unitQty ?? 1000 ) / 1000 ) ),
	);
}

async function syncClients( orders: any[] ) {
	const clientIds = pipe( orders,
		map( ( order ) => order.customers?.elements[ 0 ]?.id ),
		filter( Boolean ),
		uniq,
	);
	
	const dbClients = await queryGraphQL<QueryClientsReadArgs>( {
		query: gql`
			query ClientsRead_9383($options: FilterOptions) {
				clientsRead(options: $options) {
					items { externalId, id }
				}
			}
		`,
		variables: { options: { filter: { externalId: { $in: clientIds } } } },
	} )
		.then( ( { clientsRead } ) => keyBy( clientsRead.items, 'externalId' ) );
	
	const dbClientsSet = new Set<string>( Object.keys( dbClients ) );
	const missingClients = clientIds.filter( ( clientId ) => !dbClientsSet.has( clientId ) );
	if ( typeof window === 'undefined' ) { //TODO: separate client and server logic
		console.log( 'missing clients', missingClients );
		// TODO: fetch and write clients for API
	}
	
	return dbClients;
}

async function syncItems( lineItems: any[] ) {
	const itemExternalIds = pipe(
		lineItems,
		map( ( lineItem ) => lineItem?.item?.id ),
		filter( Boolean ),
		uniq,
	);
	if ( !itemExternalIds.length ) return {};
	
	return queryGraphQL( {
		query: gql`
			query ItemsRead_6d85($options: FilterOptions) {
				itemsRead(options: $options) {
					items { id, externalId, image }
				}
			}
		`,
		variables: { options: { filter: { externalId: { $in: itemExternalIds } } } },
	} )
		.then( ( { itemsRead } ) => keyBy( itemsRead.items, 'externalId' ) )
		.catch( ( e ) => {
			console.log( 'get Items error', e );
			return {};
		} );
}

async function syncCategories( gateway: GatewayBase, lineItems: any[] ) {
	if ( !lineItems.length ) return {};
	if ( !lineItems[ 0 ]._orderId ) {
		throw new Error( 'must have an internal order id' );
	}
	const categoryIds = new Set<string>();
	const categoryNames = new Set<string>();
	const categoryExternalIds = new Set<string>();
	const categoryLineItemIds = new Set<string>();
	
	lineItems
		.map( ( { _orderId, id, binName } ) => ( { _orderId, id, binName } ) )
		.filter( ( { binName } ) => !!binName && binName.includes( ':' ) )
		.forEach( ( { _orderId, id, binName } ) => {
			const [ categoryName, externalId ] = binName.split( ':' );
			if ( !externalId ) {
				categoryLineItemIds.add( id );
				return;
			}
			categoryNames.add( JSON.stringify( { _orderId, id, categoryName } ) );
			if ( validate( externalId ) ) {
				categoryIds.add( externalId );
			} else {
				categoryExternalIds.add( externalId );
			}
		} );
	
	// no need to await, ignore result:
	Promise.all( [ ...categoryNames ].map( ( jsonStr ) => {
		const { _orderId, id, categoryName } = JSON.parse( jsonStr );
		return axios.post( `${PLATFORM_ENDPOINT}/${gateway.externalId}/orders/${_orderId}/line_items/${id}`, {
			binName: categoryName,
		}, { headers: { Authorization: `Bearer ${gateway.externalKey}` } } );
	},
	) );
	
	const categoriesMap: { [ id: string ]: string } = {};
	
	const getCatsById = () => {
		if ( !categoryIds.size ) return;
		return queryGraphQL( {
			query: gql`
				query CategoriesRead_3b01($options: FilterOptions) {
					categoriesRead(options: $options) {
						items { id }
					}
				}
			`,
			variables: { options: { filter: { id: { $in: [ ...categoryIds ] } } } },
		} )
			.then( ( { categoriesRead } ) => categoriesRead.items.forEach( ( category ) => categoriesMap[ category.id ] = category.id ) )
			.catch( ( e ) => console.log( 'get Categories by id error', e ) );
	};
	
	const getCatsByExternalId = () => {
		if ( !categoryExternalIds.size ) return;
		return queryGraphQL( {
			query: gql`
				query CategoriesRead_cf48($options: FilterOptions) {
					categoriesRead(options: $options) {
						items { id, externalId }
					}
				}
			`,
			variables: { options: { filter: { externalId: { $in: [ ...categoryExternalIds ] } } } },
		} )
			.then( ( { categoriesRead } ) => categoriesRead.items?.forEach( ( category ) => categoriesMap[ category.externalId ] = category.id ) )
			.catch( ( e ) => console.log( 'get Category by external error', e ) );
	};
	
	const getCatsByLineItemId = () => {
		if ( !categoryLineItemIds.size ) return;
		return queryGraphQL( {
			query: gql`
				query LineItemsRead_1a90($options: FilterOptions) {
					lineItemsRead(options: $options) {
						items { externalId, category { id } }
					}
				}
			`,
			variables: { options: { filter: { externalId: { $in: [ ...categoryLineItemIds ] } } } },
		} )
			.then( ( { lineItemsRead } ) => lineItemsRead.items.forEach( ( lineItem ) => categoriesMap[ lineItem.externalId ] = lineItem.category?.id ) )
			.catch( ( e ) => console.log( 'get Category by lineItem id error', e ) );
	};
	
	await Promise.all( [ getCatsById(), getCatsByExternalId(), getCatsByLineItemId() ] );
	return categoriesMap;
}

async function syncModifierGroups( lineItems: CloverLineItem[], modifiers: CloverModifier[] ) {
	const modifierIds = new Set( lineItems
		.map( ( li ) => li?.modifications?.elements?.map( ( modification ) => modification.modifier.id ) )
		.filter( Boolean )
		.flat(),
	);
	
	const modifierGroupsById = pipe(
		modifiers,
		filter( ( { id } ) => modifierIds.has( id ) ),
		keyByFp( ( x ) => x.modifierGroup.id ),
	);
	
	const modifierGroupsExternalIds: string[] = Object.keys( modifierGroupsById );
	
	return queryGraphQL<QueryModifierGroupsReadArgs>( {
		query: gql`
			query ModifierGroupsRead_1da5($options: FilterOptions) {
				modifierGroupsRead(options: $options) { items { externalId, id } }
			}`,
		variables: { options: { filter: { externalId: { $in: modifierGroupsExternalIds } } } },
	} )
		.then( async ( { modifierGroupsRead } ) => {
			const existingModifierGroupMap = pipe(
				modifierGroupsRead.items,
				keyByFp( ( x ) => x.externalId ),
				mapValues( 'id' ),
			);
			const newModifierGroups = modifierGroupsExternalIds.filter( ( externalId ) => !existingModifierGroupMap[ externalId ] );
		
			return Promise.all(
				newModifierGroups.map( ( externalId ) =>
					mutateGraphQL<MutationModifierGroupWriteArgs>( {
						mutation: gql`
						mutation ModifierGroupWrite_70ff($input: ModifierGroupValidator!) {
							modifierGroupWrite(input: $input) {
								id
								externalId
							}
						}
					`,
						variables: {
							input: {
								externalId,
								name: modifierGroupsById[ externalId ].modifierGroup.name,
							},
						},
					} ),
				),
			).then( ( res ) => {
				res.forEach( ( { modifierGroupWrite } ) => {
					existingModifierGroupMap[ modifierGroupWrite.externalId ] = modifierGroupWrite.id;
				} );
				return existingModifierGroupMap;
			} );
		} );
}

async function getCloverModifiers( gateway: GatewayBase, modifierIds: string[] ): Promise<CloverModifier[]> {
	if ( !modifierIds.length ) return [];
	
	const { data: modifiers } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/getModifiers`, {
		id : gateway.id,
		ids: modifierIds,
	} );
	
	return modifiers?.data;
}

export async function convertCloverOrders(
	gateway: GatewayBase,
	locationId: string,
	orders: CloverOrder[] ) {
	if ( !orders.length ) return [];
	const lineItems = orders
		.map( ( order ) => order?.lineItems?.elements.map( ( item ) => ( { _orderId: order.id, ...item } ) ) )
		.flat()
		.filter( Boolean );
	
	const modifierIds = pipe(
		lineItems,
		map( ( li ) => li?.modifications?.elements?.map( ( modification ) => modification.modifier.id ) ),
		filter( Boolean ),
		flatten,
		uniq,
	);
	
	const modifiers = await getCloverModifiers( gateway, modifierIds );
	
	const [ clientMap, itemsMap, categoriesMap, modifierGroupsMap ] = await Promise.all( [
		syncClients( orders ),
		syncItems( lineItems ),
		syncCategories( gateway, lineItems ),
		syncModifierGroups( lineItems, modifiers ),
	] );
	
	return orders.map( ( order ) => convertCloverOrder(
		gateway.id,
		locationId,
		order,
		modifiers,
		clientMap,
		itemsMap,
		categoriesMap,
		modifierGroupsMap ) );
}

export function convertCloverOrder(
	gatewayId: string,
	locationId: string,
	order: CloverOrder,
	modifiers: CloverModifier[],
	clientMap: { [ externalId: string ]: Client },
	itemsMap: { [ externalId: string ]: Item },
	categoriesMap: { [ id: string ]: string },
	modifierGroupsMap: { [ externalId: string ]: string },
) {
	const client = clientMap[ order.customers?.elements[ 0 ]?.id ];
	const refundedPayments = isEmpty( order?.refunds?.elements )
		? []
		: order.refunds.elements.map( ( refund ) => ( {
			refundedAmount: refund.amount,
			amount        : refund.amount,
			tipAmount     : refund.payment.tipAmount,
			note          : refund.payment?.note,
			tender        : { label: refund.payment?.tender?.label },
			id            : refund.id,
			createdTime   : refund.createdTime,
		} ) );
	
	const lineItems = aggregateLineItems( order.lineItems?.elements )?.map( ( lineItem: CloverLineItem ) => {
		const lineItemId = lineItem?.alternateName?.split( ':' )?.[ 0 ];
		const hasUuid = validate( lineItemId );
		const lineItemItem = itemsMap[ lineItem.item?.id ];
		const itemId = lineItemItem?.id;
		const categoryExternalId = lineItem.binName?.split( ':' )?.[ 1 ];
		const category = categoriesMap[ categoryExternalId ] || categoriesMap[ lineItem.id ];
		const lineItemQuantity = ( lineItem.unitQty ?? 1000 ) / 1000;
		const multipleTaxes = lineItem.taxRates?.elements.filter( ( tax ) => tax?.name !== 'Tax (auto)' );
		const metadata = aggregateMetadata( lineItem );
		const modifierGroupsIds = pipe(
			modifiers,
			filter( ( modifier ) => !!metadata?.[ modifier.id ] ),
			map( ( modifier ) => modifier.modifierGroup.id ),
			uniq,
			map( ( modifierExternalId: string ) => modifierGroupsMap[ modifierExternalId ] ),
			filter( Boolean ),
			( ids ) => ids.length > 0 ? ids : undefined,
		);
		const discounts = lineItem?.discounts?.elements || [];
		
		if ( lineItem?.hasSameLineItem && !isEmpty( discounts ) ) {
			lineItem.discounts.elements = discounts.map( ( discount ) => {
				if ( discount?.percentage ) {
					return discount;
				} else {
					return { ...discount, amount: discount.amount *= lineItemQuantity };
				}
			} );
		}
		const uom = lineItemItem ? lineItemItem?.uoms?.find( ( uom ) => uom?.selected || !uom?.removed )?.id : undefined;
		
		return {
			item       : hasUuid ? undefined : itemId,
			uom        : hasUuid ? undefined : uom,
			externalId : lineItem.id,
			id         : hasUuid ? lineItemId : undefined,
			sequence   : hasUuid ? undefined : 5,
			name       : lineItem.name || '',
			price      : lineItem.price / 100,
			cost       : ( lineItem.item?.cost || 0 ) / 100 || undefined,
			quantity   : lineItemQuantity,
			unit       : lineItem.unitName ?? 'Unit',
			description: lineItem.note,
			code       : lineItem?.itemCode || '',
			isRevenue  : lineItem.isRevenue,
			category   : hasUuid ? undefined : category,
			binName    : !category ? lineItem?.binName : null,
			image      : hasUuid ? undefined : lineItemItem?.image || undefined,
			metadata   : { ...metadata, modifierGroupsIds, refunded: lineItem?.refunded } || undefined,
			prices     : [
				...lineItem?.discounts?.elements.map( ( discount ) => ( {
					name     : discount.name,
					isPercent: Boolean( discount.percentage ),
					value    : discount.amount / 100 / lineItemQuantity || -discount.percentage || 0,
				} ) ) || [],
				...!order.taxRemoved && !isEmpty( multipleTaxes ) ? multipleTaxes.map( ( tax ) => {
					const isPercent = tax?.rate > 0 ? true : tax?.taxAmount <= 0;
					return {
						name      : tax.name,
						externalId: null,
						metadata  : { externalId: tax.id, externalTax: true, isDefault: tax.isDefault, useTax: true },
						isPercent : isPercent,
						value     : ( tax?.taxAmount > 0
							? tax.taxAmount / 100 / lineItemQuantity
							: tax?.rate / 100000 ) || 0,
					};
				} ) : [],
			
			],
			tax: order.taxRemoved ? 0 : isEmpty( multipleTaxes )
				? lineItem.taxRates?.elements.find( ( tax ) => tax.name === 'Tax (auto)' )?.rate / 100000 || 0
				: 0,
		} as LineItemValidator;
	} );
	
	const getPayment = ( payment: Partial<CloverPayment> ) => ( {
		client: client?.id,
		...convertCloverPayment( gatewayId, payment ),
	} );
	
	return omitBy( {
		gateway        : gatewayId,
		externalId     : order.id,
		client         : client?.id || null,
		companyLocation: locationId,
		createdAt      : order.createdTime ? new Date( order.createdTime ) : null,
		updatedAt      : order.modifiedTime ? new Date( order.modifiedTime ) : null,
		syncDate       : order.modifiedTime ? new Date( order.modifiedTime ) : null,
		number         : order.id,
		title          : order.title,
		notes          : order.note,
		serviceType    : order.orderType?.label,
		overrideTotal  : order.total / 100 || null,
		prices         : [
			...order.serviceCharge ? [ {
				name     : order.serviceCharge.name,
				isPercent: true,
				value    : order.serviceCharge.percentageDecimal / 10000 || 0,
			} ] : [],
			...order?.discounts?.elements.map( ( discount ) => ( {
				name     : discount.name,
				isPercent: Boolean( discount.percentage ),
				value    : discount.amount / 100 || -discount.percentage || 0,
				metadata : { clientCreditId: discount.name === 'Credit' && client?.id },
			} ) ) || [],
		],
		lineItems,
		payments: [
			...!isEmpty( order?.payments?.elements ) ? order.payments.elements.map( getPayment ) : [],
			...!isEmpty( refundedPayments ) ? refundedPayments?.map( getPayment ) : [] ],
	}, isUndefined ) as OrderValidator;
}

export function convertCloverDevice( gatewayId: string, data ) {
	return {
		externalId : data.id,
		gateway    : gatewayId,
		productName: data.productName,
		serial     : data.serial,
		label      : data.name,
		staff      : data.staff,
	} as DeviceValidator;
}
