import Form from '@/components/form';
import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import LayoutAnimation from '@/components/framerAnimations/layoutAnimation';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModalControls } from '@/providers/modal';
import { ArrowBack as ArrowBackIcon, Close as CloseIcon } from '@mui/icons-material';
import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grow,
	IconButton,
	LinearProgress,
	Stack,
	Typography,
} from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { Fragment, useState } from 'react';
import applyTouchState from '../../../../../helpers/formikTouchAllFields';
import BankInfo, { bankInfoValidationSchema } from './bankInfo';
import CompanyInfo, { companyInfoValidationSchema } from './companyInfo';
import DemographicsInfo, { demographicsInfoValidationSchema } from './demographicsInfo';
import ExistingMerchant from './existingMerchant';
import InitialStep from './initialStep';
import { loadingTemplate } from './loadingTemplate';
import OwnerInfo, { ownerInfoValidationSchema } from './ownerInfo';
import { Merchant } from './types';

const validationSchemas = [
	companyInfoValidationSchema,
	demographicsInfoValidationSchema,
	ownerInfoValidationSchema,
	bankInfoValidationSchema,
];

export const formatPhoneNumber = ( phoneNumber ) => {
	if ( !phoneNumber ) return '';
	
	// Strip all non-numeric characters except the leading +
	const digits = phoneNumber.replace( /[^\d+]/g, '' );
	
	// Check if it starts with '+1' (US country code)
	if ( digits.startsWith( '+1' ) ) {
		const formatted = digits.slice( 2 ); // Remove the '+1'
		if ( formatted.length === 10 ) {
			// Format to XXX-XXX-XXXX
			return `${formatted.slice( 0, 3 )}-${formatted.slice( 3, 6 )}-${formatted.slice( 6 )}`;
		}
	} else if ( digits.startsWith( '1' ) ) {
		const formatted = digits.slice( 1 ); // Remove the '1'
		if ( formatted.length === 10 ) {
			return `${formatted.slice( 0, 3 )}-${formatted.slice( 3, 6 )}-${formatted.slice( 6 )}`;
		}
	}
	return phoneNumber;
};

const formatWebsiteAddress = ( website ) => {
	if ( !website ) return 'http://';
	return website.startsWith( 'http://' ) || website.startsWith( 'https://' ) ? website : `https://${website}`;
};

export default function CardConnectFormModal( { onSubmit }: { onSubmit?: () => void } ) {
	const { closeModal } = useModalControls();
	const { staff } = useUserInfo();
	const { enqueueSnackbar } = useSnackbar();
	
	const [ activeStep, setActiveStep ] = useState( 0 );
	const [ showClose, setShowClose ] = useState( false );
	
	if ( showClose ) {
		return (
			<Grow in>
				<Box
					className='center'
					sx={{
						p            : 2,
						height       : '100%',
						flexDirection: 'column',
						textAlign    : 'center',
					}}>
					<Typography variant='h3'>Close Form</Typography>
					<Typography color='text.secondary'>
						Are you sure you want to close this form? You will lose all progress.
					</Typography>
					<Stack spacing={1} my={2}>
						<Button size='large' onClick={() => closeModal()}>
							Yes, close form
						</Button>
						<Button size='large' variant='text' onClick={() => setShowClose( false )}>
							No, continue
						</Button>
					</Stack>
				</Box>
			</Grow>
		);
	}
	
	return (
		<Form<Merchant>
			validationSchema={validationSchemas[ activeStep - 1 ]}
			initialValues={{
				akaBusinessName  : staff?.company?.name || '',
				dbName           : staff?.company?.name || '',
				legalBusinessName: staff?.company?.name || '',
				taxFilingName    : staff?.company?.name || '',
				taxFilingMethod  : '',
				demographic      : {
					merchantTimeZone: 'ET',
					websiteAddress  : formatWebsiteAddress( staff?.company?.website ) || '',
					businessAddress : {
						address1 : '',
						city     : '',
						stateCd  : '',
						zip      : '',
						countryCd: 'US',
					},
					mailingAddress: {
						address1 : '',
						city     : '',
						stateCd  : '',
						zip      : '',
						countryCd: 'US',
					},
					sameAsBusinessAddress: false,
				},
				ownership: {
					owner: {
						ownerAddress: {
							address1 : '',
							city     : '',
							stateCd  : '',
							zip      : '',
							countryCd: 'US',
						},
						ownerEmail      : staff?.company?.email || '',
						ownerName       : staff?.company?.contact || staff?.user?.firstName + ' ' + staff?.user?.lastName || staff?.company?.name || '',
						ownerPhone      : formatPhoneNumber( staff?.company?.phone ) || '',
						ownerMobilePhone: formatPhoneNumber( staff?.company?.cell ) || '',
						ownerTitle      : 'OWNER',
					},
				},
				bankDetail: {
					depositBank: {
						bankAcctNum         : '',
						bankRoutingNum      : '',
						confirmAccountNumber: '',
						bankAcctTypeCd      : 'BIZ',
						bankName            : '',
					},
					withdrawalBank: {
						bankAcctNum         : '',
						bankRoutingNum      : '',
						confirmAccountNumber: '',
						bankAcctTypeCd      : 'BIZ',
						bankName            : '',
					},
				},
			} as Merchant}
			onSubmit={async ( values: Merchant ) => {
				try {
					const newTab = window.open( '', '_blank' );
					if ( newTab ) {
						newTab.document.write( loadingTemplate );
					}
					
					const { data } = await axios.post( '/api/processor/cardConnect/merchant/copilot', {
						...values,
						companyId: staff?.company?.id,
					} );
					
					console.log( 'data.url', data.url );
					if ( data.url && newTab ) {
						newTab.location.href = data.url;
						
						await axios.post( '/api/emails/cardConnectApplication', {
							companyName     : staff?.company.name,
							companyEmail    : staff?.company.email,
							applicantName   : values.ownership.owner.ownerName,
							applicantEmail  : values.ownership.owner.ownerEmail,
							applicantWebsite: values.demographic.websiteAddress,
						} );
					} else if ( data.info ) {
						enqueueSnackbar( data.info, { variant: 'info' } );
					}
					onSubmit?.();
				} catch ( e ) {
					console.log( e );
					enqueueSnackbar( 'Card Connect connection error', { variant: 'error' } );
				}
			}}>
			{( formik ) => (
				<Fragment>
					<DialogTitle>
						<Stack direction='row' alignItems='center' justifyContent='space-between'>
							<Stack>
								<Typography variant='h2'>
									Connect Bank
								</Typography>
								<Typography color='text.secondary' sx={{ mb: 2 }}>
									Add bank transfer option to your invoices.
								</Typography>
							</Stack>
							<IconButton onClick={() => setShowClose( true )}>
								<CloseIcon/>
							</IconButton>
						</Stack>
					</DialogTitle>
					<Box sx={{ mb: 1 }}>
						{activeStep !== 5 && (
							<LinearProgress
								variant='determinate'
								value={activeStep * 25}
								sx={{ height: 5, borderRadius: 0 }}
							/>
						)}
					</Box>
					<DialogContent sx={{ p: 0 }}>
						<Box p={2}>
							{activeStep !== 0 && (
								<Button
									variant='text'
									startIcon={<ArrowBackIcon/>}
									sx={{ alignSelf: 'start', mb: 2 }}
									onClick={() => {
										if ( activeStep === 5 ) {
											setActiveStep( 0 );
										} else {
											setActiveStep( activeStep - 1 );
										}
									}}>
									Back
								</Button>
							)}
							<LayoutAnimation key={activeStep} variant='fadeInRight'>
								{activeStep === 0 && <InitialStep setActiveStep={setActiveStep}/>}
								{activeStep === 1 && <CompanyInfo/>}
								{activeStep === 2 && <DemographicsInfo/>}
								{activeStep === 3 && <OwnerInfo/>}
								{activeStep === 4 && <BankInfo/>}
								{activeStep === 5 && <ExistingMerchant/>}
							</LayoutAnimation>
						</Box>
					</DialogContent>
					{activeStep !== 0 && activeStep !== 5 && (
						<DialogActions>
							<AsyncLoadingButton
								variant='contained'
								size='large'
								color='primary'
								sx={{ my: 2, width: '100%' }}
								onClick={async () => {
									const errors = await formik.validateForm();
									
									// Clear errors for mailingAddress if sameAsBusinessAddress is checked
									if ( formik.values.demographic.sameAsBusinessAddress && errors.demographic?.mailingAddress ) {
										const newErrors = { ...errors };
										delete newErrors.demographic.mailingAddress;
										formik.setErrors( newErrors );
									}
									
									const noErrors = Object.keys( errors ).length === 0;
									const validBusinessAddress = activeStep === 2 && formik.values.demographic.sameAsBusinessAddress && !errors.demographic?.businessAddress;
									
									if ( noErrors || validBusinessAddress ) {
										if ( activeStep === 4 ) {
											await formik.submitForm();
										} else {
											setActiveStep( activeStep + 1 );
										}
									} else {
										const touchedFields = applyTouchState( errors );
										formik.setTouched( touchedFields );
									}
								}}>
								{activeStep === 4 ? 'Submit' : 'Continue'}
							</AsyncLoadingButton>
						</DialogActions>
					)}
				</Fragment>
			)}
		</Form>
	);
}
