import Attachment from '@/components/fileUploading/attachment';
import PageSection from '@/components/page/section';
import SendAgreementToClientEmailModal from '@/modals/email/sendRawEmailModal';
import SendRawTextMessage from '@/modals/sentTextMessageModal';
import { useModal } from '@/providers/modal';
import type { Agreement, Client } from '@/types/schema';
import {
	ChatBubbleOutlineOutlined as ChatBubbleOutlineOutlinedIcon,
	ContentCopy as ContentCopyIcon,
	MailOutlineRounded as MailOutlineRoundedIcon,
} from '@mui/icons-material';
import { Button, Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AgreementDialog from './agreementDialog';

export default function AgreementSection( {
	agreements,
	layoutColor,
	client,
	showCommunicationButtons,
	invoiceId,
}: {
	agreements?: Agreement[],
	layoutColor?: string,
	client?: Client,
	showCommunicationButtons?: boolean,
	invoiceId?: string
} ) {
	const { t } = useTranslation();
	const { showModal } = useModal();
	const { enqueueSnackbar } = useSnackbar();
	const router = useRouter();
	
	useEffect( () => {
		if ( router.query?.agreementId ) {
			showModal( AgreementDialog, { variant: 'fullPageDialog' }, {
				id: router.query?.agreementId,
				client,
			} );
		}
		
	}, [ router.query?.agreementId ] );
	
	if ( !Boolean( agreements?.length ) ) return null;
	
	return (
		<PageSection
			primary={t( 'common:agreements' )}
			primaryTypographyProps={{ color: layoutColor }}>
			{agreements?.map( ( agreement, index ) => (
				<Fragment key={index}>
					<Stack direction='row' spacing={1} alignItems='center' sx={{ justifyContent: 'space-between' }}>
						<Typography
							className='ellipsisText5Line'
							sx={{
								px        : .8,
								color     : 'text.secondary',
								fontSize  : '12px !important',
								whiteSpace: 'pre-line',
							}}>
							{agreement.title}
						</Typography>
						<Stack direction='row' alignItems='center' spacing={1}>
							{agreement.signature && (
								<Attachment
									removeDownload
									src={agreement.signature}
									imageSX={{
										width    : 80,
										height   : 30,
										objectFit: 'cover',
									}}
								/>
							)}
							<Button
								color='primary'
								variant='text'
								onClick={() => showModal( AgreementDialog, { variant: 'fullPageDialog' }, {
									id: agreement.id,
									client,
								} )}>
								{agreement.requireSignature && !agreement.signature
									? t( 'commerce:read-sign-agreement' )
									: t( 'commerce:read-agreement' )}
							</Button>
							{showCommunicationButtons && (
								<Tooltip
									title={agreement.requireSignature && !agreement.signature
										? 'Copy link and send to your client to have them read and sign this agreement.'
										: 'Copy this link and send to your client to have them read this agreement.'}>
									<IconButton
										onClick={() => {
											navigator.clipboard.writeText( `${process.env.NEXT_PUBLIC_SITE_URL}/p/${invoiceId}/invoice?agreementId=${agreement.id}` );
											enqueueSnackbar( 'Link copied to clipboard.', { variant: 'success' } );
										}}>
										<ContentCopyIcon fontSize='small'/>
									</IconButton>
								</Tooltip>
							)}
							{showCommunicationButtons && (
								<Tooltip title='Text message this agreement to the client.'>
									<IconButton
										onClick={() => showModal( SendRawTextMessage, { maxWidth: 'xs' }, {
											number : client?.phone || client?.cell,
											message: `Hello${client?.name || client?.contact || ''}, Please review${agreement.requireSignature
												? ' and sign'
												: ''} the following agreement: "${agreement.title}". Click here to access the agreement: ${process.env.NEXT_PUBLIC_SITE_URL}/p/${invoiceId}/invoice?agreementId=${agreement.id}. Thank you for your attention to this matter.`,
										} )}>
										<ChatBubbleOutlineOutlinedIcon fontSize='small'/>
									</IconButton>
								</Tooltip>
							)}
							{showCommunicationButtons && (
								<Tooltip title='Email this agreement to the client.'>
									<IconButton
										onClick={() => showModal( SendAgreementToClientEmailModal, { maxWidth: 'xs' }, {
											email    : client?.email,
											client   : client,
											agreement: agreement,
											invoiceId: invoiceId,
										} )}>
										<MailOutlineRoundedIcon fontSize='small'/>
									</IconButton>
								</Tooltip>
							)}
						</Stack>
					</Stack>
					{index !== agreements.length - 1 && <Divider sx={{ my: .5 }}/>}
				</Fragment>
			) )}
		</PageSection>
	);
}
