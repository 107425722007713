import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import Loading from '@/components/loading';
import { useGraphQL } from '@/data';
import {
	ClientPrepaidCreditsTotal_PrepaidCreditQuery,
	ClientPrepaidCreditsTotal_PrepaidCreditQueryVariables,
} from '@/generated/graphql';
import currencyFormat from '@/helpers/currencyFormat';
import { gql } from '@apollo/client';
import { Stack, Tooltip, Typography } from '@mui/material';
import { useRouter } from 'next/router';

export default function PrePaidCreditTotal() {
	
	const router = useRouter();
	const { data, isLoading } = useGraphQL<ClientPrepaidCreditsTotal_PrepaidCreditQueryVariables, ClientPrepaidCreditsTotal_PrepaidCreditQuery>( {
		query: gql`
			query ClientPrepaidCreditsTotal_PrepaidCredit($ids: [String!]) {
				clientPrepaidCreditsTotal(ids: $ids){
					clientId
					locationId
					total
				}
			}
		`,
		queryKey: 'clientPrepaidCreditsTotal',
	}, { enabled: true, keepPreviousData: true, staleTime: 1000 * 60 * 2 } ); // 2 minutes
	
	const locationId = data?.clientPrepaidCreditsTotal?.locationId;
	const clientId = data?.clientPrepaidCreditsTotal?.clientId;
	if ( isLoading ) return <Loading/>;
	
	if ( !clientId || !locationId ) return null;
	
	return (
		<Tooltip title='Remaining Credits'>
			<AsyncLoadingButton
				variant='outlined'
				onClick={async () => {
					const filters = encodeURIComponent(
						Buffer.from( JSON.stringify( { prepayClientId: clientId } ) ).toString( 'base64' ),
					);
					await router.push( `/p/${locationId}/payment?a=${filters}` );
				}}>
				<Stack spacing={2} direction='row' alignItems='center'>
					<Typography fontWeight={500}>
						Credit:
					</Typography>
					<Typography sx={{ fontFamily: 'Credit Card !important', fontSize: 15 }}>
						{currencyFormat( data?.clientPrepaidCreditsTotal?.total )}
					</Typography>
				</Stack>
			</AsyncLoadingButton>
		</Tooltip>
	);
}
