import { Box, FormHelperText, TextField, TextFieldProps } from '@mui/material';
import { useField } from 'formik';
import { ReactNode } from 'react';
import ReactInputMask from 'react-input-mask';
import 'react-phone-input-2/lib/style.css';
import TextFieldInputLabel from '../inputLabel';

export default function FormMaskField( {
	name,
	label,
	tooltip,
	size,
	mask,
	textFieldProps,
	...props
}: {
	name: string,
	label?: string,
	tooltip?: ReactNode,
	mask?: string,
	textFieldProps?: TextFieldProps,
	size?: 'small' | 'medium'
} & ReactInputMask['props'] ) {
	const [ , { value = null, touched, error }, { setValue, setTouched } ] = useField( name );
	return (
		<Box sx={{ width: '100%' }}>
			<TextFieldInputLabel label={label} tooltip={tooltip}/>
			<ReactInputMask
				name={name}
				mask={mask}
				value={value}
				onChange={( value ) => setValue( value.target.value )}
				onBlur={() => setTouched( true )}
				{...props}>
				{/*@ts-ignore*/}
				{( inputProps ) => <TextField fullWidth size={size} {...textFieldProps} {...inputProps}/>}
			</ReactInputMask>
			{touched && Boolean( error ) && <FormHelperText error>{error}</FormHelperText>}
		</Box>
	);
}
