import TipTapStylesWrapper from '@/components/tiptap/stylesWrapper';
import TiptapToolbar from '@/components/tiptap/toolbar';
import { Box, BoxProps } from '@mui/material';
import Blockquote from '@tiptap/extension-blockquote';
import Code from '@tiptap/extension-code';
import { Color } from '@tiptap/extension-color';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';

import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useField } from 'formik';
import { useEffect } from 'react';
import { useDebounce } from 'rooks';

export default function RichTextField( {
	name,
	placeholder,
	boxProps,
	hasBorder,
	toolBarMaxToShow,
	maxHeight,
	minHeight,
}: {
	name: string,
	placeholder?: string,
	boxProps?: BoxProps,
	hasBorder?: boolean,
	toolBarMaxToShow?: number,
	maxHeight?: string | number,
	minHeight?: string | number
} ) {
	const [ field, , { setValue } ] = useField( name ) ?? [];
	
	const editor = useEditor( {
		extensions: [
			StarterKit,
			Underline,
			Code,
			Blockquote,
			HorizontalRule,
			Color,
			TextStyle,
			TextAlign.configure( {
				types: [ 'heading', 'paragraph' ],
			} ),
			Link.configure( {
				openOnClick    : true,
				autolink       : true,
				defaultProtocol: 'https',
			} ),
			Placeholder.configure( {
				placeholder: placeholder || 'Start typing...',
			} ),
		],
		content: field.value || '<p></p>',
	} );
	
	// Debounced version of the onChange handler with a lower debounce time
	const debouncedOnChange = useDebounce( setValue, 200 );
	
	// Listen for changes in the editor and trigger debounced parent update
	useEffect( () => {
		if ( editor ) {
			editor.on( 'update', () => {
				const htmlContent = editor.getHTML();
				debouncedOnChange( htmlContent ); // Debounce the update to parent
			} );
		}
		
		// Cleanup on unmount
		return () => {
			editor?.destroy();
		};
	}, [ editor, debouncedOnChange ] );
	
	// Ensure the editor updates when initialValue changes
	useEffect( () => {
		if ( editor && editor.getHTML() !== field.value ) {
			editor.commands.setContent( field.value || '<p></p>' );
		}
	}, [ field.value, editor ] );
	
	if ( !editor ) {
		return null;
	}
	
	return (
		<Box
			sx={{
				overflow     : 'hidden',
				display      : 'flex',
				flexDirection: 'column',
				flexGrow     : 1,
				p            : 1,
				bgcolor      : 'background.default',
				maxHeight    : maxHeight || undefined,
				minHeight    : minHeight || undefined,
				cursor       : 'text',
				...hasBorder ? {
					border      : '1px solid',
					borderRadius: 2,
					borderColor : 'divider',
					boxShadow   : 26,
				} : undefined,
				...boxProps?.sx,
			}}
			onClick={() => editor?.chain().focus().run()}>
			
			{/* Toolbar */}
			<TiptapToolbar editor={editor} toolBarMaxToShow={toolBarMaxToShow}/>
			
			{/* Editor content */}
			<Box sx={{ p: 1, mt: 1, height: '100%', flexGrow: 1 }}>
				<TipTapStylesWrapper>
					<EditorContent
						editor={editor}
						className='tiptap-editor'
						style={{ height: '100%', width: '100%' }}
					/>
				</TipTapStylesWrapper>
			</Box>
		</Box>
	);
}
