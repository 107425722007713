import { gql } from '@apollo/client';

export const ItemsSelectRead = gql`
	query ItemsRead_7461($options: FilterOptions) {
		itemsRead(options: $options) {
			items {
				id
				name
				image
				description
				taxable
				metadata
				removeUom
				externalId
				taxes {
					metadata
					isPercent
					name
					value
					externalId
					quantity
				}
				markupPrices {
					id
					name
					isPercent
					value
					metadata
				}
				categories {
					id
					name
				}
				uoms {
					id
					name
					removed
					cost
					markup
					sku
					quantity
					price
					code
					image
					min
					max
					selected
				}
				modifierGroups {
					id
					name
					min
					max
					sequence
					externalId
					modifiers {
						id
						name
						isPercent
						value
						quantity
						min
						max
						metadata
						externalId
					}
				}
				locations {
					name
					address {
						line1
					}
				}
				gateway {
					id
					external
					externalId
				}
			}
			count
		}
	}
`;

export const ItemSelectRead = gql`
	query ItemRead_66dd($id: String, $externalId: String, $externalValue: String, $name: String) {
		itemRead(id: $id, externalId: $externalId, externalValue: $externalValue, name: $name) {
			id
			name
			image
			description
			taxable
			metadata
			removeUom
			externalId
			noCommission
			taxes {
				metadata
				isPercent
				name
				value
				externalId
				quantity
			}
			markupPrices {
				id
				name
				isPercent
				value
				metadata
			}
			categories {
				id
				name
			}
			uoms {
				id
				name
				removed
				cost
				markup
				sku
				quantity
				price
				code
				image
				min
				max
				selected
			}
			locations {
				id
			}
			modifierGroups {
				id
				name
				min
				max
				sequence
				externalId
				modifiers {
					id
					name
					isPercent
					value
					quantity
					min
					max
					metadata
					externalId
				}
			}
		}
	}`;
export const ClientsSelectRead = gql`
	query ClientsRead_ac81($options: FilterOptions) {
		clientsRead(options: $options) {
			items {
				id
				contact
				name
				email
				phone
				externalValue
				logo
				metadata
				gateway {
					externalId
				}
			}
		}
	}
`;

export const ClientSelectRead = gql`
	query ClientRead_bc8b($id: String, $externalId: String, $externalValue: String) {
		clientRead(id: $id, externalId: $externalId, externalValue: $externalValue) {
			id
			name
			contact
			email
			phone
			cell
			logo
			terms
			metadata
			statement
			externalId
			discounts {
				id
				name
				value
				isPercent
				quantity
			}
			houseAccounts {
				id
				invoiceDates
			}
			clientCredits {
				id
				amount
				reason
				expiration
				selected
				remaining
			}
			addresses {
				id
				line1
				line2
				city
				state
				country
				postalCode
				lat
				lng
			}
			gateway {
				external
				externalId
			}
		}}`;

export const CategoriesSelectRead = gql`
	query CategoriesRead_f1e4($options: FilterOptions) {
		categoriesRead(options: $options) {
			items {
				id
				name
				itemsCount
				clients {
					id
				}
			}
		}
	}`;

export const CategoriesReadForMSRP = gql`
	query CategoriesRead_cc12($options: FilterOptions) {
		categoriesRead(options: $options) {
			items {
				id
				name
				clients {
					id
					name
					contact
				}
				items {
					id
					name
					uoms {
						id
						price
						selected
					}
				}
			}
		}
	}`;

export const LineItemsSelectRead = gql`
	query LineItemsRead_0dee($options: FilterOptions) {
		lineItemsRead(options: $options) {
			items {
				id # none
				image
				name
				description # none
				price
				quantity
				orderTax
				unit
				status
				code
				sequence
				externalId
				externalValue
				markup
				isRevenue
				cost
				receivedQuantity
				metadata
				item {
					id
					taxable
					name
					removeUom
					taxes {
						id
						name
						value
						isPercent
						externalId
						quantity
						externalId
						metadata
					}
					metadata
					uoms {
						id
						quantity
						selected
						code
						cost
					}
				}
				order {
					number
					type
					metadata
					externalId
					company {
						name
					}
					client {
						name
						contact
					}
					serviceDate
					createdAt
					updatedAt
					paidTotal
				}
				purchase {
					id
					number
					createdAt
					updatedAt
					status
					serviceDate
					company {
						name
					}
					menu {
						name
						vendorName
					}
				}
				category {
					id
					name
					description
				}
				uom {
					id
					name
					price
					cost
					sku
					quantity
					vendorSku
					code
					markup
				}
				prices {
					id
					name
					isPercent
					value
					quantity
					metadata
				}
				modifierGroups {
					id
					name
				}
				tax
			}
			count
		}
	}`;

export const PurchasesSelect = gql`
	query PurchasesRead_prp1($options: FilterOptions) {
		purchasesRead(options: $options) {
			items {
				id
				number
				metadata
				menu {
					vendorName
					vendorContact
				}
				grandTotal
			}
		}
	}
`;
