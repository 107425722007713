import { Location, Store, StoreType } from '@/types/schema';
import { getBrowserTimezone } from '@/utils/timezone';
import { addDays, subHours } from 'date-fns';
import safeDateFns from '../../../../helpers/safeFormat';

interface StoreHoursAvailability {
	selectedDateMinTime?: Date,
	selectedDateMaxTime?: Date,
	open: boolean,
	availabilityMessage: string,
	shouldDisableTime?: ( value: Date ) => boolean,
	nextAvailableDateAndTime?: Date | null // New field added
}

export function getToDate( date: any, dateTz: Date ) {
	return safeDateFns.set( new Date( date ), {
		year : dateTz.getFullYear(),
		month: dateTz.getMonth(),
		date : dateTz.getDate(),
	} );
}

export function getFromDate( date: any, dateTz: Date ) {
	return safeDateFns.set( new Date( date ), {
		year : dateTz.getFullYear(),
		month: dateTz.getMonth(),
		date : dateTz.getDate(),
	} );
}

// store leadTime is only relevant for the current day, and is calculated based on pick up or delivery.
// if the leadTime plus the current times falls on a closed dateTime, then the minTime should be set to the next available date/time.
// storeMinTime for today is always starting from the current time + leadTime
// calendar should disable all dates before storeMinTime

export default function getStoreHoursAvailability(
	store: Store,
	selectedDate: Date | null,
	reduxStoreType: StoreType | undefined,
	atomStoreLocation: Location | null | undefined,
): StoreHoursAvailability {
	
	if ( !store ) return {
		selectedDateMinTime: undefined,
		selectedDateMaxTime: undefined,
		open               : false,
		availabilityMessage: '',
	};
	
	// Determine timezone based on reduxStoreType or use Intl as fallback
	const timezone = ( reduxStoreType === 'PICKUP'
		? atomStoreLocation?.timezone
		: store.location?.timezone ) || getBrowserTimezone();
	
	// Adjust effectiveDate to the correct timezone
	const dateTz = safeDateFns.utcToZonedTime( selectedDate || new Date(), timezone );
	
	const dayOfWeek = safeDateFns.format( dateTz, 'eeee' ).toLowerCase();
	const dayWithFromAndTo = store.hours?.[ dayOfWeek ];
	
	const from = getFromDate( dayWithFromAndTo?.from, dateTz );
	const to = getToDate( dayWithFromAndTo?.to, dateTz );
	
	// Check if current time is within operating hours
	const open = safeDateFns.isAfter( dateTz, subHours( from, 1 ) ) && safeDateFns.isAfter( to, dateTz );
	const displayFromTime = safeDateFns.format( from, 'h:mm a' );
	const displayToTime = safeDateFns.format( to, 'h:mm a' );
	
	let availabilityMessage = '';
	if ( open || safeDateFns.isBefore( dateTz, from ) || safeDateFns.isAfter( dateTz, to ) ) {
		availabilityMessage = `${safeDateFns.format( dateTz, 'EEEE' )}'s operating hours are from ${displayFromTime} to ${displayToTime}.`;
	} else {
		// This covers any other scenario, which should theoretically not be reached given the current logic
		availabilityMessage = `${safeDateFns.format( dateTz, 'EEEE' )}'s are closed.`;
	}
	
	return {
		selectedDateMinTime: from || undefined,
		selectedDateMaxTime: safeDateFns.subHours( to, 1 ) || undefined,
		open               : open || false,
		availabilityMessage,
	};
}

const roundToClosestNextMinutes = ( dateTime: number, mins = 5 ) => {
	const ms = 60000 * mins; // minutes in milliseconds
	return new Date( Math.floor( ( dateTime + ms - 1 ) / ms ) * ms );
};

// Helper function to find the next available date and time within store hours
export const findNextAvailableTime = ( store: Store, date: Date ) => {
	let currentDate = new Date( date?.getTime() || new Date().getTime() );
	for ( let i = 0; i < 7; i++ ) {
		const currentDayOfWeek = safeDateFns.format( currentDate, 'eeee' ).toLowerCase();
		const currentDayWithFromAndTo = store.hours?.[ currentDayOfWeek ];
		
		if ( currentDayWithFromAndTo ) {
			const from = getFromDate( currentDayWithFromAndTo.from, currentDate );
			const to = getToDate( currentDayWithFromAndTo.to, currentDate );
			
			if ( safeDateFns.isAfter( currentDate, subHours( from, 1 ) ) && safeDateFns.isBefore( currentDate, to ) ) {
				return currentDate;
			} else if ( safeDateFns.isBefore( currentDate, from ) ) {
				return from;
			}
		}
		// Move to the next day if the current day is not available
		currentDate = addDays( new Date( date?.getTime() ), i + 1 );
		currentDate.setHours( 0, 0, 0, 0 ); // Reset to the beginning of the next day
	}
	return undefined;
};

export function getGlobalMinTime(
	store: Store,
	reduxStoreType: StoreType,
	reduxStoreAddress: Location,
) {
	const timezone = ( reduxStoreType === 'PICKUP'
		? reduxStoreAddress?.timezone
		: store.location?.timezone ) || getBrowserTimezone();
	
	const currentDateTime = safeDateFns.utcToZonedTime( new Date(), timezone );
	const leadTimeInSeconds = store.leadTime?.[ reduxStoreType ] || 0;
	
	const dateWithLeadTime = roundToClosestNextMinutes(
		safeDateFns.setSeconds( currentDateTime, 0 ).getTime() + ( leadTimeInSeconds + 300 ) * 1000,
		5,
	);
	
	return findNextAvailableTime( store, dateWithLeadTime );
}

export function everyDayIsClosed( store: { hours: { from: Date, to: Date }[] } ) {
	return Object.values( store.hours as { from: Date, to: Date }[] ).every( ( day ) => !day.from && !day.to );
}
