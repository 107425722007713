import Form from '@/components/form';
import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import FormSelect from '@/components/form/fields/select';
import FormTextField from '@/components/form/fields/textField';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import { Close as CloseIcon } from '@mui/icons-material';
import { Box, DialogContent, DialogTitle, IconButton, MenuItem, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import React, { Fragment } from 'react';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape( {
	firstName   : Yup.string().required( 'First name is required' ),
	lastName    : Yup.string().required( 'Last name is required' ),
	emailAddress: Yup.string().email( 'Invalid email' ).required( 'Email is required' ),
	companyName : Yup.string()
		.matches( /^\S+$/, 'Name must not contain spaces' )
		.required( 'Business Name is required' ),
	businessName: Yup.string().required( 'Business Name is required' ),
	currency    : Yup.string().required( 'Currency is required' ),
	webSite     : Yup.string().matches( /^(?!https:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/, 'Invalid URL' ),
	comments    : Yup.string().max( 500, 'Comments must be less than 500 characters' ),
} );

export default function ConnectVoPayFormModal() {
	const router = useRouter();
	const { closeModal } = useModal();
	const { enqueueSnackbar } = useSnackbar();
	const { user, staff } = useUserInfo();
	
	return (
		<Fragment>
			<DialogTitle>
				<Stack direction='row' alignItems='center' justifyContent='space-between'>
					<Stack>
						<Typography variant='h2' sx={{ mb: 2 }}>Activate EFT</Typography>
						<Typography color='text.secondary'>Submit your application, we will get back to you soon.
						</Typography>
					</Stack>
					<IconButton onClick={() => closeModal( 'vopay-connect-modal' )}><CloseIcon/></IconButton>
				</Stack>
			</DialogTitle>
			<DialogContent sx={{ p: 2 }}>
				<Box mt={2}>
					<Form
						initialValues={{
							firstName    : ( user?.firstName || '' ).trim(),
							lastName     : ( user?.lastName || '' ).trim(),
							emailAddress : staff?.company?.email || user?.email || '',
							companyName  : ( staff?.company?.name || '' ).trim().replaceAll( ' ', '_' ),
							businessName : '',
							currency     : 'CAD',
							webSite      : '',
							businessModel: '',
							comments     : '',
						}}
						validationSchema={validationSchema}
						onSubmit={async ( values ) => {
							try {
								await axios.post( '/api/user/vopay/createLeadIntent', {
									...values,
									companyId: staff?.company?.id,
								} );
								enqueueSnackbar( 'We\'ve received your request. VoPay will contact you shortly.', { variant: 'success' } );
								closeModal();
								await router.push( '/dashboard/settings/gateway?tab=connected-gateways' );
							} catch ( e ) {
								console.log( e );
								enqueueSnackbar( e?.response?.data.message || 'Error creating client account', { variant: 'error' } );
							}
						}}>
						{( formik ) => (
							<Box display='flex' flexDirection='column' gap={1}>
								<FormTextField fullWidth name='firstName' label='First Name'/>
								<FormTextField fullWidth name='lastName' label='Last Name'/>
								<FormTextField fullWidth name='emailAddress' label='Business Email Address'/>
								<FormTextField fullWidth name='companyName' label='Company Name'/>
								<FormTextField fullWidth name='businessName' label='Doing Business Name'/>
								<FormSelect
									fullWidth
									name='currency'
									label='Currency'>
									<MenuItem value='CAD'>CAD</MenuItem>
									<MenuItem value='USD'>USD</MenuItem>
								</FormSelect>
								<FormTextField fullWidth name='webSite' label='Website'/>
								<FormTextField fullWidth name='businessModel' label='Business Model'/>
								<FormTextField fullWidth name='comments' label='Comments'/>
								<AsyncLoadingButton
									variant='contained'
									color='primary'
									sx={{ my: 2 }}
									onClick={async () => formik.submitForm()}>
									Submit
								</AsyncLoadingButton>
							</Box>
						)}
					</Form>
				</Box>
			</DialogContent>
		</Fragment>
	);
};

