import Attachment from '@/components/fileUploading/attachment';
import PageSection from '@/components/page/section';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Box, Chip, Stack } from '@mui/material';
import Link from 'next/link';

export default function Attachments( {
	attachments,
	layoutColor,
	isPdf,
}: {
	attachments: string[],
	layoutColor?: string,
	isPdf?: boolean
} ) {
	if ( !Boolean( attachments?.length ) ) return null;
	
	const getFileExtension = ( url: string ) => {
		const extension = url.split( '.' ).pop()?.split( '?' )[ 0 ] || '';
		return extension.toUpperCase();
	};

	const getFileIcon = ( extension: string ) => {
		switch ( extension ) {
			case 'PDF':
				return <PictureAsPdfIcon/>;
			case 'PNG':
			case 'JPG':
			case 'JPEG':
			case 'GIF':
			case 'WEBP':
				return <ImageIcon/>;
			default:
				return <InsertDriveFileIcon/>;
		}
	};
	
	return (
		<PageSection
			primary='Attachments'
			primaryTypographyProps={{ variant: 'h5', color: layoutColor }}>
			{isPdf ? (
				<Stack spacing={1.5}>
					{attachments?.map( ( attachment, index ) => {
						const extension = getFileExtension( attachment );
						return (
							<Box key={`${attachment}-${index}`} sx={{ display: 'flex', alignItems: 'center' }}>
								<Box sx={{ width: '80px' }}>
									<Chip
										size='small'
										icon={getFileIcon( extension )}
										label={extension}
										color='default'
										variant='alpha'
									/>
								</Box>
								<Box component='span' sx={{ color: 'text.secondary', width: '40px' }}>
									{index + 1}.
								</Box>
								<Link
									href={attachment}
									target='_blank'
									rel='noopener noreferrer'>
									<span style={{ cursor: 'pointer' }}>
										{`Attachment ${index + 1}`}
									</span>
								</Link>
							</Box>
						);
					} )}
				</Stack>
			) : (
				<Box sx={{ display: 'flex', flexWrap: 'wrap', p: 1 }}>
					{attachments?.map( ( attachment, index ) => (
						<Attachment
							key={index}
							src={attachment}
							imageSX={{ objectFit: 'cover' }}
							sx={{ mr: 1, mb: 1 }}
						/>
					) )}
				</Box>
			)}
		</PageSection>
	);
}
