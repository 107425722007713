import { useCompany } from '@/hooks/useSetCompanyInAtom';
import { stayOpen } from '@/pages/dashboard/commerce/components/tableHelpers';
import CardConnectFormModal from '@/pages/dashboard/settings/gateway/cardConnectForm';
import { useModal } from '@/providers/modal';
import { dismissibleBannerAtoms } from '@/utils/atoms';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import { Button, Stack, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { lowerCase } from 'lodash-es';
import { useRouter } from 'next/router';
import GlobalBannerWrapper from './wrapper';

export default function CardConnectBanner() {
	const { company } = useCompany();
	const { showModal } = useModal();
	const router = useRouter();
	const [ globalBanners, setGlobalBanners ] = useAtom( dismissibleBannerAtoms );
	
	const urlParam = new URLSearchParams( router.asPath.split( '?' )[ 1 ] );
	const devFlag = urlParam.has( 'cardConnectBanner' );
	const hasCardConnect = company?.gateways?.some( ( g ) => g.external === 'CARD_CONNECT' );
	const hasUsdGateway = company?.gateways?.some( ( g ) => g.currency === 'USD' );
	const hasAchGateway = company?.gateways?.some( ( gateway ) => gateway.paymentMethod?.includes( 'ACH' ) );
	
	const hasUsAddress = company?.locations
		?.map( ( l ) => l.address )
		?.some( ( addr ) => [ 'united states', 'us', 'usa' ].includes( lowerCase( addr.country ) ) );
	
	return (
		<GlobalBannerWrapper
			show={Boolean( devFlag || !hasAchGateway && !hasCardConnect && ( hasUsAddress || hasUsdGateway ) ) && !globalBanners.hideCardConnectBanner}
			onClose={() => setGlobalBanners( ( prev ) => ( { ...prev, hideCardConnectBanner: true } ) )}>
			<Stack
				spacing={1}
				direction={{ xs: 'column', lg: 'row' }}
				alignItems='center'
				justifyContent='center'>
				<Typography variant='h5' sx={{ textAlign: 'center', fontWeight: 'normal', color: 'primary.main' }}>
					Save money! Enable bank transfer (ACH) payments for your business! Let your customers pay via bank
					account.
				</Typography>
				<Button
					variant='outlined'
					color='primary'
					startIcon={<AccountBalanceRoundedIcon/>}
					onClick={() => {
						showModal( CardConnectFormModal, {
							onClose      : ( event, reason ) => stayOpen( event, reason ),
							maxWidth     : 'xs',
							fullPageModal: true,
							sx           : {
								'.MuiDialog-paper': {
									overflow: 'hidden !important',
								},
							},
						}, {
							onSubmit: () => {
								console.log( 'submitted' );
								router.push( '/dashboard/settings/gateway?tab=connected-gateways' );
							},
						} );
					}}>
					Connect Bank
				</Button>
			</Stack>
		</GlobalBannerWrapper>
	);
}
