import Form from '@/components/form';
import FormAddress, { useAddressValidationSchema } from '@/components/form/fields/address';
import FormGraphQLAutocomplete from '@/components/form/fields/formGraphQLAutocomplete';
import { ModalFormWrapper } from '@/components/form/modal';
import GoogleMapComp from '@/components/googleMap';
import LargeChip from '@/components/largeChip';
import { calculateDistance } from '@/pages/dashboard/commerce/components/tableHelpers';
import { useGetStoreAtom, useStorePublicRead, useUpdateOnlineStoreAtom } from '@/pages/p/store/context';
import getStoreHoursAvailability from '@/pages/p/store/utils/storeAvilibility';
import { days } from '@/pages/p/store/utils/storeHours';
import { useModalControls } from '@/providers/modal';
import type { Store, StorePublic, StoreType } from '@/types/schema';
import { gql } from '@apollo/client';
import { StoreMallDirectoryRounded as StoreMallDirectoryRoundedIcon } from '@mui/icons-material';
import {
	Box,
	Collapse,
	Divider,
	FormControlLabel,
	Grid,
	ListItem,
	ListItemText,
	MenuItem,
	Radio,
	RadioGroup,
	Stack,
	Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { capitalize, toLower, upperFirst } from 'lodash-es';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { Fragment, useState } from 'react';
import { storeOrderTypes } from './utils/storeOrderTypes';

export default function AddressFormModal( { deliveryOnly }: { deliveryOnly?: boolean } ) {
	const { store } = useStorePublicRead();
	const router = useRouter();
	const { enqueueSnackbar } = useSnackbar();
	const setOnlineStoreAtom = useUpdateOnlineStoreAtom();
	const { closeModal } = useModalControls();
	const validationSchema = useAddressValidationSchema( 'customerAddress' );
	
	const { atomCustomerAddress, atomStoreType, atomStoreLocation } = useGetStoreAtom();
	
	const [ storeTypeLocally, setStoreTypeLocally ] = useState<StoreType>( atomStoreType || store.type[ 0 ] || 'DELIVERY' );
	const { availabilityMessage } = getStoreHoursAvailability( store, new Date(), atomStoreType, atomStoreLocation );
	
	// Needed because the modal is outside the store null check
	if ( !store ) return null;
	
	return (
		<Form
			noClose
			validationSchema={storeTypeLocally === 'DELIVERY' && validationSchema}
			initialValues={{
				store          : store,
				customerAddress: {
					line1     : atomCustomerAddress?.line1 || '',
					line2     : atomCustomerAddress?.line2 || '',
					city      : atomCustomerAddress?.city || '',
					state     : atomCustomerAddress?.state || '',
					postalCode: atomCustomerAddress?.postalCode || '',
					country   : atomCustomerAddress?.country || '',
					lat       : atomCustomerAddress?.lat || null,
					lng       : atomCustomerAddress?.lng || null,
				},
			}}
			onSubmit={async ( values ) => {
				try {
					// PICK UP
					if ( storeTypeLocally === 'PICKUP' ) {
						setOnlineStoreAtom( { atomStoreType: storeTypeLocally } );
						if ( values.store?.location?.id ) {
							closeModal();
							await router.replace( `/p/${values.store?.id}/store` );
							setOnlineStoreAtom( { atomStoreLocation: values.store?.location } );
						}
					} else if ( storeTypeLocally === 'DELIVERY' || deliveryOnly ) {
						// DELIVERY
						if ( !values.customerAddress?.lat || !values.customerAddress?.lng ) {
							enqueueSnackbar( 'Please select an address from the options', { variant: 'error' } );
							return;
						}
						const clientAddressInMiles = calculateDistance(
							values.customerAddress.lat,
							values.customerAddress.lng,
							values.store?.location?.address?.lat,
							values.store?.location?.address?.lng,
						) / 1.60934;
						if ( values.store?.location?.address?.lat && clientAddressInMiles > store.radius ) {
							enqueueSnackbar( 'Sorry, the store doesn\'t deliver to the address you just entered. Choose an address within the store\'s accepted zone.', { variant: 'info' } );
						} else {
							setOnlineStoreAtom( { atomStoreType: 'DELIVERY' } );
							setOnlineStoreAtom( { atomCustomerAddress: values.customerAddress } );
							closeModal();
						}
					}
				} catch ( e ) {
					console.log( e );
				}
			}}>
			<ModalFormWrapper title={deliveryOnly ? 'Delivery Address' : 'Pickup Or Delivery'}>
				<Stack spacing={1}>
					<GoogleMapComp
						address={atomStoreLocation?.address || store.location?.address}
						radius={store.radius}
						customMarker={( <StoreMallDirectoryRoundedIcon sx={{ fontSize: 20 }}/> )}
					/>
					{!deliveryOnly && (
						<Box mt={2}>
							{store.type?.length === 1 ? (
								// Tell the user the store only supports either Delivery or Pickup
								<Typography variant='h5'>
									This store only supports {capitalize( store.type[ 0 ] )}
								</Typography>
							) : (
								<RadioGroup
									sx={{ width: '30%' }}
									value={storeTypeLocally}
									onChange={( e ) => setStoreTypeLocally( e.target.value as StoreType )}>
									{storeOrderTypes.map( ( type ) => (
										<FormControlLabel
											key={type}
											value={type}
											control={<Radio/>}
											label={upperFirst( toLower( type ) )}
										/>
									) )}
								</RadioGroup>
							)}
						</Box>
					)}
					<Collapse in={storeTypeLocally === 'PICKUP'}>
						<Grid container spacing={2} pt={0}>
							<Grid item xs={12} sx={{ mt: 0.5 }}>
								<Box>
									<Typography
										gutterBottom
										color='text.secondary'>{availabilityMessage || 'Closed'}
									</Typography>
									<Box>
										{days.map( ( day, index ) => {
											const openingTime = new Date( store.hours?.[ day ]?.from );
											const closingTime = new Date( store.hours?.[ day ]?.to );
											return (
												<ListItem key={index} sx={{ p: { xs: 1, sm: 0 } }}>
													<ListItemText
														primary={day}
														primaryTypographyProps={{
															color        : 'text.secondary',
															textTransform: 'capitalize',
														}}
													/>
													<Stack
														width={150}
														direction='row'
														justifyContent='space-between'
														alignItems='center'>
														<Typography width={78} textAlign='left'>
															{+openingTime && format( openingTime, 'h:mm a' ) || ''}
														</Typography>
														<Typography width={2}>{!isNaN( closingTime.getTime() ) && '-'}</Typography>
														<Typography width={78} textAlign='right'>
															{+closingTime && format( closingTime, 'h:mm a' ) || 'Closed'}
														</Typography>
													</Stack>
												</ListItem>
											);
										} )}
									</Box>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<FormGraphQLAutocomplete<StorePublic>
									name='store'
									queryKey={[ 'stores' ]}
									query={gql`
										query StoresPublicRead($options: FilterOptions) {
											storesPublicRead(options: $options) {
												items {
													name
													id
													location {
														id
														name
														address {
															line1
														}
													}
												}
												count
											}
										}
									`}
									variables={{
										options: {
											filter: {
												company : store?.company?.id,
												location: { $ne: null },
											},
										},
									}}
									getOptionLabel={( store: Store ) => store.location?.name || store.location?.address?.line1 || '-'}
									renderOption={( props, store ) => (
										<Fragment key={store.id}>
											<MenuItem {...props} selected={store?.id === router.query.id}>
												<ListItemText
													primaryTypographyProps={{ fontWeight: '500' }}
													primary={store.name}
													secondary={store.location?.name || store.location?.address?.line1}
												/>
											</MenuItem>
											<Divider sx={{ my: 1 }}/>
										</Fragment>
									)}
									textFieldProps={{
										label     : 'Store Pick Up Location',
										helperText: 'Changing the pick up location will clear your cart',
									}}
								/>
							</Grid>
						</Grid>
					</Collapse>
					<Collapse in={storeTypeLocally === 'DELIVERY' && store.type.includes( 'DELIVERY' )}>
						<Box my={2}>
							{store?.radius >= 1 && (
								<LargeChip
									color='warning'
									label={`This store only supports a ${store?.radius} mile radius from their primary location`}
								/>
							)}
						</Box>
						<FormAddress
							name='customerAddress'
							gridBreakpoints={{
								city      : { sm: 12, lg: 12 },
								state     : { sm: 12, lg: 12 },
								country   : { sm: 12, lg: 12 },
								postalCode: { sm: 12, lg: 12 },
							}}
						/>
					</Collapse>
				</Stack>
			</ModalFormWrapper>
		</Form>
	);
}
