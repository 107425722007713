import PageLink from '@/components/page/link';
import StyledImage from '@/components/styledImage';
import { Box, Grid, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { Fragment, ReactNode } from 'react';

export function EmailFooter( { commercial, footerNotes }: { commercial?: boolean, footerNotes?: ReactNode } ) {
	const theme = useTheme();
	const footerLogo = (
		<Box>
			<Stack direction='row' alignItems='center' spacing={.5}>
				<Typography color='text.secondary' fontWeight='500' pb={.5}>Powered By</Typography>
				<PageLink href='https://www.invoiss.com/' underline='hover'>
					<StyledImage
						width={80}
						sx={{
							'transition': '.2s',
							':hover'    : { cursor: 'pointer', opacity: 1 },
							'opacity'   : .8,
						}}
						src='https://www.invoiss.info/assets/images/invoiss-logoblack.png'
						alt='Invoiss logo'
					/>
				</PageLink>
			</Stack>
			<Stack direction='row' alignItems='center' spacing={1}>
				<PageLink href='https://www.invoiss.com/terms' target='_blank' underline='hover'>
					<Typography
						style={{ fontSize: '12px' }}
						color='text.secondary'
						fontWeight='500'>
						Terms
					</Typography>
				</PageLink>
				<Typography>|</Typography>
				<PageLink href='https://www.invoiss.com/privacy' target='_blank' underline='hover'>
					<Typography
						style={{ fontSize: '12px' }}
						color='text.secondary'
						fontWeight='500'>
						Privacy
					</Typography>
				</PageLink>
			</Stack>
		</Box>
	);
	
	const footerSocial = (
		<Fragment>
			<IconButton sx={{ mr: 1, p: 0 }} component='a' href='https://www.instagram.com/invoiss.app/'>
				<StyledImage
					sx={{ width: '24px', height: '20px' }}
					alt='instagram-logo'
					// src={`${ process.env.NEXT_PUBLIC_SERVER_URL }/images/invoiss-logoblack.png`}
					src='https://www.invoiss.info/assets/images/instagram.png'
				/>
			</IconButton>
			<IconButton sx={{ p: 0 }} component='a' href='https://www.linkedin.com/company/invoiss'>
				<StyledImage
					sx={{ width: '24px', height: '20px' }}
					alt='instagram-logo'
					// src={`${ process.env.NEXT_PUBLIC_SERVER_URL }/images/invoiss-logoblack.png`}
					src='https://www.invoiss.info/assets/images/linkedin.png'
				/>
			</IconButton>
		</Fragment>
	);
	
	const footerLinks = (
		<Grid
			sx={{
				'.MuiTypography-root'      : {
					color         : 'text.secondary',
					transition    : '.2s',
					textDecoration: 'none',
				},
				'.MuiTypography-root:hover': {
					color : '#000000',
					cursor: 'pointer',
				},
			}}>
			<Grid container spacing={1}>
				<Grid item>
					<Typography component='a' href={`${process.env.NEXT_PUBLIC_SITE_URL}/help`}>
						Help
					</Typography>
				</Grid>
				<Grid item>
					<Typography component='a' href={`${process.env.NEXT_PUBLIC_SITE_URL}/contact`}>
						Contact
					</Typography>
				</Grid>
				<Grid item>
					<Typography component='a' href={`${process.env.NEXT_PUBLIC_SITE_URL}/privacy`}>
						Privacy
					</Typography>
				</Grid>
				<Grid item>
					<Typography component='a' href={`${process.env.NEXT_PUBLIC_SITE_URL}/terms`}>
						Terms
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
	
	if ( commercial ) {
		return (
			<Box sx={{ bgcolor: 'divider', p: { xs: 2, md: 5 }, height: '100%' }}>
				<Box>{footerSocial}</Box>
				<Box mt={3}>{footerLinks}</Box>
				{footerNotes && (
					<Box mt={3} sx={{ color: 'text.secondary', fontSize: 10, textAlign: 'justify' }}>
						{footerNotes}
					</Box>
				)}
				<Box mt={3}>{footerLogo}</Box>
			</Box>
		);
	}
	
	return (
		<Box sx={{ bgcolor: theme.palette.divider, p: { xs: 2, md: 5 }, height: '100%' }}>
			{footerNotes && (
				<Box mb={3} sx={{ color: 'text.secondary' }}>
					{footerNotes}
				</Box>
			)}
			<Box>{footerLogo}</Box>
		</Box>
	);
}
