import AddressWithFlag from '@/components/addressWithFlag';
import Attachment from '@/components/fileUploading/attachment';
import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import FormTextField from '@/components/form/fields/textField';
import PageLinkComponent from '@/components/page/linkComponent';
import PageWrapper from '@/components/page/wrapper';
import TipTapTypography from '@/components/tiptap/readOnlyTipTap';
import { mutateGraphQL, queryGraphQL } from '@/data/apollo';
import { CommercePublicRead } from '@/data/commerce/commerce.graphql';
import FormGraphqlProvider from '@/data/query/formGraphqlProvider';
import {
	AgreementPublicRead_ClientViewQuery,
	AgreementPublicWriteMutation,
	AgreementPublicWriteMutationVariables, HouseAccount_ClientAgreementQuery, HouseAccount_ClientAgreementQueryVariables,
} from '@/generated/graphql';
import SignModal from '@/modals/sign';
import { useEvents } from '@/providers/event';
import { useModal, useModalControls } from '@/providers/modal';
import {
	Client,
	HouseAccount,
	Order,
	QueryCommercePublicReadArgs,
	QueryHouseAccountPublicReadArgs,
} from '@/types/schema';
import { getBrowserTimezone } from '@/utils/timezone';
import { gql } from '@apollo/client';
import {
	Assignment as AssignmentIcon,
	Close as CloseIcon,
	PictureAsPdf as PictureAsPdfIcon,
} from '@mui/icons-material';
import { Avatar, Button, Divider, Grid, IconButton, Paper, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { format } from 'date-fns';
import { pick, toLower } from 'lodash-es';
import { useSnackbar } from 'notistack';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

export default function AgreementDialog( {
	id,
	client,
	onSubmit,
	removeDownloadButton,
}: {
	id?: string,
	client?: Client,
	onSubmit?: ( agreement: { id: string, title?: string } ) => void,
	removeDownloadButton?: boolean
} ) {
	const { enqueueSnackbar } = useSnackbar();
	const { closeModal } = useModalControls();
	const event = useEvents();
	const { t } = useTranslation();
	const timezone = getBrowserTimezone();
	const { showModal } = useModal();
	
	return (
		<FormGraphqlProvider<AgreementPublicRead_ClientViewQuery['agreementPublicRead']>
			id={id}
			queryKey='agreement'
			query={gql`
				query AgreementPublicRead_ClientView($id: String!) {
				  agreementPublicRead(id: $id) {
					 id
					 title
					 requireSignature
					 signedBy
					 dateSigned
					 signature
					 isDefault
					 body
					 company {
					   id
					   email
					   name
					   logo
					   locations {
							address {
								line1,
								line2,
								city,
								state,
								country,
								postalCode
							}
					   }
					 }
					 client {
					 	id
					 }
					 order {
						id
					   number
					   externalId
					   metadata
					 }
					 houseAccount {
						id
					 }
			    }
				}
			`}
			initialValues={() => ( { dateSigned: new Date() } )}
			onSubmit={async ( { id, ...values } ) => {
				try {
					
					let order: Order | undefined;
					let houseAccount: HouseAccount_ClientAgreementQuery['houseAccountPublicRead'] | undefined;
					if ( values.order?.id ) {
						const { commercePublicRead } = await queryGraphQL<QueryCommercePublicReadArgs>( {
							query    : CommercePublicRead,
							variables: { id: values.order.id },
						} );
						order = commercePublicRead;
					}
					if ( values.houseAccount?.id ) {
						const { houseAccountPublicRead } = await queryGraphQL<HouseAccount_ClientAgreementQueryVariables, HouseAccount_ClientAgreementQuery>( {
							query: gql`
								query HouseAccount_ClientAgreement($id: String) {
									houseAccountPublicRead(id: $id) {
										id
										name
										email
										client {
										  email
										}
									}
								}
							`,
							variables: { id: values.houseAccount.id },
						} );
						houseAccount = houseAccountPublicRead;
					}
					
					const { agreementPublicWrite } = await mutateGraphQL<AgreementPublicWriteMutationVariables, AgreementPublicWriteMutation>( {
						mutation: gql`
							mutation AgreementPublicWrite($id: String!, $method: String, $input: AgreementPublicValidator!) {
								agreementPublicWrite(id: $id, method: $method, input: $input) {
									id
									title
								}
							}
						`,
						variables: {
							id,
							method: 'Client Signed',
							input : {
								...pick( values, [ 'title', 'body', 'requireSignature', 'signedBy', 'signature' ] ) as any,
								dateSigned: new Date(),
								client    : client?.id || values.client?.id || order?.client?.id || null,
							},
						},
					} );
					try {
						await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/emails/${id}/agreementSignature`, {
							subject: order
								? `Agreement Signed on ${toLower( order.type )} #{{number}}`
								: `Agreement signed on ${houseAccount?.name || houseAccount?.email}`,
							to: [ values.company.email ],
							timezone,
						} );
						if ( order?.client?.email || houseAccount?.email || houseAccount?.client?.email || client?.email ) {
							await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/emails/${id}/agreementSignature`, {
								subject: order
									? `Agreement Signed on ${toLower( order.type )} #{{number}}`
									: `Agreement signed on ${houseAccount?.name || houseAccount?.email}`,
								client: true,
								timezone,
								to    : [ order?.client?.email || houseAccount?.email || houseAccount?.client?.email || client?.email ],
							} );
						}
						
					} catch {
					}
					onSubmit?.( agreementPublicWrite );
					enqueueSnackbar( 'Agreement has been signed successfully', { variant: 'success' } );
					closeModal();
					event.emit( 'reload.singleQuery', true );
				} catch ( e ) {
					throw e;
				}
			}}>
			{( formik ) => (
				<PageWrapper
					hideBack
					modalWrapper
					primary='Agreement'
					maxWidth='md'
					headerMaxWidth='xl'
					icon={(
						<IconButton onClick={() => closeModal()}>
							<CloseIcon/>
						</IconButton>
					)}
					actions={[ !removeDownloadButton && {
						name       : 'VIEW & DOWNLOAD PDF',
						buttonProps: { variant: 'contained', color: 'primary' },
						icon       : <PictureAsPdfIcon/>,
						props      : {
							component: PageLinkComponent,
							href     : `${process.env.NEXT_PUBLIC_SERVER_URL}/api/preview/pdf/${formik.values.id}/agreement`,
							target   : '_blank',
						},
					} ]}>
					<Stack sx={{ pt: 4 }}>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={2}>
								{formik.values.company?.logo && (
									<Avatar
										src={formik.values.company?.logo}
										alt='Company Logo'
										sx={{
											width       : 100,
											height      : 100,
											mr          : 3,
											borderRadius: 2,
											fontSize    : '10rem',
											img         : { objectFit: 'contain', bgcolor: '#ffffff' },
										}}
									/>
								)}
							</Grid>
							<Grid item xs={12} sm={10} sx={{ m: 'auto' }}>
								<Typography>{formik.values?.company?.name}</Typography>
								<Typography>{formik.values?.company.email}</Typography>
								<AddressWithFlag address={formik.values.company.locations[ 0 ]?.address}/>
							</Grid>
						</Grid>
						<Stack direction='column' spacing={1} sx={{ pt: 3 }}>
							<Typography variant='h3' sx={{ whiteSpace: 'pre-line' }}>
								{formik.values?.title || ''}
							</Typography>
							<TipTapTypography sx={{ fontSize: '16px !important' }} mt={5}>
								{formik.values?.body || ''}
							</TipTapTypography>
						</Stack>
					</Stack>
					{formik.values.requireSignature && (
						<Fragment>
							<Divider sx={{ my: 2 }}/>
							<Stack spacing={2} width={{ sm: '50%' }}>
								{formik.initialValues.signedBy ? (
									<Stack direction='column' spacing={1}>
										<Typography variant='h5'>
											{formik.initialValues.signedBy}
										</Typography>
										<Typography
											variant='caption'
											sx={{ fontWeight: 'bold', color: 'text.secondary' }}>
											{format( new Date(), 'PP' )}
										</Typography>
									</Stack>
								) : (
									<FormTextField
										fullWidth
										name='signedBy'
										label={t( 'management:print-your-name' )}
										helperText={format( new Date(), 'PP' )}
									/>
								)}
								{formik.values.signature && (
									<Attachment
										removeDownload
										src={formik.values.signature}
										imageSX={{
											width    : '100%',
											height   : '100%',
											objectFit: 'cover',
											
										}}
									/>
								)}
								{!formik.initialValues.signature && !formik.values?.signature ? (
									<Paper
										sx={{
											'width'         : '100%',
											'height'        : 100,
											'borderStyle'   : 'dashed',
											'borderWidth'   : 2,
											'cursor'        : 'pointer',
											'display'       : 'flex',
											'alignItems'    : 'center',
											'justifyContent': 'center',
											':hover'        : {
												bgcolor: 'alpha.primary',
											},
										}}
										onClick={() => showModal( SignModal, undefined, {
											onSave   : ( signature ) => formik.setFieldValue( 'signature', signature ),
											invoiceId: formik.values.order?.id || formik.values.houseAccount?.id,
										} )}>
										<Typography>
											Add Signature
										</Typography>
									</Paper>
								) : !formik.initialValues.signature && formik.values?.signature ? (
									<Button
										fullWidth
										variant='outlined'
										color='primary'
										sx={{ alignSelf: 'end' }}
										startIcon={<AssignmentIcon/>}
										onClick={() => showModal( SignModal, undefined, {
											onSave   : ( signature ) => formik.setFieldValue( 'signature', signature ),
											invoiceId: formik.values.order?.id || formik.values.houseAccount?.id,
										} )}>
										Change Signature
									</Button>
								) : null}
								{!formik.initialValues?.signature && (
									<AsyncLoadingButton
										fullWidth
										sx={{ width: { sm: '50%' } }}
										variant='contained'
										size='large'
										color='primary'
										disabled={!formik.values.signature || !formik.values.signedBy}
										onClick={async () => await formik.submitForm()}>
										Submit
									</AsyncLoadingButton>
								)}
							</Stack>
						</Fragment>
					)}
				</PageWrapper>
			)}
		</FormGraphqlProvider>
	);
}
