import { seoProperties } from '@/helpers/seo';
import SplashProvider from '@/providers/splash';
import ThemeProvider from '@/providers/theme';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import '../baseline/styles.scss';

const App = dynamic( () => import( './_app.component' ), { ssr: false } );

const emotionCache = createCache( { key: 'css', prepend: true } );

function _App( props: any ) {
	const seoData = seoProperties[ props.router.pathname ];

	return (
		<SplashProvider>
			<NextSeo
				title={seoData?.title ?? 'Invoiss, Free cloud-based accounting software'}
				defaultTitle='Invoiss, Free cloud-based accounting software'
				description={seoData?.description ?? undefined}
			/>
			<CacheProvider value={emotionCache}>
				<ThemeProvider>
					<App {...props}/>
				</ThemeProvider>
			</CacheProvider>
		</SplashProvider>
	);
}

export default _App;
