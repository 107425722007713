import FormSelect from '@/components/form/fields/select';
import FormTextField from '@/components/form/fields/textField';
import { Grid, MenuItem, Typography } from '@mui/material';
import * as yup from 'yup';

export const companyInfoValidationSchema = yup.object().shape( {
	akaBusinessName  : yup
		.string()
		.required( 'Enter a business name' )
		.max( 64, 'Business name is too long' ),
	dbName           : yup
		.string()
		.required( 'Enter a DBA name' )
		.max( 64, 'DBA name is too long' ),
	legalBusinessName: yup
		.string()
		.required( 'Enter a legal business name' )
		.max( 64, 'Legal business name is too long' ),
	taxFilingName    : yup
		.string()
		.required( 'Enter a tax filing name' )
		.max( 64, 'Tax filing name is too long' )
		.matches( /^[A-Za-z0-9 &-]*$/, {
			message           : 'Here only "&" and "-" are accepted as special characters.',
			excludeEmptyString: true,
		} ),
	taxFilingMethod  : yup
		.string()
		.required( 'Select a tax filing method' )
		.oneOf( [ 'SSN', 'EIN' ], 'Select a valid tax filing method' ),
	
} );

export default function CompanyInfo() {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant='h4' fontWeight='500'>
					Company Information
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography color='text.secondary'>
					Please enter the legal name of your business as it appears on your tax returns.
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<FormTextField
					fullWidth
					name='akaBusinessName'
					label='Business Name'
				/>
			</Grid>
			<Grid item xs={12}>
				<FormTextField
					fullWidth
					name='dbName'
					label='Doing Business As'
				/>
			</Grid>
			<Grid item xs={12}>
				<FormTextField
					fullWidth
					name='legalBusinessName'
					label='Legal Business Name'
				/>
			</Grid>
			<Grid item xs={12}>
				<FormTextField
					fullWidth
					name='taxFilingName'
					label='Tax Filing Name'
				/>
			</Grid>
			<Grid item xs={12}>
				<FormSelect
					fullWidth
					name='taxFilingMethod'
					label='Tax Filing Method'
					defaultValue=''>
					<MenuItem value='SSN'>SSN</MenuItem>
					<MenuItem value='EIN'>EIN</MenuItem>
				</FormSelect>
			</Grid>
		</Grid>
	);
}
