import { Collapse, CollapseProps, Grid, GridProps } from '@mui/material';
import { forwardRef, ReactNode, useEffect, useState } from 'react';

interface CustomCollapseProps {
	trigger: any,
	children: ReactNode,
	timeout?: number
}

interface CollapseWithGridProps extends Omit<CollapseProps, 'children'> {
	children?: ReactNode
}

const GridItemXs12 = forwardRef<HTMLDivElement, GridProps>( ( props, ref ) => (
	<Grid ref={ref} item xs={12} {...props}/>
) );
export const CollapseWithGrid = forwardRef<HTMLDivElement, CollapseWithGridProps>( ( {
		children, ...props
	}, ref ) => (
		<Collapse
			ref={ref}
			component={GridItemXs12}
			{...props}>
			<Grid container spacing={2}>
				{children}
			</Grid>
		</Collapse>
	),
);

export default function CustomCollapse( { trigger, children, timeout = 300 }: CustomCollapseProps ) {
	const [ isExpanded, setIsExpanded ] = useState( true );
	
	useEffect( () => {
		setIsExpanded( false );
		
		const timer = setTimeout( () => {
			setIsExpanded( true );
		}, timeout );
		
		return () => clearTimeout( timer );
	}, [ trigger, timeout ] );
	
	return (
		<Collapse in={isExpanded}>
			{children}
		</Collapse>
	);
}
