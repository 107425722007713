import { mutateGraphQL, queryGraphQL } from '@/data/apollo';
import { ClientWrite } from '@/data/management/client.graphql';
import { LineItemWrite } from '@/data/management/lineItem.graphql';
import { LineDetailType, QuickbooksInvoice } from '@/dto/qb-invoices';
import { Line, QbCustomer } from '@/dto/quickbooks';
import { convertQbClientToValidator, inferQbPrivateNote } from '@/gatewayUtils/quickbooksUtils';
import { safeConvertToZonedTime } from '@/helpers/safeFormat';
import { CredentialsWithCompany, GatewayCredentials } from '@/types/gateway';
import { QbItem, QbPurchaseOrder } from '@/types/quickbooks';
import {
	Client,
	Item,
	ItemValidator,
	LineItem,
	MutationClientsWriteArgs,
	MutationClientWriteArgs,
	MutationItemsWriteArgs,
	MutationLineItemWriteArgs,
	MutationSyncQbItemArgs,
	OrderValidator,
	Payment,
	QueryClientReadArgs,
	SimpleLineItem,
} from '@/types/schema';
import { CARD_FEE_LABEL } from '@/utils/constants';
import { gql } from '@apollo/client';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import Bluebird from 'bluebird';
import { pipe } from 'fp-ts/function';
import { groupBy, isEmpty, isNil, omitBy, partition, round, toUpper } from 'lodash-es';
import { calculateUnitPrice, parseQbClient, parseQbItem } from '../../quickbooks/utils';
import { convertPayment } from '../payment/quickbooks';
import { getQB, normalizeName, postQB, queryQB } from '../quickbooks.utils';

export const importClients = async ( credentials: CredentialsWithCompany, customerIds: string[] ) => {
	if ( !customerIds.length ) return {};
	if ( customerIds.length > 100 ) throw new Error( 'Too many customers to import' );
	const response = await queryQB( credentials, `SELECT *
	                                              FROM Customer
	                                              WHERE Id IN ('${customerIds.join( '\',\'' )}')` );
	const customers = response.data.QueryResponse?.Customer;
	if ( !customers ) return {};
	const inputs = customers
		.map( ( customer ) => parseQbClient( credentials.externalId, customer ) )
		.map( convertQbClientToValidator );
	
	const { clientsSync } = await mutateGraphQL<MutationClientsWriteArgs>( {
		mutation: gql`
			mutation ClientsSync_0320( $inputs: [ClientValidator!] ) {
				clientsSync( inputs: $inputs ){
					id
					externalValue
					addresses {
						id
						type
					}
				}
			}
		`,
		variables: { inputs },
		context  : { headers: { company: credentials.companyId } },
	} );
	
	return clientsSync.reduce( ( dict, client ) => {
		dict[ client.externalValue! ] = client;
		return dict;
	}, {} );
};

export const importItems = async ( credentials: CredentialsWithCompany, itemIds: string[] ) => {
	if ( !itemIds.length ) return {};
	if ( itemIds.length > 100 ) throw new Error( 'Too many items to import' );
	const response = await queryQB( credentials, `SELECT *
	                                              FROM Item
	                                              WHERE Id IN ('${itemIds.join( '\',\'' )}')` );
	const items = response.data.QueryResponse?.Item;
	if ( !items ) return {};
	const inputs = items
		.map( ( item ) => parseQbItem( credentials.externalId, item ) )
		.map( ( item ) => convertItem( credentials, undefined, item ) );
	
	const { syncQbItems } = await mutateGraphQL<MutationItemsWriteArgs>( {
		mutation: gql`
			mutation SyncQbItems_3558( $inputs: [ItemValidator!]! ) {
				syncQbItems(inputs: $inputs) {
					id
					externalValue
				}
			}
		`,
		variables: { inputs },
		context  : { headers: { company: credentials.companyId } },
	} );
	
	return syncQbItems.reduce( ( dict, item ) => {
		dict[ item.externalValue! ] = item;
		return dict;
	}, {} );
};

export const getAssetAccount = async ( gateway: GatewayCredentials ) => {
	const { data: assetAccounts } = await queryQB( gateway, 'select * from Account where AccountType = \'Other Current Asset\'' );
	return assetAccounts.QueryResponse.Account?.find( ( account ) => ( account.AccountType === 'Other Current Asset' || account.AccountType === 'Other Current Assets' ) && account.AccountSubType === 'Inventory' );
};

export const getExpenseAccount = async ( gateway: GatewayCredentials ) => {
	const { data: expenseAccounts } = await queryQB( gateway, 'select * from Account where AccountType = \'Cost of Goods Sold\'' );
	const account = expenseAccounts.QueryResponse.Account?.find( ( account ) => account.AccountType === 'Cost of Goods Sold' );
	if ( account ) return account;
	
	const { data: createdAccount } = await postQB( gateway, '/account', {
		Name       : 'Cost of Goods Sold',
		AccountType: 'Cost of Goods Sold',
	} );
	console.debug( 'created expense account:', createdAccount );
	return createdAccount.Account;
};

const getBankChargeAccount = async ( gateway: GatewayCredentials ) => {
	try {
		const { data } = await queryQB( gateway, 'SELECT * FROM Account WHERE AccountType = \'Expense\' AND Name = \'Bank Charges\'' );
		const account = data?.QueryResponse?.Account?.[ 0 ];
		if ( account ) return account;
		
		const { data: createdAccount } = await postQB( gateway, '/account', {
			Name          : 'Bank Charges',
			AccountType   : 'Expense',
			AccountSubType: 'BankCharges',
		} );
		return createdAccount.Account;
	} catch ( e ) {
		console.error( 'error getting bank charge account:', e );
		throw new Error( 'Error getting bank charge account' );
	}
};

export const getIncomeAccount = async ( gateway: GatewayCredentials ) => {
	const { data: incomeAccounts } = await queryQB( gateway, 'select * from Account where AccountType = \'Income\'' );
	const incomeAccount = incomeAccounts.QueryResponse.Account?.find( ( account ) => account.AccountType === 'Income' && account.AccountSubType === 'SalesOfProductIncome' );
	if ( incomeAccount ) return incomeAccount;
	
	const { data: createdAccount } = await postQB( gateway, '/account', {
		Name          : 'Sales of Product Income',
		AccountType   : 'Income',
		AccountSubType: 'SalesOfProductIncome',
	} );
	console.debug( 'created income account:', createdAccount );
	return createdAccount.Account;
};

export async function getPurchaseOrder( gateway: GatewayCredentials, id: string ): Promise<QbPurchaseOrder> {
	const { data } = await getQB( gateway, `/purchaseorder/${id}` );
	return data.PurchaseOrder;
}

export const convertOrder = ( gateway: { externalId: string }, data, isEstimate = false ) => {
	const quickbooksInvoice = data as QuickbooksInvoice;
	
	const { companyLocation } = data;
	
	return {
		gateway        : companyLocation?.gateway?.id,
		externalValue  : `${gateway.externalId}-${quickbooksInvoice.Id}-quickbooks`,
		client         : quickbooksInvoice.Customer?.id,
		number         : quickbooksInvoice.DocNumber,
		notes          : quickbooksInvoice.CustomerMemo?.value,
		overrideTotal  : quickbooksInvoice.TotalAmt,
		companyLocation: companyLocation?.id,
		clientAddress  : quickbooksInvoice.BillAddr?.id,
		shippingAddress: quickbooksInvoice.ShipAddr?.id,
		createdAt      : quickbooksInvoice.CreatedAt,
		updatedAt      : quickbooksInvoice.UpdatedAt,
		dueDate        : safeConvertToZonedTime( quickbooksInvoice.DueDate, companyLocation?.timezone ),
		serviceDate    : safeConvertToZonedTime( quickbooksInvoice.IssueDate, companyLocation?.timezone ),
		standingDate   : quickbooksInvoice.TxnDate,
		sent           : quickbooksInvoice.EmailStatus === 'EmailSent',
		type           : isEstimate ? 'ESTIMATE' : 'INVOICE',
		metadata       : {
			qbSyncToken : quickbooksInvoice.SyncToken,
			qbClientId  : quickbooksInvoice.Customer?.externalValue,
			customNumber: quickbooksInvoice.DocNumber,
		},
		prices: quickbooksInvoice.discounts?.map( ( discount ) => ( {
			name     : discount.DiscountLineDetail?.DiscountAccountRef.name,
			isPercent: discount.DiscountLineDetail?.PercentBased,
			value    : discount.DiscountLineDetail?.PercentBased
				? -discount.DiscountLineDetail?.DiscountPercent
				: -discount.Amount,
		} ) ),
		lineItems: ( quickbooksInvoice.Line
			.filter( ( line ) => !!line.Item && !isEmpty( line.Item ) ) || [] )
			.map( ( lineItem ) => {
				const name = lineItem.Item?.name || lineItem.Item?.ItemRef?.name || lineItem.Description || lineItem.Item?.description || '-';
				return {
					name         : name.slice( 0, 255 ).trim(),
					externalValue: `${gateway.externalId}-${quickbooksInvoice.Id}-${lineItem.Id}-quickbooks`,
					item         : lineItem.Item?.id,
					price        : lineItem.Item?.UnitPrice ?? lineItem.Item?.uoms?.[ 0 ]?.price ?? 0,
					quantity     : lineItem.Item?.Qty ?? 1,
					unit         : 'Unit',
					description  : lineItem.Description || lineItem.Item?.description,
					tax          : lineItem.Item?.TaxCodeRef?.value === 'NON' || quickbooksInvoice.TxnTaxDetail.TotalTax === 0
						? 0
						: round( quickbooksInvoice.TxnTaxDetail.TaxLine?.reduce( ( tax,
							taxLine ) => tax + taxLine.TaxLineDetail.TaxPercent, 0 ) || 0, 2 ),
				};
			} ),
		payments: pipe(
			( data.LinkedTxn || [] )
				.map( ( payment ) => {
					const orderPayment = payment?.Payment?.find( ( p ) => Boolean( p?.externalValue ) );
					return orderPayment
						? convertPayment( gateway, {
							...orderPayment,
							externalValue: `${gateway.externalId}-${quickbooksInvoice.Id}-${orderPayment.externalValue}-quickbooks`,
						} )
						: null;
				} )
				.filter( Boolean ),
			( x ) => isEmpty( x ) ? undefined : x,
		),
	} as OrderValidator;
};

export const convertPurchase = ( gateway, qbPurchase ) => ( {
	number      : qbPurchase.docNumber,
	notes       : qbPurchase.privateNote,
	menu        : qbPurchase.vendorId,
	grandTotal  : parseInt( qbPurchase.totalAmount, 10 ),
	paidTotal   : parseInt( qbPurchase.totalAmount, 10 ),
	createdAt   : new Date( qbPurchase.createdAt ),
	updatedAt   : new Date( qbPurchase.updatedAt ),
	quickbooksId: `${gateway.externalId}-${qbPurchase.id}`,
	metadata    : {
		entityRef  : qbPurchase.entityRef,
		paymentType: qbPurchase.paymentType,
	},
	lineItems: qbPurchase.lines.map( ( line ) => ( {
		name       : line.description || 'Unnamed item',
		price      : parseFloat( line.amount ),
		description: line.description,
		quantity   : 1, // Ensure this matches your data model's requirements
	} ) ),
	paid     : qbPurchase.paymentType === 'CreditCard',
	confirmed: true,
	received : true,
} );

export const getCardFeeLine = async (
	gateway: GatewayCredentials,
	payments: Payment[],
): Promise<Line | undefined> => {
	if ( isEmpty( payments ) ) return undefined;
	const cardFee = payments.reduce( ( sum, payment ) => sum.plus( payment.fee || 0 ), BigNumber( 0 ) );
	if ( cardFee.isZero() ) return undefined;
	
	const expenseAccount = await getBankChargeAccount( gateway );
	
	const { data: queryRes } = await queryQB( gateway, `select *
	                                                    from Item
	                                                    where Name = '${CARD_FEE_LABEL}'` );
	let cardFeeItem = queryRes.QueryResponse.Item?.[ 0 ];
	if ( !cardFeeItem ) {
		const { data: postRes } = await postQB( gateway, '/item', {
			Name            : CARD_FEE_LABEL,
			IncomeAccountRef: { value: expenseAccount.Id },
			Type            : 'Service',
		} );
		cardFeeItem = postRes.Item;
	}
	return {
		DetailType         : LineDetailType.SalesItemLineDetail,
		Amount             : cardFee.toNumber(),
		SalesItemLineDetail: {
			ItemRef: { value: cardFeeItem.Id, name: CARD_FEE_LABEL },
		},
	};
};

export const postItem = async ( gateway: GatewayCredentials,
	{
		id,
		externalValue,
		name,
		uom,
		description,
		taxable,
	}: Pick<Item, 'id' | 'externalValue' | 'description' | 'taxable' | 'name'> & Pick<LineItem, 'uom'>,
	accounts?: { assetAccount: any, expenseAccount: any, incomeAccount: any } ): Promise<QbItem & { id: string }> => {
	if ( !name ) throw new Error( `Invalid item name: ${name}` );
	let qbItem;
	const normalizedName = normalizeName( name );
	
	const assetAccount = accounts?.assetAccount || await getAssetAccount( gateway );
	const expenseAccount = accounts?.expenseAccount || await getExpenseAccount( gateway );
	const incomeAccount = accounts?.incomeAccount || await getIncomeAccount( gateway );
	
	const qbItemId = externalValue?.split?.( '-' )?.[ 1 ];
	
	if ( !qbItem && qbItemId ) {
		await getQB( gateway, `/item/${qbItemId}` )
			.then( ( { data } ) => {
				if ( data.Item.Type !== 'Category' && data.Item.Active ) {
					qbItem = data.Item;
				}
			} )
			.catch( () => null );
	}
	
	if ( !qbItem && normalizedName ) {
		const { data } = await queryQB( gateway, `select *
		                                          from Item
		                                          where Name = '${normalizedName.replace( /'/g, '\\\'' )}'` );
		qbItem = data.QueryResponse?.Item?.[ 0 ];
	}
	
	const qbUpdateItem = {
		Id              : qbItem?.Id || null,
		SyncToken       : qbItem?.SyncToken || null,
		sparse          : false,
		Sku             : uom?.sku || null,
		Description     : description || null,
		PurchaseCost    : uom?.cost || 1,
		UnitPrice       : uom?.price || 1,
		IncomeAccountRef: qbItem?.IncomeAccountRef ?? ( incomeAccount ? { value: incomeAccount.Id } : null ),
		AssetAccountRef : qbItem?.AssetAccountRef
			?? ( ![ 'Service', 'NonInventory' ].includes( qbItem?.Type ) && assetAccount
				? { value: assetAccount.Id }
				: null ),
		ExpenseAccountRef: qbItem?.ExpenseAccountRef ?? ( expenseAccount ? { value: expenseAccount.Id } : null ),
		QtyOnHand        : uom?.quantity || qbItem?.QtyOnHand || 0,
	};
	
	try {
		const payload = {
			...omitBy( qbUpdateItem, isNil ),
			Name   : qbItem?.Name || normalizedName,
			Type   : qbItem?.Type ?? 'NonInventory',
			Taxable: taxable ?? true,
		};
		console.log( 'item payload:', payload );
		const response = await postQB( gateway, '/item', payload );
		const item = response.data.Item;
		if ( id && !externalValue ) {
			await mutateGraphQL( {
				mutation: gql`
					mutation UpdateItemQbId($id: String, $input: ItemValidator) {
						itemWrite(id: $id, input: $input) {
							id
						}
					}
				`,
				variables: {
					id,
					input: {
						externalValue: `${gateway.externalId}-${item.Id}-quickbooks`,
					},
				},
			} );
		}
		return { ...item, id: item.Id };
	} catch ( e ) {
		console.error( { itemError: e.response?.data } );
		// skip update the item if it fails, cuz some client got `Unexpected critical error. (-20999)` error
		if ( qbItem ) return { ...qbItem, id: qbItem.Id };
		throw e;
	}
	
};

export const getClients = async ( gateway: { id: string }, page: number | string = 0, size = 100 ) => {
	const { data } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/quickbooks/${gateway.id}/getClients`, {
		startPosition: ( +page === 0 ? 1 : +page * size + 1 ).toString(),
		size,
	} );
	return { data, nextPage: +page + 1 };
};

export const getInvoices = async ( { gateway, page = 0, size = 100, ids }: {
	gateway: { id: string },
	page?: number | string,
	size?: number,
	ids?: string[]
} ) => {
	const { data } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/quickbooks/${gateway.id}/getInvoices`, {
		startPosition: ( +page === 0 ? 1 : +page * size + 1 ).toString(),
		size,
		ids,
	} );
	return { data, nextPage: +page + 1 };
};

export const getEstimates = async ( { gateway, page = 0, size = 100, ids }: {
	gateway: { id: string },
	page?: number | string,
	size?: number,
	ids?: string[]
} ) => {
	const { data } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/quickbooks/${gateway.id}/getEstimates`, {
		startPosition: ( +page === 0 ? 1 : +page * 100 + 1 ).toString(),
		size,
		ids,
	} );
	return { data, nextPage: +page + 1 };
};

export const getPurchases = async ( gateway, page: number | string = 0 ) => {
	const { data } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/quickbooks/${gateway.id}/getPurchases`, {
		startPosition: ( +page === 0 ? 1 : +page * 100 + 1 ).toString(),
	} );
	return { data, nextPage: +page + 1 };
};

export const convertItem = ( gateway: GatewayCredentials, locations, data ) => ( {
	parentRef: data.parentRef
		? { name: data.parentRef.name, id: `qb-${gateway.externalId}-${data.parentRef.value}` }
		: undefined,
	description  : data.description,
	externalValue: data.id,
	name         : data.name.replace( '/\0/g', '' ),
	taxable      : data.taxable,
	createdAt    : data.createdAt,
	updatedAt    : data.updatedAt,
	metadata     : { qbSyncToken: data.syncToken },
	uoms         : [ {
		name    : 'Unit',
		price   : data.unitPrice || 0,
		cost    : data.purchaseCost || 0,
		quantity: data.qty || 1,
		selected: true,
	} ],
	locations     : locations,
	categories    : undefined,
	modifierGroups: undefined,
} as ItemValidator );

export const getItems = async ( gateway, _location, page ) => {
	const { data } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/quickbooks/${gateway.id}/getItems`, {
		startPosition: ( +page === 0 ? 1 : +page * 100 + 1 ).toString(),
	} );
	return { data, nextPage: +page + 1 };
};

const findClient = async ( gateway,
	{
		externalValue,
		name,
		email,
		contact,
	}: Pick<Client, 'externalValue' | 'name' | 'email' | 'contact'> ): Promise<QbCustomer | null> => {
	const qbClientId = externalValue?.split?.( '-' )?.[ 1 ];
	let qbCustomer = null;
	if ( qbClientId ) {
		await getQB( gateway, `/customer/${qbClientId}` )
			.then( ( { data } ) => {
				if ( data.Customer?.Active ) {
					console.log( 'got customer by id', qbClientId );
					qbCustomer = data.Customer;
				}
			} )
			.catch( () => null );
	}
	
	if ( !qbCustomer && name ) {
		const modifiedName = normalizeName( name );
		const { data } = await queryQB( gateway, `select *
		                                          from Customer
		                                          where displayName = '${modifiedName.replace( /'/g, '\\\'' )}'` );
		const customer = data.QueryResponse?.Customer?.[ 0 ];
		if ( customer?.Active ) {
			console.log( 'got customer by name', modifiedName );
			qbCustomer = customer;
		}
	}
	if ( !qbCustomer && email ) {
		const { data } = await queryQB( gateway, `select *
		                                          from Customer
		                                          where displayName = '${email}'` );
		const customer = data.QueryResponse?.Customer?.[ 0 ];
		if ( customer?.Active ) {
			console.log( 'got customer by email', email );
			qbCustomer = customer;
		}
	}
	if ( !qbCustomer && contact && contact.length > 2 ) {
		const splitContact = contact.split( ' ' );
		let displayName = `${splitContact?.[ 0 ]} ${splitContact?.[ 1 ]}`.trim();
		const { data } = await queryQB( gateway, `select *
		                                          from Customer
		                                          where displayName = '${displayName.replace( /'/g, '\\\'' )}'` );
		const customer = data.QueryResponse?.Customer?.[ 0 ];
		if ( customer?.Active ) {
			console.log( 'got customer by displayName', displayName );
			qbCustomer = customer;
		}
		if ( !qbCustomer ) {
			displayName = `${splitContact?.[ 0 ]}${splitContact?.[ 1 ]}`.trim();
			const { data: data2 } = await queryQB( gateway, `select *
			                                                 from Customer
			                                                 where displayName = '${displayName.replace( /'/g, '\\\'' )}'` );
			const customer2 = data2.QueryResponse?.Customer?.[ 0 ];
			if ( customer?.Active ) {
				console.log( 'got customer by displayName', displayName );
				qbCustomer = customer2;
			}
		}
	}
	
	return qbCustomer;
};

const quickbooksManageProcessor = {
	type: 'QUICKBOOKS',
	postItem,
	async getItems( gateway, _location, page = 0 ) {
		return getItems( gateway, _location, page );
	},
	
	async convertItem( gateway, location, data ) {
		return convertItem( gateway, location, data );
	},
	getClients,
	async postClient( gateway,
		{ externalValue, name, contact, email, phone, cell, taxCode, addresses, metadata },
		qbClient?: QbCustomer ) {
		const qbCustomer = qbClient !== undefined ? qbClient : await findClient( gateway, {
			externalValue,
			name,
			email,
			contact,
		} );
		
		const qbUpdateCustomer = {
			Id               : qbCustomer?.Id || null,
			SyncToken        : qbCustomer?.SyncToken || metadata?.qbSyncToken || null,
			sparse           : qbCustomer?.Id ? true : null,
			PrimaryPhone     : phone ? { FreeFormNumber: phone.replace( '+', '' ) } : null,
			PrimaryEmailAddr : email ? { Address: email } : null,
			Mobile           : cell ? { FreeFormNumber: cell } : null,
			DisplayName      : name || email || null,
			GivenName        : contact?.length as number > 2 ? contact?.split( ' ' )?.[ 0 ] : null,
			FamilyName       : contact?.length as number > 2 ? contact?.split( ' ' )?.[ 1 ] : null,
			DefaultTaxCodeRef: taxCode ? { value: taxCode } : null,
		};
		
		const payload = {
			...omitBy( qbUpdateCustomer, isNil ),
			CompanyName       : qbCustomer?.CompanyName || name || '',
			FullyQualifiedName: name || '',
			...metadata?.exemptFromTax && {
				Taxable             : false,
				TaxExemptionReasonId: 11, // (https://developer.intuit.com/app/developer/qbo/docs/api/accounting/most-commonly-used/customer#create-a-customer)
			},
			BillAddr: addresses?.reduce( ( obj, address ) => {
				if ( address.type === 'BILLING' ) {
					return {
						CountrySubDivisionCode: address.state,
						City                  : address.city,
						PostalCode            : address.postalCode,
						Line1                 : address.line1,
						Country               : 'USA',
					};
				} else {
					return {
						CountrySubDivisionCode: address.state,
						City                  : address.city,
						PostalCode            : address.postalCode,
						Line1                 : address.line1,
						Country               : 'USA',
					};
				}
			}, {} ),
			ShipAddr: addresses?.reduce( ( obj, address ) => {
				if ( address.type === 'SHIPPING' ) {
					return {
						CountrySubDivisionCode: address.state,
						City                  : address.city,
						PostalCode            : address.postalCode,
						Line1                 : address.line1,
						Country               : 'USA',
					};
				}
			}, {} ),
		};
		
		console.log( 'customer payload:', payload );
		
		const response = await postQB( gateway, '/customer', payload );
		
		console.log( 'updated customer' );
		// .catch( ( err ) => {
		// 	const { Id, SyncToken, sparse, ...rest } = payload as any;
		// 	if ( !Id ) throw err;
		// 	// retry as a new Client:
		// 	return postQB( gateway, '/customer', rest );
		// } );
		const customer = response.data.Customer;
		
		return { ...customer, id: customer.Id };
	},
	async postOrder( gateway, {
		externalValue,
		companyLocation,
		client,
		grandTotal,
		lineItems,
		prices,
		payments,
		metadata,
		dueDate,
		standingDate,
		taxTotal,
		number,
		clientAddress,
		shippingAddress,
		subTotal,
		company,
		notes,
		po,
		sent,
	} ) {
		if ( !companyLocation ) {
			throw new Error( 'Please add a company location to this invoice' );
		}
		if ( !client ) {
			throw new Error( 'Please add a client to this invoice' );
		}
		
		const lineItemsWithoutCardFee = lineItems.filter( ( { name } ) => name !== CARD_FEE_LABEL );
		
		if ( lineItemsWithoutCardFee.length ) {
			const { data: categories } = await queryQB( gateway, 'select * from Item where Type=\'Category\'' );
			const categorySet = new Set( categories?.QueryResponse?.Item?.map( ( category ) => category.Name ) ?? [] );
			for ( const lineItem of lineItemsWithoutCardFee ) {
				const name = normalizeName( lineItem.name || lineItem.item?.name );
				if ( name.includes( ':' ) ) {
					throw new Error( `Please rename the item "${name}" as Quickbooks does not accept colons in item name` );
				}
				if ( categorySet.has( name ) ) {
					throw new Error( `Item "${name}" is a category in your Quickbooks. Please rename the item or the category` );
				}
			}
		}
		
		console.log( 'externalKey', gateway.externalKey );
		const [ discounts, qbTaxes ] = partition( prices, ( price ) => price?.value < 0 );
		let qbInvoiceId = externalValue?.split?.( '-' )?.[ 1 ]; // invoice could be deleted by user in QB
		let qbTaxCode = qbTaxes?.[ 0 ]?.metadata?.taxCodeRef;
		
		if ( !client.metadata?.exemptFromTax ) {
			const lineItemTaxes = lineItemsWithoutCardFee.map( ( { tax } ) => tax ).filter( Boolean );
			if ( !isEmpty( lineItemTaxes ) && !lineItemTaxes?.every( ( lineItem ) => lineItem === lineItemTaxes?.[ 0 ] ) ) {
				throw 'Quickbooks does not accept multiple different taxes on line items';
			}
			
			if ( !qbTaxCode ) {
				const qbTaxCodes = await queryQB( gateway, 'Select * From TaxCode' )
					.then( ( { data: taxCodes } ) => taxCodes.QueryResponse.TaxCode )
					.catch( () => null );
				const qbTaxName = qbTaxes?.[ 0 ]?.name;
				if ( qbTaxName && qbTaxCodes?.length ) {
					qbTaxCode = qbTaxCodes.find( ( tc ) => tc.Name.startsWith( qbTaxName.split( ' ' )[ 0 ] ) )?.Id
						|| qbTaxCodes.find( ( tc ) => tc?.SalesTaxRateList?.TaxRateDetail?.find( ( t ) => t.TaxRateRef.name === qbTaxName ) )?.Id;
				}
			}
		}
		
		const qbCustomer = await findClient( gateway, client )
			|| await this.postClient( gateway, { ...client, taxCode: qbTaxCode } as any, null );
		
		if ( !qbCustomer.Id ) throw new Error( 'Client not found' );
		
		if ( !client?.id ) {
			await queryGraphQL<QueryClientReadArgs>( {
				query: gql`
					query ClientRead_96af($externalValue: String!) {
						clientRead(externalValue: $externalValue) {
							id
							metadata
						}
					}
				`,
				variables: { externalValue: `${gateway.externalId}-${qbCustomer.Id}-quickbooks` },
			} ).then( ( { clientRead } ) => {
				client.id = clientRead?.id;
				client.metadata = clientRead?.metadata;
			} ).catch( ( e ) => console.error( e ) );
		}
		if ( !client.id ) throw new Error( 'Client not found' );
		
		// update sync token:
		await mutateGraphQL<MutationClientWriteArgs>( {
			mutation : ClientWrite,
			variables: {
				id   : client.id,
				input: {
					externalValue: `${gateway.externalId}-${qbCustomer.Id}-quickbooks`,
					metadata     : { qbSyncToken: qbCustomer.SyncToken },
				},
			},
			context: { headers: { company: company.id } },
		} ).catch( ( e ) => console.error( e ) );
		
		const taxCode = companyLocation.address.country === 'US' || companyLocation.address.country === 'United States'
			? qbTaxCode ? 'TAX' : 'NON'
			: qbTaxCode || 'TAX';
		
		const handleSalesLineItem = ( lineItem: SimpleLineItem, qbItem: QbItem ) => {
			const hasTax = lineItem.orderTax || lineItem.prices.some( ( p ) => p.metadata?.useTax );
			const taxDetail = hasTax
				? { TaxCodeRef: { value: taxCode } }
				: { TaxCodeRef: { value: 'NON' } };
			const UnitPrice = calculateUnitPrice( lineItem );
			return {
				DetailType         : 'SalesItemLineDetail',
				Amount             : UnitPrice * lineItem.quantity,
				Id                 : lineItem.externalValue ? lineItem.externalValue.split( '-' )[ 2 ] : undefined,
				Description        : lineItem?.description || '',
				SalesItemLineDetail: {
					...omitBy( taxDetail, isNil ),
					Qty    : lineItem.quantity,
					UnitPrice,
					ItemRef: {
						name : qbItem.Name || lineItem.name || lineItem.item?.name || '',
						value: qbItem.Id || '0',
					},
				},
			};
		};
		
		const cardFeeLine = await getCardFeeLine( gateway, payments );
		
		const assetAccount = await getAssetAccount( gateway );
		const expenseAccount = await getExpenseAccount( gateway );
		const incomeAccount = await getIncomeAccount( gateway );
		
		const handlePostItem = async ( lineItem: SimpleLineItem ) => {
			const item = lineItem.item;
			const uom = lineItem.uom;
			// if ( item.externalValue ) {
			// 	return { Id: item.externalValue.split( '-' )[ 1 ], Name: item.name } as QbItem;
			// }
			return postItem(
				gateway,
				{
					id  : item?.id,
					name: lineItem.name || item?.name,
					uom : {
						sku     : uom?.sku,
						quantity: uom?.quantity,
						price   : uom?.price,
						cost    : uom?.cost,
					},
					metadata     : item?.metadata,
					description  : lineItem?.description || item?.description,
					taxable      : item?.taxable,
					externalValue: item?.externalValue,
				} as any,
				{ assetAccount, expenseAccount, incomeAccount },
			);
		};
		
		const lineItemsGroups = Object.values( groupBy( lineItemsWithoutCardFee, ( lineItem ) => normalizeName( ( lineItem.name || lineItem.item?.name )! ) ) );
		
		const handlePostItemsByGroup = async ( lineItems: SimpleLineItem[] ) => {
			const pickedLineitem = lineItems.find( ( l ) => l.item?.externalValue ) || lineItems[ lineItems.length - 1 ];
			const qbItem: QbItem = await handlePostItem( pickedLineitem );
			const item = lineItems[ 0 ].item;
			
			await mutateGraphQL<MutationSyncQbItemArgs>( {
				mutation: gql`
					mutation SyncQbItem_58d3($input: ItemValidator!) {
						syncQbItem(input: $input) {
							id
						}
					}
				`,
				variables: {
					input: {
						externalValue: `${gateway.externalId}-${qbItem.Id}-quickbooks`,
						name         : qbItem.Name,
						metadata     : { qbSyncToken: qbItem.SyncToken || item?.metadata?.qbSyncToken || '0' },
					},
				},
			} ).then( ( { syncQbItem } ) => {
				if ( item?.id === syncQbItem.id ) return;
				return mutateGraphQL<MutationLineItemWriteArgs>( {
					mutation : LineItemWrite,
					variables: {
						id   : pickedLineitem.id,
						input: { item: syncQbItem.id },
					},
				} );
			} ).catch( ( e ) => {
				console.error( 'syncQbItem error', e );
			} );
			
			return lineItems.map( ( lineItem ) => handleSalesLineItem( lineItem, qbItem ) );
		};
		
		const qbSalesLineItems = ( await Bluebird.map( lineItemsGroups, handlePostItemsByGroup, { concurrency: 2 } ) ).flat() as Line[];
		
		// console.dir( { qbSalesLineItems }, { depth: 10 } );
		
		let discountAccount;
		if ( !isEmpty( discounts ) ) {
			const { data: discountAccounts } = await queryQB( gateway, 'select * from Account where AccountType = \'Income\' and AccountSubType = \'DiscountsRefundsGiven\'' );
			if ( discountAccounts.QueryResponse?.Account ) {
				discountAccount = discountAccounts.QueryResponse.Account.find( ( account ) => account.Name.includes( 'Discount' ) || account.Name.includes( 'discount' ) );
			}
		}
		const qbDiscountLineItems: Line[] = discounts?.map( ( discount ) => ( {
			DetailType        : 'DiscountLineDetail',
			Amount            : Math.abs( discount.value ),
			// Id: discount.externalValue ? discount.externalValue.split( '-' )[ 2 ] : '0',
			DiscountLineDetail: {
				...omitBy( discountAccount ? {
					DiscountAccountRef: {
						name : discountAccount.Name,
						value: discountAccount.Id,
					},
				} : null, isNil ),
				PercentBased: discount.isPercent,
				...omitBy( { DiscountPercent: discount.isPercent ? Math.abs( discount.value ) : null }, isNil ),
			},
		} ) ) as Line[];
		
		const qbTaxDetail = !client.metadata?.exemptFromTax && !isEmpty( qbTaxes ) && taxTotal ? {
			TxnTaxDetail: {
				TxnTaxCodeRef: { value: qbTaxCode },
				TaxLine      : [ {
					DetailType   : 'TaxLineDetail',
					TaxLineDetail: {
						NetAmountTaxable: subTotal,
						TaxPercent      : qbTaxes[ 0 ].value || 0,
						PercentBased    : true,
						TaxRateRef      : { value: qbTaxes[ 0 ].id, name: qbTaxes[ 0 ].name },
					},
				} ],
			},
		} : qbTaxes[ 0 ]?.id ? {
			TxnTaxDetail: {
				TaxLine: [ {
					DetailType   : 'TaxLineDetail',
					TaxLineDetail: {
						TaxRateRef: { value: qbTaxes[ 0 ].id || '0' },
					},
				} ],
			},
		} : null;
		
		let qbInvoice;
		if ( qbInvoiceId ) {
			await getQB( gateway, `/invoice/${qbInvoiceId}` )
				.then( ( { data } ) => qbInvoice = data.Invoice )
				.catch( () => qbInvoiceId = null );
		}
		
		const qbInvoiceUpdate = {
			Id          : qbInvoiceId || null,
			SyncToken   : qbInvoice?.SyncToken || ( metadata?.qbSyncToken !== 'NaN' ? metadata?.qbSyncToken : null ),
			sparse      : qbInvoiceId ? true : null,
			...qbInvoice?.ClassRef && { ClassRef: qbInvoice.ClassRef },
			CustomerMemo: notes ? { value: notes } : null,
			PrivateNote : inferQbPrivateNote( { po, metadata } ) || null,
			BillAddr    : clientAddress ? {
				Line1                 : clientAddress.line1,
				Line2                 : clientAddress.line2 || '',
				City                  : clientAddress.city,
				CountrySubDivisionCode: clientAddress.state,
				Country               : clientAddress.country.length === 2 ? toUpper( clientAddress.country ) : 'USA',
				PostalCode            : clientAddress.postalCode,
			} : null,
			ShipAddr: shippingAddress ? {
				Line1                 : shippingAddress.line1,
				Line2                 : shippingAddress.line2 || '',
				City                  : shippingAddress.city,
				CountrySubDivisionCode: shippingAddress.state,
				Country               : shippingAddress.country.length === 2 ? toUpper( shippingAddress.country ) : 'USA',
				PostalCode            : shippingAddress.postalCode,
			} : null,
		};
		
		const payload = {
			...omitBy( qbInvoiceUpdate, isNil ),
			...omitBy( qbTaxDetail, isNil ),
			CustomerRef: {
				value: qbCustomer.Id,
				name : qbCustomer.DisplayName,
			},
			Line                 : [ ...qbSalesLineItems, ...qbDiscountLineItems ].concat( cardFeeLine || [] ),
			DocNumber            : metadata?.customNumber || number,
			DueDate              : dueDate,
			TxnDate              : standingDate,
			TotalAmt             : grandTotal,
			ApplyTaxAfterDiscount: true,
			EmailStatus          : sent ? 'EmailSent' : 'NotSet',
		};
		console.log( 'invoice payload:', JSON.stringify( payload ) );
		const response = await postQB( gateway, '/invoice', payload );
		console.log( 'updated invoice' );
		
		if ( !response?.data ) return null;
		const invoice = response.data.Invoice;
		return { ...invoice, id: invoice.Id };
	},
};

export default quickbooksManageProcessor;
