import { useAtom } from 'jotai/index';
import { useCallback, useEffect, useState } from 'react';
import { useGraphQL } from '../../../data';
import { CommercesRead } from '../../../data/commerce/commerce.graphql';
import { clientsMapAtom, commercesMapAtom, companyMapAtom, staffsMapAtom } from '../../../utils/atoms';
import { clientsReadQuery, clientsReadQueryForEmails, staffsReadQuery, staffsReadQueryForEmails } from './gqlHelper';

export const transformFirebaseDate = ( time ): Date | null => time
	? new Date( time?.seconds * 1000 + time?.nanoseconds / 1000000 )
	: null;

export function useClients( { clientView = false } ) {
	const [ clientsMap, setClientsMap ] = useAtom( clientsMapAtom );
	const [ emails, setEmails ] = useState( [] );
	const queryKey = clientView ? 'clientsReadForEmails' : 'clientsRead';
	
	const { data } = useGraphQL( {
		queryKey : [ queryKey ],
		query    : clientView ? clientsReadQueryForEmails : clientsReadQuery,
		variables: clientView
			? { emails, options: { limit: 1000 } }
			: { options: { limit: 1000, filter: { email: { $in: emails } } } },
	}, {
		enabled         : Boolean( emails.length ),
		keepPreviousData: true,
		placeholderData : undefined,
	} );
	
	const triggerClientFetch = useCallback( async ( newEmails: string[] ) => {
		const uniqueEmails = newEmails.filter( ( email ) => !clientsMap[ email ] );
		if ( uniqueEmails.length > 0 ) {
			setEmails( uniqueEmails );
		}
	}, [] );
	
	useEffect( () => {
		const items = clientView ? data?.[ queryKey ] : data?.[ queryKey ]?.items;
		if ( items ) {
			const newClientsMap = items.reduce( ( acc, { id, email, name, contact, logo } ) => ( {
				...acc,
				[ email ]: {
					id,
					email,
					name   : name || '',
					contact: contact || '',
					image  : logo || '',
				},
			} ), {} );
			setClientsMap( ( prevMap ) => ( { ...prevMap, ...newClientsMap } ) );
		}
	}, [ data, setClientsMap ] );
	
	return { triggerClientFetch };
}

export function useStaffs( { clientView = false } ) {
	const [ staffsMap, setStaffsMap ] = useAtom( staffsMapAtom );
	const [ , setCompanyMap ] = useAtom( companyMapAtom );
	const [ staffQuery, setStaffQuery ] = useState( [] );
	const queryKey = clientView ? 'staffsReadForEmails' : 'staffsRead';
	const variables = clientView
		? { staffQuery, options: { filter: { user: { $exists: true } }, limit: 1000 } }
		: { options: { filter: { user: { $exists: true } }, limit: 1000 } };
	
	const { data, refetch } = useGraphQL( {
		queryKey: [ queryKey ],
		query   : clientView ? staffsReadQueryForEmails : staffsReadQuery,
		variables,
	}, {
		enabled         : clientView ? staffQuery.length > 0 : false,
		keepPreviousData: true,
		placeholderData : undefined,
	} );
	
	const triggerStaffFetch = useCallback( async ( query, clientView = true ) => {
		if ( clientView ) {
			setStaffQuery( query );
		} else {
			refetch();
		}
	}, [] );
	
	useEffect( () => {
		const items = data?.[ queryKey ]?.items;
		if ( items ) {
			const newStaffsMap = items.reduce( ( acc, { id, email, user } ) => ( {
				...acc,
				[ email ]: {
					id,
					email,
					firstName: user?.firstName || '',
					lastName : user?.lastName || '',
					image    : user?.image || '',
				},
			} ), {} );
			
			const companyMap = items.reduce( ( acc, { company } ) => ( {
				...acc,
				[ company?.id ]: {
					id   : company?.id || '',
					name : company?.name || '',
					email: company?.email || '',
					logo : company?.logo || null,
				},
			} ), {} );
			
			setCompanyMap( ( prevMap ) => ( { ...prevMap, ...companyMap } ) );
			setStaffsMap( ( prevMap ) => ( { ...prevMap, ...newStaffsMap } ) );
		}
	}, [ data, setStaffsMap ] );
	
	return { triggerStaffFetch };
}

export function useCommerces() {
	const [ commercesMap, setCommercesMap ] = useAtom( commercesMapAtom );
	const [ ids, setIds ] = useState( [] );
	
	const { data } = useGraphQL( {
		queryKey : [ 'commerce' ],
		query    : CommercesRead,
		variables: {
			options: { limit: 1000, filter: { id: { $in: ids } } },
		},
	}, {
		enabled         : Boolean( ids.length ),
		keepPreviousData: true,
		placeholderData : undefined,
	} );
	
	const triggerCommercesFetch = useCallback( async ( newIds: string[] ) => {
		const uniqueIds = newIds.filter( ( id ) => !commercesMap[ id ] );
		if ( uniqueIds.length > 0 ) {
			setIds( uniqueIds );
		}
	}, [] );
	
	useEffect( () => {
		const items = data?.commercesRead?.items;
		if ( items ) {
			const newCommercesMap = items.reduce( ( acc, { id, number, type } ) => ( {
				...acc,
				[ id ]: {
					id,
					number,
					type,
				},
			} ), {} );
			setCommercesMap( ( prevMap ) => ( { ...prevMap, ...newCommercesMap } ) );
		}
	}, [ data, setCommercesMap ] );
	
	return { triggerCommercesFetch };
}
