import { ActionProps } from '@/components/actions';
import { mutateGraphQL } from '@/data/apollo';
import { CommerceWrite } from '@/data/commerce/commerce.graphql';
import { InvoiceWrite } from '@/data/commerce/invoice.graphql';
import { PaymentWrite } from '@/data/commerce/payment.graphql';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import { stayOpen } from '@/pages/dashboard/commerce/components/tableHelpers';
import CommerceItemsUpdating from '@/pages/dashboard/commerce/invoices/commerceItemsUpdating';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import { useModal } from '@/providers/modal';
import { MutationCommerceWriteArgs, MutationInvoiceWriteArgs, MutationPaymentWriteArgs, Order } from '@/types/schema';
import {
	AssignmentTurnedInRounded as AssignmentTurnedInRoundedIcon,
	CreditScore as CreditScoreIcon,
	CurrencyExchangeRounded as CurrencyExchangeRoundedIcon,
	Email as EmailIcon,
	MoneyOffCsred as MoneyOffCsredIcon,
} from '@mui/icons-material';
import axios from 'axios';
import { toLower } from 'lodash-es';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import process from 'process';
import { useTranslation } from 'react-i18next';

export const markStatusAction = async ( invoice: Order, changeStatusData: any, method: string, unsync = false ) => {
	
	const removeSyncInfo = unsync ? null : undefined;
	
	if ( unsync && invoice.externalId ) {
		await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/deleteOrder`, { id: invoice.id } );
	}
	
	await mutateGraphQL<MutationCommerceWriteArgs>( {
		mutation : CommerceWrite,
		variables: {
			id   : invoice.id,
			method,
			input: {
				...changeStatusData,
				gateway      : removeSyncInfo,
				externalId   : removeSyncInfo,
				number       : removeSyncInfo,
				overrideTotal: removeSyncInfo,
			},
		},
	} );
};

export default function MarkStatusActions( invoice: Order ): ActionProps[] {
	const { t } = useTranslation();
	const { showModal } = useModal();
	const router = useRouter();
	const { enqueueSnackbar } = useSnackbar();
	const confirmDialog = useConfirmDialog();
	const payable = usePermissions( permissions.payments.read || permissions.payments.write );
	const editable = usePermissions( permissions.invoices.write );
	
	const hasPayments = invoice.paidTotal > 0 && invoice.payments?.find( ( payment ) => payment?.status === 'PAID' && payment?.refundedAmount <= 0 );
	const canBeUnSynced = invoice.externalId && ![ 'PAID', 'PARTIALLY_PAID' ].includes( invoice.status );
	
	return [ editable && hasPayments && {
		name   : t( 'commerce:issue-refund' ),
		icon   : <CurrencyExchangeRoundedIcon/>,
		details: 'Scroll down to payments',
		onClick: async () => await router.push( `/dashboard/commerce/invoices/${invoice.id}` ),
	}, editable && invoice.status === 'DRAFT' && !invoice.standing && {
		name   : t( 'common:mark-sent' ),
		details: invoice.standing
			? t( 'common:mark-sent-pre' )
			: t( 'common:mark-sent-post' ),
		icon   : <EmailIcon/>,
		onClick: async () => {
			const value = await confirmDialog( {
				title  : t( 'common:mark-sent' ),
				message: t( 'common:mark-sent-confirmation' ),
			} );
			if ( !value ) return;
			return await mutateGraphQL<MutationInvoiceWriteArgs>( {
				mutation : InvoiceWrite,
				variables: {
					id    : invoice.id,
					method: 'Marked Sent',
					input : { sent: true },
				},
			} );
		},
	}, editable && payable && invoice.status !== 'PAID' && !invoice.standing && invoice.status !== 'ACCOUNT' && {
		name   : t( 'common:mark-paid' ),
		details: invoice.standing
			? t( 'common:generate-midnight' )
			: t( 'common:use-pay-online' ),
		icon   : <CreditScoreIcon/>,
		onClick: async () => {
			const markAsPaid = async ( invoice: Order ) => {
				const { paymentWrite } = await mutateGraphQL<MutationPaymentWriteArgs>( {
					mutation : PaymentWrite,
					variables: {
						id    : null,
						method: 'Marked Paid',
						input : {
							gateway: invoice.gateway?.id || null,
							status : 'PAID',
							type   : 'PAID',
							amount : invoice.grandTotal - invoice.paidTotal,
							tip    : 0,
							client : invoice.client?.id || null,
							order  : invoice.id || null,
							note   : 'Marked as paid',
						},
					},
				} );
				return paymentWrite;
			};
			
			const value = await confirmDialog( {
				title  : `${t( 'common:mark-paid' )} & ${t( 'commerce:update-stock' )}`,
				message: `${t( 'common:mark-paid-pre' )} ${invoice.externalId && invoice.gateway?.external === 'CLOVER'
					? t( 'common:mark-paid-post' )
					: ''}`,
				buttonText        : `${t( 'common:mark-paid' )}`,
				extraButtonText   : `${t( 'common:mark-paid' )} & ${t( 'commerce:update-stock' )}`,
				onExtraButtonClick: async () => {
					const paymentWrite = await markAsPaid( invoice );
					if ( paymentWrite ) {
						showModal( CommerceItemsUpdating, {
							maxWidth: 'lg',
							onClose : ( event, reason ) => stayOpen( event, reason ),
						}, { commerce: invoice } );
					}
				},
			} );
			
			if ( !value ) return;
			await markAsPaid( invoice );
			
		},
	}, editable && {
		name   : invoice.refunded ? t( 'commerce:unmark-refunded' ) : t( 'commerce:mark-as-refunded' ),
		details: 'Marking or unmarking as refunded will not process a refund',
		icon   : <MoneyOffCsredIcon/>,
		onClick: async ( e, closeMenu ) => {
			closeMenu();
			const value = await confirmDialog( {
				title  : invoice.refunded ? t( 'commerce:unmark-refunded' ) : t( 'commerce:mark-as-refunded' ),
				message: 'Marking or unmarking as refunded will not process a refund, but you can issue a refund from the payments tab.',
			} );
			if ( !value ) return;
			return await mutateGraphQL<MutationInvoiceWriteArgs>( {
				mutation : InvoiceWrite,
				variables: {
					id    : invoice.id,
					method: invoice.refunded ? 'Removed Refunded' : 'Marked Refunded',
					input : { refunded: !invoice.refunded },
				},
			} );
		},
	}, editable && {
		name   : invoice.nonReport ? t( 'commerce:mark-as-reported' ) : t( 'commerce:mark-as-not-reported' ),
		details: 'Excluding or including would affect reports on the Invoiss platform only',
		icon   : <AssignmentTurnedInRoundedIcon/>,
		onClick: async () => {
			const btnText = canBeUnSynced && !invoice.nonReport
				? `${t( 'commerce:mark-as-not-reported' )} & ${t( 'common:unsync' )}`
				: undefined;
			const value = await confirmDialog( {
				title: invoice.nonReport
					? t( 'commerce:mark-as-reported' )
					: t( 'commerce:mark-as-not-reported' ),
				message: invoice.nonReport
					? t( 'commerce:include-in-reports', { commerceType: toLower( invoice.type ) } )
					: t( 'commerce:exclude-from-reports', { commerceType: toLower( invoice.type ) } ),
				extraButtonText   : btnText,
				onExtraButtonClick: btnText ? async () => {
					await markStatusAction( invoice, {
						nonReport: true,
					}, 'Mark reported', true );
					enqueueSnackbar( t( 'common:unsync-success' ), { variant: 'success' } );
				} : undefined,
			} );
			if ( !value ) return;
			return await markStatusAction( invoice, {
				nonReport: invoice.nonReport === true
					? null
					: true,
			}, invoice.nonReport ? 'Mark reported' : 'Marked not reported', true );
			
		},
	}, editable && invoice.status !== 'PAID' && {
		name   : invoice.closed ? t( 'commerce:unmark-closed' ) : t( 'commerce:mark-as-closed' ),
		details: 'Marking it as closed, will consider this invoice as resolved',
		icon   : <EmailIcon/>,
		onClick: async ( e, closeMenu ) => {
			closeMenu();
			const btnText = canBeUnSynced && !invoice.closed
				? `${t( 'commerce:mark-as-closed' )} & ${t( 'common:unsync' )}`
				: undefined;
			const value = await confirmDialog( {
				title  : invoice.closed ? t( 'commerce:unmark-closed' ) : t( 'commerce:mark-as-closed' ),
				message: invoice.closed
					? t( 'commerce:you-want-to-unmark-closed' )
					: t( 'commerce:you-want-to-mark-closed' ),
				extraButtonText   : btnText,
				onExtraButtonClick: btnText ? async () => {
					await markStatusAction( invoice, { closed: !invoice.closed }, 'Marked Closed', true );
					enqueueSnackbar( t( 'common:unsync-success' ), { variant: 'success' } );
				} : undefined,
			} );
			if ( !value ) return;
			return await markStatusAction( invoice, { closed: !invoice.closed }, invoice.closed
				? 'Unmarked Closed'
				: 'Marked Closed' );
			
		},
	}, editable && invoice.status !== 'PAID' && {
		name   : invoice.voided ? t( 'commerce:unmark-voided' ) : t( 'commerce:mark-as-voided' ),
		details: invoice.voided
			? t( 'commerce:remove-voided-status-invoice' )
			: t( 'commerce:mark-this-invoice-as-voided' ),
		icon   : <AssignmentTurnedInRoundedIcon/>,
		onClick: async () => {
			const btnText = canBeUnSynced && !invoice.voided
				? `${t( 'commerce:mark-as-voided' )} & ${t( 'common:unsync' )}`
				: undefined;
			const value = await confirmDialog( {
				title: invoice.voided
					? t( 'commerce:unmark-voided' )
					: t( 'commerce:mark-as-voided' ),
				message: invoice.voided
					? t( 'commerce:you-want-to-unmark-voided' )
					: t( 'commerce:you-want-to-mark-as-voided' ),
				extraButtonText   : btnText,
				onExtraButtonClick: btnText ? async () => {
					await markStatusAction( invoice, { voided: true, nonReport: true }, 'Marked Voided', true );
					enqueueSnackbar( t( 'common:unsync-success' ), { variant: 'success' } );
				} : undefined,
			} );
			if ( !value ) return;
			return await markStatusAction( invoice, {
				voided   : invoice.voided ? null : true,
				nonReport: invoice.voided ? null : true,
			}, invoice.voided
				? 'Unmarked Voided'
				: 'Marked Voided' );
		},
	}, editable && invoice.status !== 'PAID' && {
		name   : invoice.collections ? t( 'commerce:unmark-collections' ) : t( 'commerce:mark-as-collections' ),
		details: invoice.collections
			? t( 'commerce:remove-collections-status-invoice' )
			: t( 'commerce:mark-this-invoice-as-collections' ),
		icon   : <AssignmentTurnedInRoundedIcon/>,
		onClick: async () => {
			const value = await confirmDialog( {
				title  : invoice.collections ? t( 'commerce:unmark-collections' ) : t( 'commerce:mark-as-collections' ),
				message: invoice.collections
					? t( 'commerce:you-want-to-unmark-collections' )
					: t( 'commerce:you-want-to-mark-collections' ),
			} );
			if ( !value ) return;
			return await mutateGraphQL<MutationInvoiceWriteArgs>( {
				mutation : InvoiceWrite,
				variables: {
					id    : invoice.id,
					method: invoice.collections ? 'Unmarked Collections' : 'Marked Collections',
					input : { collections: !invoice.collections },
				},
			} );
		},
	}, editable && invoice.status !== 'PAID' && {
		name   : invoice.writeOff ? t( 'commerce:unmark-write-off' ) : t( 'commerce:mark-as-write-off' ),
		details: invoice.writeOff
			? t( 'commerce:remove-write-off-status-invoice' )
			: t( 'commerce:mark-this-invoice-as-write-off' ),
		icon   : <AssignmentTurnedInRoundedIcon/>,
		onClick: async ( e, closeMenu ) => {
			closeMenu();
			const btnText = canBeUnSynced && !invoice.writeOff
				? `${t( 'commerce:mark-as-write-off' )} & ${t( 'common:unsync' )}`
				: undefined;
			const value = await confirmDialog( {
				title: invoice.writeOff
					? t( 'commerce:unmark-write-off' )
					: t( 'commerce:mark-as-write-off' ),
				message: invoice.writeOff
					? t( 'commerce:you-want-to-unmark-write-off' )
					: t( 'commerce:you-want-to-mark-as-write-off' ),
				extraButtonText   : btnText,
				onExtraButtonClick: btnText ? async () => {
					await markStatusAction( invoice, { writeOff: !invoice.writeOff }, 'Marked Write Off', true );
					enqueueSnackbar( t( 'common:unsync-success' ), { variant: 'success' } );
				} : undefined,
			} );
			if ( !value ) return;
			return await markStatusAction( invoice, { writeOff: !invoice.writeOff }, invoice.writeOff
				? 'Unmarked Write Off'
				: 'Marked Write Off' );
		},
	}, editable && invoice.status !== 'PAID' && {
		name   : invoice.pastDue ? t( 'commerce:unmark-past-due' ) : t( 'commerce:mark-as-past-due' ),
		details: invoice.pastDue
			? t( 'commerce:remove-past-due-status-invoice' )
			: t( 'commerce:mark-this-invoice-as-past-due' ),
		icon   : <AssignmentTurnedInRoundedIcon/>,
		onClick: async () => {
			const value = await confirmDialog( {
				title  : invoice.pastDue ? t( 'commerce:unmark-past-due' ) : t( 'commerce:mark-as-past-due' ),
				message: invoice.pastDue
					? t( 'commerce:you-want-to-unmark-past-due' )
					: t( 'commerce:mark-this-invoice-as-past-due' ),
			} );
			if ( !value ) return;
			return await mutateGraphQL<MutationInvoiceWriteArgs>( {
				mutation : InvoiceWrite,
				variables: {
					id    : invoice.id,
					method: invoice.pastDue ? 'Unmarked Past Due' : 'Marked Past Due',
					input : { pastDue: !invoice.pastDue },
				},
			} );
		},
	} ];
}
