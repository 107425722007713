import EnhancedDisplay from '@/components/enhancedDisplay';
import Form from '@/components/form';
import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import StyledImage from '@/components/styledImage';
import { mutateGraphQL } from '@/data/apollo';
import { PurchaseWrite } from '@/data/commerce/purchase.graphql';
import currencyFormat from '@/helpers/currencyFormat';
import idPick from '@/helpers/idPick';
import VendorSelect from '@/pages/formSelects/vendorSelect';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModalControls } from '@/providers/modal';
import { LineItem, Location, Menu, MutationPurchaseWriteArgs } from '@/types/schema';
import { reqSSE } from '@/utils/reqSSE';
import { Button, Paper, Stack, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function PurchaseForVendor( { lineItems, location, goToPreviousStep }: {
	lineItems: LineItem[],
	location: Location,
	goToPreviousStep: () => void
} ) {
	const { t } = useTranslation();
	const { staff } = useUserInfo();
	const { closeModal } = useModalControls();
	const router = useRouter();
	const { enqueueSnackbar } = useSnackbar();
	
	const [ progress, setProgress ] = useState( 0 );
	const showSnackAndRedirect = async ( purchaseId: string ) => {
		enqueueSnackbar( 'Purchase created successfully', { variant: 'success' } );
		closeModal();
		await router.push( `/dashboard/commerce/purchases/${purchaseId}` );
	};
	
	const onCloverSave = async ( purchaseId: string ) => {
		
		await reqSSE( `/api/user/clover/updatePurchaseInventoryStock?id=${purchaseId}`, setProgress );
		
		await showSnackAndRedirect( purchaseId );
	};
	
	return (
		<Form<{ vendor: Menu }>
			initialValues={{ vendor: null }}
			onSubmit={async ( values ) => {
				const { purchaseWrite } = await mutateGraphQL<MutationPurchaseWriteArgs>( {
					mutation : PurchaseWrite,
					variables: {
						customNumber: Boolean( staff.company.metadata?.customPurchaseNumber ),
						method      : 'New Purchase',
						input       : {
							staff          : staff?.id,
							received       : true,
							companyLocation: location?.id || null,
							serviceDate    : new Date(),
							menu           : values.vendor?.id || null,
							lineItems      : lineItems.map( ( lineItem ) => ( {
								...idPick( lineItem, [
									'name',
									'price',
									'image',
									'description',
									'unit',
									'externalId',
									'metadata',
								] ),
								receivedQuantity: lineItem.receivedQuantity || 1,
								quantity        : 1,
								code            : lineItem.code ?? undefined,
								uom             : lineItem.uom.id ?? undefined,
								item            : lineItem.item?.id ?? undefined,
							} ) ),
						},
					},
				} );
				
				// update item stock
				if ( purchaseWrite ) await onCloverSave( purchaseWrite.id );
				
			}}>
			{( formik ) => (
				<Stack>
					<Stack
						component={Paper}
						direction='row'
						alignItems='end'
						spacing={2}
						my={1}
						width={{ xs: '100%' }}
						sx={{ p: 1 }}>
						<VendorSelect
							sx={{ width: 300 }}
							name='vendor'
							variables={{ options: { limit: 50, filter: { active: true } } }}
							onAdd={undefined}
						/>
					</Stack>
					<EnhancedDisplay
						hasPagination
						selectable
						title={`${t( 'common:item' )}`}
						extraData={lineItems}
						data={lineItems as LineItem[]}
						pageSectionProps={{
							hide                  : true,
							primaryTypographyProps: { variant: 'h5' },
							boxProps              : { sx: { '.MuiListItem-root': { mb: 0, pt: 0 } } },
						}}
						emptyComponent={(
							<Typography
								textAlign='center'
								color='text.secondary'
								py={1}>
								{t( 'common:nothing-to-display' )}
							</Typography>
						)}
						listProps={{
							renderRow: ( item ) => {
								const selectedUom = item?.uom;
								return (
									<Stack direction='row'>
										<Typography key='name'>
											{`${item.name} (${selectedUom?.name})`}
										</Typography>
										<Typography key='sku'>
											{selectedUom?.sku || '-'}
										</Typography>
										<Typography key='cost'>
											{currencyFormat( item.cost || selectedUom?.cost || undefined )}
										</Typography>
										<Typography key='quantity'>
											{item.quantity || selectedUom?.quantity || '-'}
										</Typography>
										<Typography key='stock'>
											{selectedUom?.quantity || '-'}
										</Typography>
										<Typography key='received'>
											{item?.receivedQuantity || '-'}
										</Typography>
									</Stack>
								);
							},
						}}
						tableProps={{
							hover  : false,
							headers: [ 'Name',
							             'Unit',
							             'Code',
							             'Sku',
							             'Cost',
							             'LineItem Quantity',
							             'Quantity On Hand',
							             'Quantity to Add' ],
							columns: ( item ) => {
								const selectedUom = item?.uom;
								return [
									<Typography key='name'>
										{item.name}
									</Typography>,
									<Typography key='unit'>
										{selectedUom?.name}
									</Typography>,
									<Typography key='code'>
										{item?.code || selectedUom?.code || '-'}
									</Typography>,
									<Typography key='sku'>
										{selectedUom?.sku || '-'}
									</Typography>,
									<Typography key='cost'>
										{currencyFormat( item?.cost || selectedUom?.cost || undefined )}
									</Typography>,
									<Typography key='quantity'>
										{item?.quantity || '-'}
									</Typography>,
									<Typography key='quantityOnHand'>
										{selectedUom?.quantity || '-'}
									</Typography>,
									<Typography key='quantityToAdd'>
										{item?.receivedQuantity || '-'}
									</Typography>,
								];
							},
							cellProps: [
								{ align: 'left' },
								{ align: 'left' },
								{ align: 'left' },
								{ align: 'left' },
								{ align: 'left' },
								{ align: 'left' },
								{ align: 'left' },
								{ align: 'left' },
							],
						}}
					/>
					<Stack sx={{ mt: 1 }} direction='row' spacing={1}>
						<Button
							variant='outlined'
							onClick={() => goToPreviousStep()}>
							Back
						</Button>
						<AsyncLoadingButton
							variant='contained'
							color='primary'
							progress={progress}
							disabled={lineItems.length === 0}
							loading={formik.isSubmitting}
							onClick={async () => await formik.submitForm()}>
							<StyledImage
								alt='clover-pos-image'
								src='/images/clover-icon.png'
								width='20px'
								height='20px'
								sx={{ mr: 1 }}
							/> Receive and update stocks
						</AsyncLoadingButton>
					</Stack>
				</Stack>
			)}
		</Form>
	);
	
}
