import FormGraphQLAutocomplete from '@/components/form/fields/formGraphQLAutocomplete';
import { FormGraphQLAutoCompleteProps } from '@/components/form/fields/types';
import { ModalFormWrapper } from '@/components/form/modal';
import { useGraphQL } from '@/data';
import { LocationsRead } from '@/data/management/location.graphql';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import type { Location, QueryLocationsReadArgs } from '@/types/schema';
import { MenuItem } from '@mui/material';
import { useField } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LocationForm from '../dashboard/management/locations/form';

export function useLocations( disabled?: boolean, variables?: QueryLocationsReadArgs ): [ Location[], boolean ] {
	const { staff } = useUserInfo();
	const isOwner = usePermissions( [ 'OWNER' ] );
	const isAdmin = usePermissions( [ 'ADMIN' ] );
	
	// if admin or owner and staff has no locations, return all locations
	const { data, isFetching } = useGraphQL<QueryLocationsReadArgs>( {
		queryKey: [ 'location' ],
		query   : LocationsRead,
		variables,
	}, {
		enabled         : !disabled && Boolean( staff ) && ( isOwner || isAdmin ) && !staff.locations.length,
		keepPreviousData: true,
	} );
	
	const [ firstFetching, setFirstFetching ] = useState( true );
	
	useEffect( () => {
		if ( !isFetching ) setFirstFetching( false );
	}, [ isFetching ] );
	
	if ( ( isOwner || isAdmin ) && !staff.locations.length ) {
		return [ data.locationsRead?.items ?? [], firstFetching ];
	} else {
		return [ staff?.locations, false ];
	}
}

export function useCloverLocations( disabled?: boolean, variables?: QueryLocationsReadArgs ): [ Location[], boolean ] {
	const { staff } = useUserInfo();
	
	const { data, isFetching } = useGraphQL<QueryLocationsReadArgs>( {
		queryKey : [ 'location' ],
		query    : LocationsRead,
		variables: variables || { options: { filter: { gateway: { external: 'CLOVER', active: true } } } },
	}, { enabled: !disabled && Boolean( staff ), keepPreviousData: true } );
	
	const [ firstFetching, setFirstFetching ] = useState( true );
	
	useEffect( () => {
		if ( !isFetching ) setFirstFetching( false );
	}, [ isFetching ] );
	
	return [ data.locationsRead?.items ?? [], firstFetching ];
	
}

export default function LocationSelect( {
	removeCreate,
	onlyGateway,
	onlyClover,
	...props
}: {
	removeCreate?: boolean,
	onlyGateway?: boolean,
	onlyClover?: boolean
} & Omit<FormGraphQLAutoCompleteProps<Location, QueryLocationsReadArgs>, 'query' | 'queryKey'> ) {
	const creatable = usePermissions( permissions.locations.write );
	const { showModal } = useModal();
	const [ , { value = [] }, { setValue } ] = useField( props.name );
	const { t } = useTranslation();
	
	return (
		<FormGraphQLAutocomplete<Location>
			label={t( 'management:location' )}
			queryKey={[ 'locations' ]}
			query={LocationsRead}
			variables={onlyGateway || onlyClover ? {
				options: {
					filter: {
						gateway: {
							active  : true,
							external: onlyClover
								? 'CLOVER'
								: undefined,
						},
					},
				},
			} : undefined}
			isOptionEqualToValue={( option, value ) => option.id === value?.id}
			getOptionLabel={( option: Location ) => option?.name || option?.address?.line1}
			renderOption={( props, option ) => {
				const { key, ...otherProps } = props;
				return (
					<MenuItem key={key} {...otherProps}>
						{option.name || option.address?.line1}
					</MenuItem>
				);
			}}
			onAdd={!removeCreate && creatable ? () => showModal( LocationForm, { maxWidth: 'sm' }, {
				Wrapper : ModalFormWrapper,
				onSubmit: ( address ) => setValue( props.multiple ? [ ...value, address ] : address ),
			} ) : undefined}
			{...props}
			onChange={( _e, value, reason ) => {
				if ( props?.onChange ) {
					return props?.onChange?.( _e, value, reason );
				}
				if ( Array.isArray( value ) && value.length === 0 || !value ) return;
				const newLocation = Array.isArray( value ) ? value[ value.length - 1 ] : value;
				if ( typeof newLocation === 'string' ) return;
			}}
		/>
	);
}
