/* eslint-disable react/jsx-key */
import { scrollLeftAtom, scrollRightAtom } from '@/hooks/useScroll';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import type { TableRowProps, Theme } from '@mui/material';
import { Collapse, IconButton, TableBody as _TableBody, TableCell, TableRow, useMediaQuery } from '@mui/material';
import { useAtomValue } from 'jotai/index';
import { isEmpty } from 'lodash-es';
import { useRouter } from 'next/router';
import { Fragment, ReactNode, useEffect, useState } from 'react';
import type { TableInstance } from 'react-table';
import type { ActionProps } from '../actions';
import Actions from '../actions';
import CustomCheckBox from '../customCheckBox';
import { ActionType } from './index';

export default function TableBody( {
	table,
	rowRoute,
	onRowClick,
	rowProps,
	showCheckBox,
	expandedComponent,
	useActions,
	pageRowsSelectedOnLoad,
	selectedRows,
	setSelectedRows,
	actionCellWidth,
}: {
	table: TableInstance<any>,
	rowRoute: ( row ) => string,
	onRowClick: ( row ) => void,
	rowProps: ( row ) => TableRowProps,
	showCheckBox: boolean,
	expandedComponent: ( row ) => ReactNode,
	useActions: ( row ) => ActionProps[] | ActionType,
	pageRowsSelectedOnLoad?: boolean,
	selectedRows?: any[],
	setSelectedRows?: ( rows: any[] ) => void,
	actionCellWidth?: number
} ) {
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const router = useRouter();
	const selectedRowIds = Object.keys( table.state.selectedRowIds );
	const hasSelectedRows = !isEmpty( selectedRowIds );
	const [ selected, setSelected ] = useState( false );
	
	const totalSelectedFlatRows = table.selectedFlatRows.map( ( row ) => row.original );
	const totalSelectedRowIds = Object.keys( table.state.selectedRowIds );
	
	// check/uncheck check boxes of the first page on page load
	useEffect( () => {
		if ( pageRowsSelectedOnLoad !== undefined ) {
			// @ts-ignore
			table.toggleAllPageRowsSelected( pageRowsSelectedOnLoad );
		}
		
	}, [] );
	
	useEffect( () => {
		if ( selected && setSelectedRows ) {
			const totalSelectedRows = [ ...selectedRows || [], ...totalSelectedFlatRows ];
			const totalCheckedRows = totalSelectedRowIds.map( ( id ) => totalSelectedRows.find( ( row ) => row.id === id ) );
			setSelectedRows?.( totalCheckedRows );
			setSelected( false );
		}
	}, [ selected ] );
	
	return (
		<_TableBody {...table.getTableBodyProps()} component='div'>
			{table.page.map( ( row ) => {
				table.prepareRow( row );
				const { key, ...restRowProps } = row.getRowProps();
				
				return (
					<Fragment key={key}>
						<TableRow
							hover={Boolean( rowRoute ) && !hasSelectedRows}
							onClick={( e ) => {
								e.stopPropagation();
							}}
							{...rowProps?.( row.original )}
							{...restRowProps}
							component='div'>
							{showCheckBox && (
								<CheckBoxCell
									setSelected={setSelected}
									selectedRowIds={selectedRowIds}
									row={row}
								/>
							)}
							{Boolean( expandedComponent ) && (
								<TableCell component='div' sx={{ pr: 0 }}>
									<IconButton
										{...row.getToggleRowExpandedProps()}
										onClick={( e ) => {
											e.stopPropagation();
											// @ts-ignore
											row.getToggleRowExpandedProps().onClick();
										}}>
										{row.isExpanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
									</IconButton>
								</TableCell>
							)}
							{row.cells.map( ( cell, index ) => (
								<TableCell
									component='div'
									sx={{ cursor: 'pointer' }}
									onClick={() => {
										onRowClick?.( row.original );
										return rowRoute && rowRoute( row.original )?.length && !hasSelectedRows && router.push( rowRoute( row.original ) );
									}}
									{...cell.getCellProps()}
									key={index}>
									{cell.render( 'Cell' ) as ReactNode}
								</TableCell>
							) )}
							{Boolean( useActions ) && (
								<ActionCell
									actions_={useActions}
									row={row}
									isMobile={isMobile}
									actionCellWidth={actionCellWidth}
								/>
							)}
						</TableRow>
						<Collapse mountOnEnter in={row.isExpanded}>
							<TableRow component='div' sx={{ display: 'inherit', bgcolor: 'background.default' }}>
								<TableCell className='expandedCell' component='div' colSpan={table.visibleColumns.length}>
									{expandedComponent?.( row.original )}
								</TableCell>
							</TableRow>
						</Collapse>
					</Fragment>
				);
			} )}
		</_TableBody>
	);
}

function CheckBoxCell( { setSelected, selectedRowIds, row } ) {
	const isMoreThan5PercentAwayLeft = useAtomValue( scrollLeftAtom );
	return (
		<TableCell
			component='div'
			sx={{
				width   : '50px',
				position: 'sticky',
				left    : 0,
				zIndex  : 3,
				bgcolor : 'background.default',
				filter  : isMoreThan5PercentAwayLeft
					? 'drop-shadow(rgba(0, 0, 0, 0.3) 2px 10px 6px)'
					: 'none',
				transition: 'filter 0.3s ease-out',
			}}>
			<CustomCheckBox
				disabled={selectedRowIds.length >= 100 && !row.isSelected}
				onClick={( e ) => {
					e.stopPropagation();
					setSelected( true );
				}}
				{...row.getToggleRowSelectedProps()}
			/>
		</TableCell>
	);
}

function ActionCell( { actions_, row, isMobile, actionCellWidth } ) {
	const actions = actions_( row.original )?.items || actions_( row.original );
	const isMoreThan5PercentAwayRight = useAtomValue( scrollRightAtom );
	
	return (
		<TableCell
			align='right'
			component='div'
			sx={{
				width     : actionCellWidth,
				position  : 'sticky',
				right     : 0,
				zIndex    : 3,
				bgcolor   : 'background.default',
				transition: 'filter 0.3s ease-out',
				filter    : isMoreThan5PercentAwayRight
					? 'drop-shadow(rgba(0, 0, 0, .3) -2px 9px 4px)'
					: 'none',
			}}>
			<Actions items={actions} max={actions_( row.original )?.max || isMobile ? 1 : 2}/>
		</TableCell>
	);
}
