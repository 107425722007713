import { FormikContextType, FormikProvider, useField, useFormikContext } from 'formik';
import { debounce } from 'lodash-es';
import { useCallback, useEffect } from 'react';
import type { FormattedTextFieldProps } from '../../formattedTextField';
import FormattedTextField from '../../formattedTextField';

// Inner component that uses Formik context
function FormTextFieldInner( {
	name,
	label,
	format,
	includeNegative,
	onBlur,
	skipLabelError,
	eager = false,
	debounceTime = 500,
	...props
}: {
	name: string,
	eager?: boolean,
	debounceTime?: number,
	format?: ( value ) => any,
	includeNegative?: boolean,
	skipLabelError?: boolean
} & FormattedTextFieldProps ) {
	const [ field, meta, { setValue, setTouched } ] = useField( name );
	const { error } = meta;
	const touched = eager ? true : meta.touched;
	const formik = useFormikContext();
	
	useEffect( () => {
		if ( formik.isSubmitting ) {
			setTouched( true );
		}
	}, [ formik.submitCount ] );
	
	const handleChange = useCallback( debounce( ( e ) => {
		setValue( format ? format( e.target.value ) : e.target.value );
	}, debounceTime ), [] );
	
	return (
		<FormattedTextField
			{...field}
			label={!skipLabelError && touched && error || label}
			value={String( typeof field.value === 'number'
				? includeNegative ? field.value : Math.abs( field.value )
				: field.value ?? '' )}
			error={touched && Boolean( error )}
			isError={touched && Boolean( error )}
			onBlur={( e ) => {
				handleChange.flush();
				onBlur?.( e );
			}}
			onChange={handleChange}
			{...props}
		/>
	);
}

export default function FormTextField( {
	formik,
	...props
}: {
	formik?: FormikContextType<any>
} & Parameters<typeof FormTextFieldInner>[0] ) {
	if ( formik ) {
		return (
			<FormikProvider value={formik}>
				<FormTextFieldInner {...props}/>
			</FormikProvider>
		);
	}
	
	return <FormTextFieldInner {...props}/>;
}
