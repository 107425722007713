import { safeFormatInTimeZone } from '@/helpers/safeFormat';
import { isEstimateType, isInvoiceType } from '@/helpers/useIsEstimateType';
import { CommerceStatus } from '@/pages/dashboard/commerce/components/statuses';
import { getInvoiceBalance } from '@/pages/dashboard/commerce/multiPayments/utils';
import { Order } from '@/types/schema';
import {
	Box,
	Chip,
	Divider,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { toLower, upperFirst } from 'lodash-es';
import React, { Fragment } from 'react';
import currencyFormat from '../helpers/currencyFormat';
import PageLinkComponent from './page/linkComponent';

export default function ReminderCommercesAndTotal( { invoices, timezone }: { invoices: Order[], timezone?: string } ) {
	
	const grandTotal = invoices.reduce( ( sum, invoice ) => sum + invoice.grandTotal, 0 );
	const totalBalance = invoices.reduce( ( sum, invoice ) => sum + getInvoiceBalance( invoice ), 0 );
	
	const data = invoices[ 0 ];
	
	const showBalance = [ 'INVOICE', 'ACCOUNT', 'STATEMENT' ].includes( data.type );
	
	return (
		<Fragment>
			<Table sx={{ '.MuiTableCell-root': { border: '0 !important', py: .5, px: 0 } }}>
				<TableBody>
					<TableRow>
						{showBalance && (
							<TableCell width='70%' valign='top' sx={{ display: 'flex' }}>
								<Box sx={{ fontSize: 30, fontWeight: 'bold' }}>
									{currencyFormat( totalBalance || 0 )}
								</Box>
							</TableCell>
						)}
					</TableRow>
				</TableBody>
			</Table>
			{showBalance && <Divider sx={{ my: 2 }}/>}
			<Table sx={{ '.MuiTableCell-root': { border: '0 !important', py: .5, px: 0 } }}>
				<TableBody>
					{data?.client && (
						<TableRow>
							<TableCell sx={{ width: 120 }} align='left'>
								Company
							</TableCell>
							<TableCell align='right'>
								{data?.client?.name || data?.client?.contact}
							</TableCell>
						</TableRow>
					)}
					<TableRow>
						<TableCell sx={{ width: 120 }} align='left'>
							From
						</TableCell>
						<TableCell align='right'>
							{data.company?.name || data.company?.contact}
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
			<Divider sx={{ my: 2 }}/>
			<Typography sx={{ mb: 1, color: 'text.secondary', fontWeight: 500 }}>
				{upperFirst( toLower( data.type ) )}s
			</Typography>
			<TableContainer
				component={Paper}
				variant='bordered'
				sx={{ td: { borderColor: 'divider', px: '10px !important', py: '.2 !important' }, mb: 1 }}>
				<Table sx={{ th: { py: '7px !important', fontSize: '12px !important' } }}>
					<TableHead sx={{ bgcolor: 'divider', p: 1 }}>
						<TableRow>
							<TableCell align='left' sx={{ borderBottom: 0, p: '10px !important' }}>
								<Typography p={1} variant='bold'>
									#
								</Typography>
							</TableCell>
							<TableCell align='left' sx={{ borderBottom: 0, p: '10px !important' }}>
								Status
							</TableCell>
							{data.type === 'INVOICE' ? (
								<TableCell
									align='center'
									sx={{
										borderBottom: 0,
										p           : '10px !important',
										minWidth    : 120,
									}}>
									Due date
								</TableCell>
							) : (
								<TableCell
									align='center'
									sx={{ borderBottom: 0, p: '10px !important', minWidth: 120 }}>
									Expires
								</TableCell>
							)}
							<TableCell align='center' sx={{ borderBottom: 0, p: '10px !important' }}>
								Total
							</TableCell>
							{showBalance
								&& (
									<TableCell align='right' sx={{ borderBottom: 0, p: '10px !important' }}>
										Remaining
									</TableCell>
								)}
						</TableRow>
					</TableHead>
					<TableBody sx={{ td: { py: '5px !important', fontSize: '12px !important' } }}>
						{invoices.slice( 0, 100 ).map( ( invoice, index ) => {
							const balance = getInvoiceBalance( invoice );
							
							return (
								<TableRow key={index}>
									<TableCell align='left' sx={{ whiteSpace: 'nowrap', py: 0.2 }}>
										<Typography
											color='secondary'
											component={PageLinkComponent}
											target='_blank'
											href={`${process.env.NEXT_PUBLIC_SITE_URL}/p/${invoice.id}/${isEstimateType( invoice.type )
												? 'estimate'
												: isInvoiceType( invoice.type ) ? 'invoice' : `${toLower( invoice.type )}`}`}
											py={1}>
											{invoice.metadata?.customNumber || invoice.number}
										</Typography>
									</TableCell>
									<TableCell align='center'>
										<Chip
											variant='alpha'
											color={!invoice.standingActive
												? CommerceStatus[ invoice.type ][ invoice?.status ]?.color
												: 'success'}
											label={CommerceStatus[ invoice.type ][ invoice.status ]?.clientView}
										/>
									</TableCell>
									<TableCell align='center' sx={{ py: 0.2, minWidth: 120 }}>
										{safeFormatInTimeZone( invoice.dueDate, 'PP', timezone )}
									</TableCell>
									<TableCell align='center' sx={{ py: 0.2 }}>
										{currencyFormat( invoice.grandTotal )}
									</TableCell>
									{showBalance && (
										<TableCell align='right' sx={{ py: 0.2 }}>
											{currencyFormat( balance )}
										</TableCell>
									)}
								</TableRow>
							);
						} )}
					</TableBody>
				</Table>
				{invoices.length > 100 && (
					<Table sx={{ '.MuiTableCell-root': { border: 0, py: .5, px: 0 } }}>
						<TableBody>
							<TableRow>
								<TableCell>
									<Box sx={{ textAlign: 'center', pb: 1 }}>
										<Typography
											component='a'
											target='_blank'
											sx={{ textDecoration: 'none', color: 'primary.main' }}
											href={`${process.env.NEXT_PUBLIC_SERVER_URL}/login?redirect=${process.env.NEXT_PUBLIC_SERVER_URL}/client`}>
											View All..
										</Typography>
									</Box>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				)}
				{data.type === 'INVOICE' && (
					<Table sx={{ td: { py: '2px !important', fontSize: '12px !important', bgcolor: 'divider' } }}>
						<TableBody sx={{ '&:last-child td, &:last-child th': { border: '0px !important' }, 'td': { py: 1 } }}>
							<TableRow key='sub'>
								<TableCell sx={{ minWidth: 120 }}></TableCell>
								<TableCell sx={{ minWidth: 120 }}></TableCell>
								<TableCell align='right'>
									<Typography py={1}>
										{currencyFormat( grandTotal )}
									</Typography>
								</TableCell>
								<TableCell align='right' sx={{ fontWeight: 'bold' }}>
									{currencyFormat( totalBalance )}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				)}
			</TableContainer>
		</Fragment>
	);
}
