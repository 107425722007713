export const loadingTemplate = `
<!DOCTYPE html>
<html>
<head>
    <title>Loading CardConnect Application...</title>
    <style>
        body {
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100vh;
            margin: 0;
            background-color: #f5f5f5;
            color: #333;
        }
        .logo {
            width: 200px;
            margin-bottom: 30px;
        }
        .loader {
            border: 4px solid #f3f3f3;
            border-radius: 50%;
            border-top: 4px solid #1976d2;
            width: 40px;
            height: 40px;
            animation: spin 1s linear infinite;
            margin-bottom: 20px;
        }
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
        .message {
            font-size: 18px;
            margin-bottom: 10px;
        }
        .sub-message {
            font-size: 14px;
            color: #666;
        }
    </style>
</head>
<body>
    <img src="/images/invoiss-logoblack.png" alt="Invoiss Logo" class="logo" />
    <div class="loader"></div>
    <div class="message">Preparing Your CardConnect Application</div>
    <div class="sub-message">Please wait while we redirect you...</div>
</body>
</html>
`;
