import FormMaskField from '@/components/form/fields/maskedField';
import FormSelect from '@/components/form/fields/select';
import FormTextField from '@/components/form/fields/textField';
import { Grid, MenuItem, Typography } from '@mui/material';
import React from 'react';
import * as yup from 'yup';

export const ownerInfoValidationSchema = yup.object().shape( {
	ownership: yup.object().shape( {
		owner: yup.object().shape( {
			ownerName       : yup
				.string()
				.required( 'Enter the owner\'s name' )
				.max( 64, 'Name is too long' )
				.test(
					'hasTwoParts',
					'Name must include a first name and a last name, separated by a space',
					( value ) => {
						if ( !value ) return false;
						const parts = value.split( ' ' );
						return parts.length === 2;
					},
				)
				.test(
					'firstNameValid',
					'First name must be at least 2 characters long and contain only letters',
					( value ) => {
						if ( !value ) return false;
						const firstName = value.split( ' ' )[ 0 ];
						return /^[a-zA-Z]{2,}$/.test( firstName );
					},
				)
				.test(
					'lastNameValid',
					'Last name must be at least 2 characters long and contain only letters, with at most one hyphen or apostrophe',
					( value ) => {
						if ( !value ) return false;
						const lastName = value.split( ' ' )[ 1 ];
						if ( !/^[a-zA-Z]{2,}$/.test( lastName ) || !lastName ) return false;
						const hyphenCount = ( lastName.match( /-/g ) || [] ).length;
						const apostropheCount = ( lastName.match( /['`]/g ) || [] ).length;
						return hyphenCount <= 1 && apostropheCount <= 1;
					},
				),
			ownerEmail      : yup
				.string()
				.email( 'Enter a valid email' )
				.required( 'Enter your email address' )
				.max( 64, 'Owner email is too long' ),
			ownerPhone      : yup
				.string()
				.required( 'Enter your phone number' )
				.max( 64, 'Owner phone is too long' ),
			ownerMobilePhone: yup
				.string()
				.required( 'Enter your mobile number' )
				.max( 64, 'Owner mobile phone is too long' ),
			ownerTitle      : yup
				.string()
				.required( 'Enter your title' )
				.max( 64, 'Owner title is too long' ),
		} ),
	} ),
} );

export default function OwnerInfo() {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant='h4' fontWeight='500'>
					Owner Information
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<FormTextField
					fullWidth
					name='ownership.owner.ownerName'
					label='Owner Name'
				/>
			</Grid>
			<Grid item xs={12}>
				<FormTextField
					fullWidth
					name='ownership.owner.ownerEmail'
					label='Owner Email'
				/>
			</Grid>
			<Grid item xs={12}>
				<FormMaskField
					name='ownership.owner.ownerPhone'
					mask='999-999-9999'
					label='Owner Phone'
				/>
			</Grid>
			<Grid item xs={12}>
				<FormMaskField
					name='ownership.owner.ownerMobilePhone'
					mask='999-999-9999'
					label='Owner Mobile Phone'
				/>
			</Grid>
			<Grid item xs={12}>
				<FormSelect
					fullWidth
					name='ownership.owner.ownerTitle'
					label='Owner Title'>
					<MenuItem value='OWNER'>OWNER</MenuItem>
					<MenuItem value='PRESIDENT'>PRESIDENT</MenuItem>
					<MenuItem value='VICE_PRESIDENT'>VICE PRESIDENT</MenuItem>
					<MenuItem value='SECRETARY'>SECRETARY</MenuItem>
					<MenuItem value='TREASURER'>TREASURER</MenuItem>
					<MenuItem value='CEO'>CEO</MenuItem>
					<MenuItem value='CFO'>CFO</MenuItem>
					<MenuItem value='COO'>COO</MenuItem>
				</FormSelect>
			</Grid>
		</Grid>
	);
}
