import { axiosClient } from '@/data';
import { mutateGraphQL } from '@/data/apollo';
import { isEstimateType, isInvoiceType } from '@/helpers/useIsEstimateType';
import { EmailValuesType } from '@/pages/dashboard/commerce/invoices/syncAndPay';
import { EmailType, MutationCommerceWriteArgs, Order, Staff } from '@/types/schema';
import { isEmailValid } from '@/utils/isValidEmail';
import { gql } from '@apollo/client';
import { Theme } from '@mui/material';
import axios from 'axios';
import { isEmpty, toLower } from 'lodash-es';

export const sendCommerceEmail = async (
	invoice: Order,
	timezone: string = 'America/Chicago',
	values: EmailValuesType,
	sendVia: EmailType,
	staff?: Staff,
	theme?: Theme,
) => {
	const dataColor = invoice.metadata?.documentLayout?.color || staff?.company.metadata?.documentLayout?.color;
	const themeBasedLayoutColor = !isEmpty( dataColor?.light ) ? dataColor?.light?.slice( 1 ) : undefined;
	
	// get pdf url
	const { data } = await axios.get( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/preview/pdf/${invoice.id}/${isEstimateType( invoice.type )
		? 'estimate'
		: isInvoiceType( invoice.type )
			? 'invoice'
			: `${toLower( invoice.type )}`}?getUrl=true&timezone=${timezone}${themeBasedLayoutColor
		? `&themeBasedLayoutColor=${themeBasedLayoutColor}&themeMode=${theme?.palette?.mode}`
		: ''}` )
		.catch( ( e ) => {
			console.error( e );
			return { data: { url: null } };
		} );
	
	await axiosClient.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/emails/${invoice.id}/invoiceOrderEstimate`, {
		...values,
		timezone,
		url    : values.toggleEmailPdfUrl ? data?.url : undefined,
		sendVia: sendVia,
		staffId: staff?.id,
	} );
	
	await mutateGraphQL<MutationCommerceWriteArgs>( {
		mutation: gql`mutation CommerceWrite_306e($id: String, $externalId: String, $method: String,$customNumber: Boolean, $input: OrderValidator, $remove: Boolean) {
			commerceWrite(id: $id, externalId: $externalId, method: $method, customNumber: $customNumber, input: $input, remove: $remove) {
				id
			}
		}`,
		variables: {
			id    : invoice.id,
			method: 'Send Via Email',
			input : {
				sent    : true,
				metadata: {
					...invoice.metadata,
					sentDates: [ ...!isEmpty( invoice.metadata?.sentDates )
						? [ ...invoice.metadata.sentDates, new Date() ]
						: [ new Date() ] ],
				},
			},
		},
	} );
};

export const disableEmailButton = ( formik: any ) => {
	const toEmails = formik.values.to;
	const ccEmails = formik.values.cc;
	const bccEmails = formik.values.bcc;
	const toValidateEmails = formik.values.validateToEmails;
	const ccValidateEmails = formik.values.validateCCEmails;
	const bccValidationEmail = formik.values.validateBccEmails;
	
	return isEmpty( toEmails ) || toValidateEmails.length > toEmails.length || ccValidateEmails.length > ccEmails.length
		|| bccEmails.length > bccValidationEmail.length
		|| toEmails.length === toValidateEmails.length && toValidateEmails.find( ( email: string ) => !isEmailValid( email ) )
		|| ccEmails.length === ccValidateEmails.length && ccValidateEmails.find( ( email: string ) => !isEmailValid( email ) )
		|| bccEmails.length === bccValidationEmail.length && bccValidationEmail.find( ( email: string ) => !isEmailValid( email ) );
};
