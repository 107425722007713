import Actions from '@/components/actions';
import { mutateGraphQL } from '@/data/apollo';
import { OrdersHouseAccountWrite, OrdersWrite } from '@/data/commerce/order.graphql';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import useGetDeviceInfo from '@/hooks/useGetDeviceInfo';
import MergeOptions from '@/modals/mergeOptionsModal';
import SelectStaffModal from '@/modals/selectStaff';
import { groupPdfDownload } from '@/pages/dashboard/commerce/components/sharedActionUtils';
import { stayOpen } from '@/pages/dashboard/commerce/components/tableHelpers';
import { EmailAllModal } from '@/pages/dashboard/commerce/components/utils';
import MultiPaymentsDrawer from '@/pages/dashboard/commerce/multiPayments/multiPaymentsDrawer';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useEvents } from '@/providers/event';
import { useModal } from '@/providers/modal';
import { MutationOrdersHouseAccountWriteArgs, MutationOrdersWriteArgs, Order } from '@/types/schema';
import { isSuperAdmin } from '@/utils/isSuperAdmin';
import {
	CallMerge as CallMergeIcon,
	CallSplit as CallSplitIcon,
	Delete as DeleteIcon,
	Email as EmailIcon,
	Payment as PaymentIcon,
	PeopleAlt as PeopleAltIcon,
	PictureAsPdf as PictureAsPdfIcon,
} from '@mui/icons-material';
import { type Theme, useMediaQuery } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';

export default function OrdersMultiActions( {
	orders,
	clearRows,
	selectedOrders,
}: {
	orders: string[],
	clearRows: () => void,
	selectedOrders: Order[]
} ) {
	const { showModal } = useModal();
	const { user } = useUserInfo();
	const { t } = useTranslation();
	const confirmDialog = useConfirmDialog();
	
	const event = useEvents();
	const editable = usePermissions( permissions.orders.write );
	const payable = usePermissions( permissions.payments.write );
	const creatable = usePermissions( permissions.orders.write );
	const { isCloverDevice } = useGetDeviceInfo();
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const isIpad = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'lg' ) );
	const isOwner = usePermissions( [ 'OWNER' ] );
	const isAdmin = usePermissions( [ 'ADMIN' ] );
	
	const superAdmin = isSuperAdmin( user );
	const invoiceWithNoClientEmail = selectedOrders.find( ( order ) => !order.client?.email );
	const hasPartiallyPaid = Boolean( selectedOrders.find( ( order ) => order.status === 'PARTIALLY_PAID' ) );
	const hasSameClientId = selectedOrders.find( ( invoice ) => invoice.client )?.client;
	const paidOrders = selectedOrders.find( ( invoice ) => [
		'CANCELLED',
		'PAID',
		'PENDING',
		'ACCOUNT' ].includes( invoice.status ) );
	
	return (
		<Actions
			separated
			max={isMobile ? 1 : isCloverDevice || isIpad ? 4 : 0}
			items={[
				creatable && orders.length > 1 && {
					name       : t( 'commerce:merge' ),
					buttonProps: { disabled: hasPartiallyPaid, color: 'success' },
					details    : hasPartiallyPaid
						? 'Partially paid orders cannot be merged.'
						: t( 'commerce:merge-multiple-orders-into-one' ),
					icon   : <CallMergeIcon/>,
					onClick: () => {
						showModal( MergeOptions, { maxWidth: 'sm' }, {
							type             : 'Order',
							clearRows        : clearRows,
							commerces        : orders,
							selectedCommerces: selectedOrders,
						} );
					},
				}, editable && {
					name   : t( 'commerce:un-merge' ),
					icon   : <CallSplitIcon/>,
					onClick: async () => {
						await mutateGraphQL<MutationOrdersWriteArgs>( {
							mutation : OrdersWrite,
							variables: {
								method : 'Unmerged',
								ids    : orders,
								options: { limit: orders.length },
								input  : { merged: null },
							},
						} );
						clearRows();
					},
					
				}, payable && !paidOrders && {
					name   : t( 'common:pay' ),
					icon   : <PaymentIcon/>,
					details: !hasSameClientId
						? 'You must select orders for the same client to pay all at once'
						: 'Pay all selected orders',
					buttonProps: { variant: 'outlined', disabled: !hasSameClientId },
					onClick    : () => showModal( MultiPaymentsDrawer, {
						variant: 'drawer',
						onClose: ( event, reason ) => stayOpen( event, reason ),
					}, { orders: selectedOrders, clearRows } ),
				}, editable && {
					name   : t( 'commerce:download-pdf' ),
					icon   : <PictureAsPdfIcon/>,
					onClick: async () => {
						await groupPdfDownload( selectedOrders, 'order' );
						clearRows();
					},
				}, ( isOwner || isAdmin ) && {
					name   : t( 'commerce:edit-placed-by' ),
					icon   : <PeopleAltIcon/>,
					onClick: () => showModal( SelectStaffModal, { maxWidth: 'sm' },
						{ type: 'order', clearRows, selectedRows: orders } ),
				}, selectedOrders.length <= 50 && {
					name       : 'Email All',
					icon       : <EmailIcon/>,
					buttonProps: { color: 'success' },
					details    : invoiceWithNoClientEmail
						? 'One of the orders does not have a client email.'
						: 'Each email will be sent individually',
					disabled: Boolean( invoiceWithNoClientEmail ),
					onClick : async () => {
						showModal( EmailAllModal, { maxWidth: 'sm' }, {
							selectedInvoices: selectedOrders,
							clearRows,
						} );
					},
				}, superAdmin && {
					name   : 'HA Id',
					icon   : <PeopleAltIcon/>,
					onClick: async () => {
						
						const accountOrders = selectedOrders.filter( ( order ) => order.client && !order.houseAccount );
						if ( isEmpty( accountOrders ) ) throw 'Select only orders with  client id and no house account id';
						const { ordersHouseAccountWrite } = await mutateGraphQL<MutationOrdersHouseAccountWriteArgs>( {
							mutation : OrdersHouseAccountWrite,
							variables: { ids: accountOrders.map( ( { id } ) => id ) },
						} );
						
						if ( ordersHouseAccountWrite ) event.emit( 'reload.graphqlQuery', true );
						clearRows();
					},
				}, editable && {
					name       : t( 'common:delete' ),
					buttonProps: { color: 'error' },
					icon       : <DeleteIcon/>,
					onClick    : async () => {
						const value = await confirmDialog( {
							title  : t( 'common:delete' ),
							message: t( 'common:delete-confirmation' ),
						} );
						if ( !value ) return;
						await mutateGraphQL<MutationOrdersWriteArgs>( {
							mutation : OrdersWrite,
							variables: {
								method : 'Deleted Orders',
								ids    : orders,
								options: { limit: orders.length },
								input  : { deletedAt: new Date() },
							},
						} );
						clearRows();
					},
				},
			]}
		/>
	);
}
