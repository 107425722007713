import SubscriptionModal from '@/baseline/subscription';
import { getSubscription } from '@/baseline/subscription/getSubscription';
import UpgradeIcon from '@/baseline/subscription/upgradeIcon';
import useSubscription from '@/baseline/subscription/useSubscription';
import useAccountType from '@/helpers/useAccountType';
import { useCompany } from '@/hooks/useSetCompanyInAtom';
import { useModal } from '@/providers/modal';
import { Button, Stack, Typography } from '@mui/material';

export default function TrialChip() {
	const { daysLeft } = useSubscription();
	const { company } = useCompany();
	const subscription = getSubscription( { subscriptions: company?.subscriptions } );
	const { showModal } = useModal();
	const isCloverAccount = useAccountType( 'CLOVER' );
	
	if ( isCloverAccount || !daysLeft || subscription?.tier.name !== 'Trial' ) return null;
	
	return (
		<Stack
			direction='row'
			alignItems='center'
			spacing={1}
			sx={{
				'p'                  : .5,
				'borderRadius'       : 2,
				'bgcolor'            : 'alpha.warning',
				'color'              : 'warning.main',
				'.MuiTypography-root': {
					fontWeight        : '500 !important',
					fontSize          : '12px !important',
					fontFamily        : '"Roboto Mono", monospace',
					fontVariantNumeric: 'tabular-nums',
				},
			}}>
			<UpgradeIcon/>
			<Typography>
				{daysLeft}
			</Typography>
			<Typography>
				Days left on trial
			</Typography>
			<Button
				variant='outlined'
				color='warning'
				onClick={() => showModal( SubscriptionModal, { variant: 'fullPageDialog' } )}>
				Upgrade
			</Button>
		</Stack>
	);
}
