import LargeChip from '@/components/largeChip';
import useAccountType from '@/helpers/useAccountType';
import { Button, Stack, Typography } from '@mui/material';

export default function InitialStep( { setActiveStep } ) {
	const isCloverAccount = useAccountType( 'CLOVER' );
	
	return (
		<Stack spacing={2}>
			{isCloverAccount && (
				<LargeChip
					label="Linking your bank account won't impact your existing Clover configuration. Your card processing will
						continue seamlessly through the Clover gateway. We'll simply add Bank Transfer as an additional payment
						method to your invoices, powered by CardConnect."
					color='success'
				/>
			)}
			<Typography textAlign='center'>
				(No monthly additional charges)
			</Typography>
			<Typography color='text.secondary'>
				To start accepting bank transfers, please complete the short form below. This will allow us to verify your
				business and set up your account with our payment processor, CardConnect.
			</Typography>
			<Button
				color='primary'
				variant='contained'
				size='large'
				onClick={() => setActiveStep( 1 )}>
				I'm new to CardConnect
			</Button>
			<Button
				color='primary'
				variant='text'
				size='large'
				onClick={() => setActiveStep( 5 )}>
				I have a CardConnect merchant ID
			</Button>
		</Stack>
	);
}
