import Attachment from '@/components/fileUploading/attachment';
import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import { axiosClient } from '@/data';
import idPick from '@/helpers/idPick';
import SignModal from '@/modals/sign';
import { ClientInfo } from '@/pages/p/commerce/clientInfoForm';
import { surchargeFeeAtom } from '@/pages/settings/cards';
import { getClientForMerchant } from '@/pages/tender/[id]/drawersActions/clientHelpers';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import { Client, GatewayBase, HouseAccount, Order, Payment } from '@/types/schema';
import postCloverMeteredBilling from '@/utils/api/postCloverMeteredBilling';
import {
	ArrowBackIos as ArrowBackIosIcon,
	Assignment as AssignmentIcon,
	Payment as PaymentIcon,
} from '@mui/icons-material';
import { Button, Stack, TextField } from '@mui/material';
import axios from 'axios';
import { useAtomValue } from 'jotai/index';
import { isEmpty, sortBy } from 'lodash-es';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { type ComponentType, type MutableRefObject, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloverPaymentDetails from '../payment/clover/details';
import { makePayment } from '../payment/helpers';
import SquarePaymentDetails from '../payment/square/details';
import StripePaymentDetails from '../payment/stripe/details';
import { getHAInvoiceLineItems, meteredBillingAndTracking, sendMessagesAfterPayment } from './utils';

export default function PaymentDetails( {
	method,
	cancel,
	confirm,
	hideSignature,
	client,
	amount, // for House Account only
	invoices,
	selectedClientPayment,
	houseAccount,
	dollarTip,
	tip,
	cardToken,
	cardType,
	cardFee = 0,
	paymentGateway,
}: {
	method,
	cancel,
	confirm,
	dollarTip?,
	tip?,
	hideSignature?: boolean,
	client?: ClientInfo,
	amount?: number,
	invoices: Order[],
	selectedClientPayment?: Payment,
	houseAccount?: HouseAccount,
	cardToken?: string,
	cardType?: string,
	cardFee: number,
	paymentGateway?: GatewayBase
} ) {
	const {
		      id,
		      gateway: _gateway,
		      companyLocation,
		      company,
		      client: orderClient,
		      staff : orderStaff,
	      } = invoices?.[ 0 ];
	
	const { showModal, closeModal } = useModal();
	const { enqueueSnackbar } = useSnackbar();
	const onSubmit = useRef<() => Promise<string>>();
	const router = useRouter();
	const route = router.route.split( '/' )[ 1 ];
	const isClientPage = route === 'p' || route === 'client';
	
	const gateway = paymentGateway || _gateway || companyLocation?.gateway || company.mainPayment;
	
	const [ checkNumber, setCheckNumber ] = useState( '' );
	const [ note, setNote ] = useState( '' );
	const [ invoiceNote, setInvoiceNote ] = useState( '' );
	const [ signature, setSignature ] = useState<string>( null );
	const { staff, user } = useUserInfo();
	const { t } = useTranslation();
	const surchargeFeePercent = useAtomValue( surchargeFeeAtom );
	const surchargePercent = ( method === 'card' || method?.includes( 'saved' ) ) && cardType !== 'debit'
		? surchargeFeePercent
		: 0;
	
	const applyCardFee = async ( newOrder: Order ) => {
		
		try {
			if ( ( method === 'card' || method.includes( 'saved' ) )
				&& cardType !== 'debit'
				&& cardFee > 0 ) {
				
				const { data } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/tempCardFee`, {
					id     : newOrder.id,
					company: company.id,
					cardFee,
				} );
				return data?.commerce;
			} else {
				return newOrder;
			}
		} catch {
			enqueueSnackbar( t( 'commerce:card-fee-not-applied' ), { variant: 'default' } );
			closeModal();
			return;
		}
	};
	
	// create a type ACCOUNT invoice and pay it
	const payAccountInvoice = async ( args ) => {
		let cloverCommerce: Order;
		let paymentData;
		if ( !houseAccount ) throw new Error( 'The invoices(s) you are trying to pay, is not a house accout invoice.' );
		enqueueSnackbar( t( 'commerce:payment-is-processing' ), { variant: 'info' } );
		
		try {
			// creating an invoice behind the scene for the payment because clover needs an order to apply a payment
			const sortInvoices = sortBy( invoices, [ 'createdAt' ] );
			const invoiceLineItems = getHAInvoiceLineItems( sortInvoices );
			
			let merchantClient: Client = orderClient as Client;
			if ( orderClient && companyLocation ) {
				merchantClient = await getClientForMerchant( {
					companyLocation: companyLocation,
				}, orderClient, staff );
			}
			
			const { data: newOrder } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/orderPublicWrite`, {
				companyId   : company.id,
				invoices    : sortInvoices.map( ( { id } ) => id ),
				client      : merchantClient?.id,
				houseAccount: houseAccount?.id,
				gateway     : gateway.id,
				company,
				staff       : orderStaff || staff,
				serviceDate : new Date(),
				lineItems   : invoiceLineItems.map( ( lineItem ) => ( {
					...idPick( lineItem, [
						'name',
						'price',
						'quantity',
						'tax',
						'unit',
						'description',
						'sequence',
					] ),
					isRevenue: false,
				} ) ),
				type    : 'ACCOUNT',
				notes   : 'Account payment',
				metadata: {
					disableHousePayment: true,
					customNumber       : null,
					documentLayout     : {
						color: {
							dark : '#424074',
							light: '#b1adf4',
						},
						template      : 'default',
						lineItemLayout: 'industrial',
					},
					mergedFrom: true,
					
				},
			} );
			const accountInvoice = newOrder?.orderWrite;
			
			try {
				if ( accountInvoice ) {
					cloverCommerce = accountInvoice;
					
					// sync the order so that payment can go on it on clover
					const { data: cloverOrder } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/postOrder`, {
						id     : cloverCommerce.id,
						company: company.id,
						staffId: staff?.id,
					} );
					cloverCommerce = cloverOrder?.commerce;
					
					// apply card fee if card or saved card
					if ( !surchargePercent && !cloverCommerce?.metadata?.cardFee && ( method === 'card' || method.includes( 'saved' ) ) ) {
						cloverCommerce = await applyCardFee( cloverCommerce );
					}
					
				}
			} catch ( e ) {
				try {
					if ( cloverCommerce?.id ) {
						if ( cloverCommerce.externalId ) await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/deleteOrder`, { id: cloverCommerce.id } );
						// delete order
						await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/orderPublicWrite`, {
							id       : cloverCommerce.id,
							remove   : true,
							companyId: company.id,
						} );
					}
					
				} catch {
				}
				closeModal();
				enqueueSnackbar( t( 'commerce:something-went-wrong-pay-again' ), { variant: 'error' } );
				return;
			}
			
			// make a payment on the order that was synced
			if ( cloverCommerce?.id ) {
				paymentData = await makePayment( {
					type           : method.toUpperCase(),
					amount         : ( amount || 0 ) > 0 ? ( amount || 0 ) + cardFee : cloverCommerce.grandTotal!,
					fee            : ( surchargePercent || 0 ) > 0 ? 0 : cardFee,
					tip            : dollarTip || ( amount || 0 ) * ( tip || 0 ) / 100,
					note           : 'Account payment',
					signature      : signature,
					orderId        : cloverCommerce.id,
					gatewayId      : gateway?.id,
					companyId      : company.id,
					metadata       : company.metadata,
					staffExternalId: staff?.externalId || null,
					staffId        : staff?.id,
					payerId        : orderClient?.id || cloverCommerce.client?.id || cloverCommerce?.client?.id || staff?.id,
					payerName      : orderClient?.name || cloverCommerce.client?.name || cloverCommerce?.client?.name || user?.firstName,
					cardToken      : cardToken || ( method?.includes( 'saved' ) ? method.split( '-' )?.[ 1 ] : undefined ),
					isClientPage   : isClientPage,
					args           : args,
				} );
				
			}
		} catch ( e ) {
			if ( cloverCommerce?.id ) {
				try {
					if ( cloverCommerce.externalId ) await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/deleteOrder`, { id: cloverCommerce.id } );
					// delete order
					await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/orderPublicWrite`, {
						id       : cloverCommerce.id,
						remove   : true,
						companyId: company.id,
					} );
					
				} catch {
				}
			}
			const cloverErrors = e?.response.data?.cloverErrors;
			
			if ( cloverErrors ) {
				throw cloverErrors?.error?.message || cloverErrors?.message || 'An error has occurred. Clover.com';
			} else {
				enqueueSnackbar( t( 'commerce:something-went-wrong' ), { variant: 'default' } );
				closeModal();
				return;
			}
		}
		
		if ( method === 'card' || method.includes( 'saved' ) ) await meteredBillingAndTracking( paymentData, cloverCommerce?.id, gateway.id, staff?.id, orderClient?.id );
		
		if ( paymentData ) {
			await sendMessagesAfterPayment( staff, company, orderClient as Client, cloverCommerce?.grandTotal );
		}
		
		return { paymentData, cloverCommerce };
	};
	
	const PaymentDetails: ComponentType<{
		gateway: GatewayBase,
		amount: number,
		tip: number,
		dollarTip: number,
		method: string,
		createPayment: ( data? ) => any,
		onSubmit: MutableRefObject<( () => Promise<string> )>,
		confirm: ( payment: Payment ) => void
	}> = {
		CLOVER: CloverPaymentDetails,
		SQUARE: SquarePaymentDetails,
		STRIPE: StripePaymentDetails,
	}[ gateway?.external ];
	
	return (
		<Stack spacing={2}>
			{PaymentDetails && (
				<PaymentDetails
					gateway={gateway}
					amount={amount}
					tip={tip}
					dollarTip={dollarTip}
					method={method}
					createPayment={async ( args ) => {
						const paymentData = await payAccountInvoice( args );
						enqueueSnackbar( t( 'common:payment-complete' ), { variant: 'success' } );
						closeModal();
						if ( paymentData?.cloverCommerce?.id ) {
							if ( paymentData.cloverCommerce?.externalId ) {
								setTimeout( async () => {
									try {
										await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/importOrder`, {
											id          : paymentData.cloverCommerce.id,
											keepSameDate: true,
											staffId     : staff?.id,
										} );
										await router.push( `/p/${paymentData.cloverCommerce.id}/invoice` );
									} catch {
									}
								}, 3000 );
							} else {
								await router.push( `/p/${paymentData.cloverCommerce.id}/invoice` );
							}
							
						}
						if ( paymentData ) return paymentData;
					}}
					confirm={confirm}
					onSubmit={onSubmit}
				/>
			)}
			{method === 'check' && (
				<TextField
					fullWidth
					placeholder={t( 'commerce:check-number' )}
					value={checkNumber}
					onChange={( e ) => setCheckNumber( e.target.value )}
				/>
			)}
			{client && (
				<TextField
					fullWidth
					required
					helperText={t( 'commerce:what-is-this-payment-for' )}
					placeholder='e.g. E07HSGT-0012'
					inputProps={{ maxLength: 20, minLength: 1 }}
					value={invoiceNote}
					onChange={( e ) => setInvoiceNote( e.target.value )}
				/>
			)}
			{client ? (
				<TextField
					fullWidth
					multiline
					required
					placeholder={t( 'commerce:note-placeholder' )}
					value={note}
					rows={4}
					inputProps={{ maxLength: 127, minLength: 1 }}
					onChange={( e ) => setNote( e.target.value )}
				/>
			) : (
				<TextField
					fullWidth
					multiline
					placeholder={t( 'common:note' )}
					value={note}
					rows={4}
					inputProps={{ maxLength: 127 }}
					onChange={( e ) => setNote( e.target.value )}
				/>
			)}
			<Attachment
				removeDownload
				src={signature}
				imageSX={{ width: '100%', height: 'unset', objectFit: 'cover' }}
			/>
			<Stack spacing={1} direction='row' alignItems='center'>
				<AsyncLoadingButton startIcon={<ArrowBackIosIcon/>} variant='outlined' onClick={cancel}>
					{t( 'common:back' )}
				</AsyncLoadingButton>
				{!hideSignature && !client && (
					<Button
						variant='outlined'
						color='primary'
						startIcon={<AssignmentIcon/>}
						onClick={() => showModal( SignModal, { fullPageBottomSheet: false }, {
							onSave   : setSignature,
							invoiceId: id,
						} )}>
						{t( 'commerce:add-signature' )}
					</Button>
				)}
				<AsyncLoadingButton
					variant='contained'
					color='primary'
					disabled={client && ( invoiceNote.length < 1 || note.length < 1 )}
					startIcon={<PaymentIcon/>}
					sx={{ width: 150 }}
					onClick={async () => {
						// pay HA invoices with card
						if ( onSubmit.current ) {
							await confirm( await onSubmit.current(), method );
						} else if ( method.includes( 'saved' ) || houseAccount ) {
							// pay HA Account Invoice with saved card and other methods
							const confirmedPayment = await payAccountInvoice( {} );
							enqueueSnackbar( t( 'common:payment-complete' ), { variant: 'success' } );
							closeModal();
							if ( confirmedPayment?.cloverCommerce?.id ) {
								if ( confirmedPayment?.cloverCommerce?.externalId ) {
									setTimeout( async () => {
										try {
											await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/importOrder`, {
												id          : confirmedPayment.cloverCommerce.id,
												keepSameDate: true,
												staffId     : staff?.id,
											} );
											await router.push( `/p/${confirmedPayment.cloverCommerce.id}/invoice` );
										} catch {
										}
									}, 3000 );
								} else {
									await router.push( `/p/${confirmedPayment.cloverCommerce.id}/invoice` );
								}
							}
							
						} else {
							// pay with cash , client payment, or check
							const paymentType = method.toUpperCase();
							const payByTender = ![ 'CARD',
							                       'ACH' ].includes( paymentType ) && !paymentType?.includes( 'SAVED' );
							if ( isClientPage && payByTender ) throw new Error( 'Invalid payment method.' );
							
							const paymentData = [];
							let clientPaymentAmount = selectedClientPayment?.amount;
							
							try {
								enqueueSnackbar( t( 'commerce:payment-is-processing' ), { variant: 'info' } );
								
								for ( const invoice of invoices ) {
									const paymentAmount = invoice.grandTotal - ( invoice.paidTotal || 0 );
									const { data } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/payment/createPayment`, {
										type  : paymentType,
										amount: paymentAmount,
										tip   : dollarTip || amount * ( tip || 0 ) / 100,
										note  : invoiceNote ? `${invoiceNote} - ${note} ${checkNumber
											? `#${checkNumber}`
											: ''}`
											: `${note}  ${checkNumber
												? `#${checkNumber}`
												: ''}`,
										signature,
										orderId  : invoice.id,
										gatewayId: gateway?.id,
										companyId: company.id,
										metadata : company?.metadata,
										staffId  : staff?.id,
										payerId  : staff?.id || orderClient?.id,
										payerName: user?.firstName || orderClient?.name,
										isClientPage,
									} );
									if ( selectedClientPayment ) clientPaymentAmount -= paymentAmount;
									paymentData.push( data );
									
								}
								
								if ( companyLocation?.gateway?.external === 'CLOVER' && method === 'ach' ) {
									await postCloverMeteredBilling( {
										orderId  : id,
										gatewayId: companyLocation.gateway?.id,
										eventType: 'Bank Account',
										key      : 'bankAccount',
										count    : invoices.length,
										staffId  : staff?.id,
										clientId : orderClient?.id,
									} ).catch( () => [] );
								}
								
							} catch ( e ) {
								const cloverErrors = e?.response.data?.cloverErrors;
								if ( cloverErrors ) {
									throw cloverErrors?.error?.message || cloverErrors?.message || 'An error has occurred. Clover.com';
								} else {
									enqueueSnackbar( t( 'commerce:something-went-wrong' ), { variant: 'default' } );
								}
							}
							
							// if selected client payment, then update its new amount
							if ( selectedClientPayment ) {
								await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/updateClientPayment`, {
									paymentId: selectedClientPayment.id,
									amount   : clientPaymentAmount,
								} ).catch( () => [] );
							}
							
							if ( !isEmpty( paymentData ) ) await confirm( paymentData );
						}
						
					}}>
					{t( 'commerce:finish' )}
				</AsyncLoadingButton>
			</Stack>
		</Stack>
	);
}
