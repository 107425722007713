import Attachment from '@/components/fileUploading/attachment';
import GraphqlTable from '@/components/graphqlTable';
import { getRowProps } from '@/components/graphqlTable/getDeliveryStatusRowStyling';
import OverflowTypography from '@/components/overflowTypography';
import StyledImage from '@/components/styledImage';
import UserPopover from '@/components/userPopover';
import { OrdersRead } from '@/data/commerce/order.graphql';
import currencyFormat from '@/helpers/currencyFormat';
import { PaymentTypeChip } from '@/hooks/paymentTypeChip';
import useOrdersExtraActions from '@/pages/dashboard/commerce/orders/actions/extraActions';
import usePermissions from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import { Order, QueryOrdersReadArgs } from '@/types/schema';
import { AttachFile as AttachFileIcon } from '@mui/icons-material';
import { Avatar, Chip, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { isEmpty } from 'lodash-es';
import type { Column } from 'react-table';
import CommerceStatusChip from '../chips/commerceStatusChip';
import MobileRowPanel from '../components/mobileRow';
import {
	CalculateClients,
	CalculateDate,
	CalculateNumber,
	CalculatePaymentType,
	CalculateTotal,
	CalculateUnique,
} from '../components/tableHelpers';
import CommerceTablePreviewDrawer from '../drawers/commerceTablePreviewDrawer';
import OrdersMultiActions from './actions/multiActions';
import useOrdersQuickFilterActions from './actions/quickFilterActions';
import useOrderActions from './actions/tableActions';
import DeletedOrdersModal from './deletedOrders';
import RowPanel from './rowPanel';

export const columns: ( args ) => Array<Column<any> | null> = ( client ) => [ {
	accessor      : 'id',
	Header        : 'common:id',
	disableSortBy : true,
	disableFilters: true,
	width         : 20,
	props         : { style: { minWidth: 50 } },
}, {
	accessor   : ( row ) => row.metadata?.customNumber || row.number,
	Header     : 'common:order-number',
	filterByKey: 'number',
	props      : { style: { minWidth: 178 } },
	width      : 12,
	Cell       : ( { value, row } ) => {
		const { showModal } = useModal();
		return (
			<Tooltip title='Preview'>
				<Stack
					sx={{ cursor: 'pointer' }}
					direction='row'
					spacing={1}
					alignItems='center'
					justifyContent='space-between'
					onClick={( e ) => {
						e.stopPropagation();
						showModal( CommerceTablePreviewDrawer, {
							variant: 'drawer',
							sx     : row.original.deletedAt
								? { zIndex: 1400 }
								: undefined,
						}, {
							dataId: row.original.id,
							type  : 'ORDER',
						} );
					}}>
					<Typography sx={{ textDecoration: 'underline' }}>{value}</Typography>
					{!isEmpty( row.original?.attachments ) && (
						<Tooltip title='Includes Attachments'>
							<AttachFileIcon sx={{ fontSize: 12, color: 'text.secondary' }}/>
						</Tooltip>
					)}
					{row.original?.externalId && (
						<Tooltip title='Available on clover'>
							<StyledImage
								alt='clover-pos-image'
								src='/images/clover-icon.png'
								width='10px'
								height='10px'
							/>
						</Tooltip>
					)}
				</Stack>
			</Tooltip>
		);
	},
	Footer: ( { rows } ) => CalculateNumber( rows, 'Order' ),
}, {
	accessor       : ( row ) => row.client?.name || row.client?.contact,
	Header         : client ? 'Contact' : 'common:client',
	filterByKey    : 'client.contact',
	multipleFilters: [ 'name', 'contact' ],
	width          : 20,
	props          : { style: { minWidth: 168 } },
	Cell           : ( { value } ) => value ? ( (
		<OverflowTypography>{value}</OverflowTypography>
	) as any ) : '-',
	Footer: ( { rows } ) => CalculateClients( rows, 'Client', 'Client' ),
}, {
	accessor   : 'client.id' as any,
	Header     : 'common:client-id',
	filterByKey: 'client.id',
	width      : 20,
	props      : { style: { minWidth: 168 } },
	Cell       : ( { value } ) => value || '-',
	Footer     : ( { rows } ) => CalculateClients( rows, 'Client', 'Client' ),
}, !client ? {
	accessor   : 'metadata.sentDates' as any,
	Header     : 'Sent Dates',
	sortType   : 'exist',
	filterByKey: 'metadata.sentDates',
	Cell       : ( { value } ) => value?.[ 0 ] ? <Typography>{format( new Date( value[ 0 ] ), 'PPp' )}</Typography>
		: <Typography>-</Typography>,
	props: { style: { minWidth: 135 } },
	width: 12,
} : null, client === 'client' ? {
	accessor      : 'company' as any,
	Header        : 'common:company',
	disableFilters: true,
	width         : 20,
	props         : { style: { minWidth: 168 } },
	Cell          : ( { value } ) => value?.name || value?.contact || '-',
	Footer        : ( { rows } ) => CalculateClients( rows, [
		'company.name',
		'company.contact' ], 'Company' ),
} : null, {
	accessor       : 'status',
	Header         : 'common:status',
	filterByKey    : 'status',
	multipleFilters: [ 'SENT', 'VIEWED', 'PARTIALLY_PAID', 'DRAFT' ],
	filterOptions  : [ ...client ? [] : [ 'DRAFT' ], ...[
		'CANCELLED',
		'COLLECTIONS',
		'COMPLETED',
		'MERGED',
		'OPEN',
		'PAID',
		'PARTIALLY_PAID',
		'PAST_DUE',
		'REFUNDED',
		'SENT',
		'STANDING',
		'VIEWED',
		'WRITTEN_OFF',
	] ],
	width : 10,
	props : { style: { minWidth: 80 } },
	Cell  : ( data ) => <CommerceStatusChip cellData={data}/>,
	Footer: ( { rows } ) => CalculateUnique( rows, 'status' ),
}, {
	accessor     : 'payments',
	Header       : 'common:payment-type',
	width        : 10,
	sortType     : 'options',
	filterByKey  : 'payments.type',
	filterOptions: [ 'CARD', 'Credit Card', 'Invoiss' ],
	props        : { style: { minWidth: 110 } },
	Cell         : ( { value } ) => <PaymentTypeChip payments={value} name='order'/>,
	Footer       : ( { rows } ) => CalculatePaymentType( rows ),
}, {
	accessor: 'serviceType' as any,
	Header  : 'Service Type',
	width   : 10,
	props   : { style: { minWidth: 110 } },
	Cell    : ( { value } ) => value,
}, {
	accessor      : 'store',
	Header        : 'Store',
	width         : 20,
	disableFilters: true,
	disableSortBy : true,
	props         : { style: { minWidth: 168 } },
	Cell          : ( { value } ) => value?.name || value?.contact || '',
}, {
	accessor: 'createdAt',
	Header  : 'common:created-date',
	sortType: 'datetime',
	width   : 15,
	props   : { style: { minWidth: 120 } },
	Cell    : ( { value } ) => value ? format( value, 'PPp' ) as any : '',
	Footer  : ( { rows } ) => CalculateDate( rows, 'serviceDate' ),
}, {
	accessor: 'serviceDate',
	Header  : 'common:service-date',
	sortType: 'datetime',
	width   : 15,
	props   : { style: { minWidth: 120 } },
	Cell    : ( { value } ) => value ? format( value, 'PPp' ) as any : '',
	Footer  : ( { rows } ) => CalculateDate( rows, 'serviceDate' ),
}, client === 'deletedAt' ? {
	accessor: 'deletedAt',
	Header  : 'common:deletedAt',
	sortType: 'datetime',
	width   : 20,
	props   : { style: { minWidth: 168 } },
	Footer  : ( { rows } ) => CalculateDate( rows, 'deletedAt' ),
} : null, {
	accessor: 'updatedAt',
	Header  : 'common:updated',
	sortType: 'datetime',
	width   : 20,
	props   : { style: { minWidth: 168 } },
	Footer  : ( { rows } ) => CalculateDate( rows, 'updatedAt' ),
}, !client ? {
	accessor   : 'staff' as any,
	Header     : 'common:placed-by',
	sortType   : 'select',
	filterByKey: 'staff.user.firstName',
	width      : 10,
	props      : { style: { minWidth: 110 } },
	Cell       : ( { value } ) => (
		<UserPopover user={value?.user}>
			<Chip
				label={value?.user?.firstName}
				size='small'
				avatar={(
					<Avatar
						alt={value?.user?.firstName}
						src={value?.user?.image}
						sx={{
							border     : 1.5,
							borderColor: 'colors.opposite',
						}}
					/>
				)}
			/>
		</UserPopover>
	),
} : null, {
	accessor      : 'metadata.signature' as any,
	Header        : 'common:signature',
	disableSortBy : true,
	disableFilters: true,
	props         : { style: { minWidth: 90 } },
	width         : 12,
	Cell          : ( { value } ) => value ? (
		<Attachment
			whiteBg
			removeDownload
			src={value}
			imageSX={{
				width    : 40,
				height   : 40,
				objectFit: 'cover',
				mb       : 0,
			}}
		/>
	) : null,
}, {
	accessor: 'taxName',
	Header  : 'Tax Name',
	width   : 20,
	props   : { style: { minWidth: 168 } },
}, {
	accessor: 'taxTotal',
	Header  : 'common:tax',
	sortType: 'number',
	width   : 12,
	props   : { style: { minWidth: 80 } },
	Cell    : ( { value } ) => currencyFormat( value as number ) as any,
	Footer  : ( { rows } ) => CalculateTotal( rows, 'taxTotal', true ),
}, {
	accessor: 'grandTotal',
	Header  : 'common:total',
	sortType: 'number',
	width   : 10,
	props   : { style: { minWidth: 90 } },
	Cell    : ( { value } ) => currencyFormat( Math.abs( +value ) ) as any,
	Footer  : ( { rows } ) => CalculateTotal( rows, 'grandTotal', true ),
} ].filter( Boolean );

export default function OrderTable() {
	const theme = useTheme();
	const darkMode = theme.palette.mode === 'dark';
	const { staff } = useUserInfo();
	const { showModal } = useModal();
	const isOwner = usePermissions( [ 'OWNER' ] );
	const isAdmin = usePermissions( [ 'ADMIN' ] );
	const { variables, actions } = useOrdersExtraActions();
	
	return (
		<GraphqlTable<Order, QueryOrdersReadArgs>
			showFooter
			searchable
			queryKey='order'
			query={OrdersRead}
			columns={columns}
			hiddenTableColumns='ordersHiddenColumns'
			tableSavedFilters={[
				'orders',
				'Show All',
				'Not Completed',
				'Available on Clover',
				'Not Available on Clover',
			]}
			tableActionMenu={[ 'Export', 'ordersPDF', 'ordersCSV' ]}
			headerExtraActions={{
				items: actions,
				max  : 2,
			}}
			rowRoute={( { id } ) => `/dashboard/commerce/orders/${id}`}
			subscription={{ ORDER: staff.company.id }}
			variables={{
				options: {
					filter: {
						companyLocation: variables?.location?.id,
						externalId     : staff.metadata?.orders === 'Available on Clover' ? { $ne: null }
							: staff.metadata?.orders === 'Not Available on Clover' ? { $eq: null } : undefined,
						staff : isOwner || isAdmin || staff.metadata?.viewOtherEntries ? undefined : staff.id,
						status: staff.metadata?.orders === 'Not Completed' ? { $ne: 'PAID' } : undefined,
					},
				},
			}}
			initialState={{
				hiddenColumns: staff.metadata?.ordersHiddenColumns ? staff.metadata?.ordersHiddenColumns : [
					'id',
					'store',
					'serviceDate',
					'client.id',
					'metadata.signature',
					'updatedAt',
					'staff',
					'houseAccount.id',
					'houseAccount',
					'metadata.sentDates',
					'taxTotal',
					'taxName',
				],
			}}
			expandedComponent={( row ) => <RowPanel row={row} darkMode={darkMode}/>}
			rowProps={( row ) => getRowProps( row, darkMode )}
			useActions={useOrderActions}
			useQuickFilters={useOrdersQuickFilterActions}
			renderDeletedData={() => showModal( DeletedOrdersModal, { maxWidth: 'xl' } )}
			mobileRenderRow={( order ) => <MobileRowPanel data={order as any}/>}
			renderMultiActions={( orders, clearRows, selectedRows ) => (
				<OrdersMultiActions
					orders={orders}
					clearRows={clearRows}
					selectedOrders={selectedRows}
				/>
			)}
		/>
	);
}
